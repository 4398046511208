import { inject, Pipe, PipeTransform } from '@angular/core';
import { ContextStore } from '../../stores/context.store';

@Pipe({ name: 'agoraCurrency' })
export class AgoraCurrency implements PipeTransform {
  private contextStore = inject(ContextStore);
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(price: number, locale?: string): string {
    const applicationLocale = locale ?? this.contextStore.locale();

    let output = '';

    switch (applicationLocale) {
      case 'nl-be':
      case 'fr-be':
      case 'fr-fr':
        output = (price?.toFixed(2) ?? '0') + ' €';
        break;
      case 'de-de':
        output = '€ ' + (price?.toFixed(2) ?? '0');
        break;
      default:
        output = (price?.toFixed(2) ?? '0') + ' €';
    }

    return output;
  }
}

@Pipe({ name: 'agoraCurrencyInt' })
export class AgoraCurrencyInt implements PipeTransform {
  private contextStore = inject(ContextStore);
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(price: number, locale?: string): string {
    const applicationLocale = locale ?? this.contextStore.locale();
    let output = '';

    switch (applicationLocale) {
      case 'nl-be':
      case 'fr-be':
      case 'fr-fr':
        output = (price?.toFixed(0) ?? '0') + ' €';
        break;
      case 'de-de':
        output = '€ ' + (price?.toFixed(0) ?? '0');
        break;
      default:
        output = (price?.toFixed(0) ?? '0') + ' €';
    }

    return output;
  }
}
