<div class="pink-area fullwidth">
  <form *ngIf="editForm" [formGroup]="editForm" class="form-container">
    <div class="form-block">
      <div class="form-control">
        <label class="required">{{ 'DOCUMENT.FORM.TITLE' | translate }}</label>
        <input type="text" formControlName="title" />
        <p class="form-error" *ngIf="(editForm.get('title')?.dirty || editForm.get('title')?.touched) && editForm.get('title')?.errors?.required">{{ 'INPUT.VALIDATION.REQUIRED' | translate }}</p>
      </div>
    </div>
    <div class="form-block flex gap-32 j-space-between m-column m-gap-initial">
      <div class="form-control flex-1">
        <label class="required">{{ 'DOCUMENT.FORM.TYPE' | translate }}</label>
        <select formControlName="type">
          <option *ngIf="editForm.get('category').value == DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Customer">{{ 'DOCUMENT.TYPE.CLIENT' | translate }}</option>
          <option *ngIf="editForm.get('category').value == DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Hostess">{{ 'DOCUMENT.TYPE.HOSTESS' | translate }}</option>
          <option *ngIf="editForm.get('category').value == DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Delegate">{{ 'DOCUMENT.TYPE.DELEGATE' | translate }}</option>
          <option *ngIf="editForm.get('category').value == DocumentCategoryObject.HostessGuide" [value]="DocumentTypeObject.Hostess">{{ 'DOCUMENT.TYPE.GUIDE' | translate }}</option>
        </select>
        <p class="form-error" *ngIf="(editForm.get('type')?.dirty || editForm.get('type')?.touched) && editForm.get('type')?.errors?.required">{{ 'INPUT.VALIDATION.REQUIRED' | translate }}</p>
      </div>
      <div class="form-control flex-1">
        <label class="required">{{ 'DOCUMENT.FORM.LANGUAGE' | translate }}</label>
        <select formControlName="locale">
          <option *ngIf="contextStore.contextId() == 1" value="fr_fr">Français</option>
          <option *ngIf="contextStore.contextId() == 2" value="fr_be">Français</option>
          <option *ngIf="contextStore.contextId() == 2" value="nl_be">Nederlands</option>
          <option *ngIf="contextStore.contextId() == 3" value="de_de">Deutsch</option>
        </select>
        <p class="form-error" *ngIf="(editForm.get('locale')?.dirty || editForm.get('locale')?.touched) && editForm.get('locale')?.errors?.required">{{ 'INPUT.VALIDATION.REQUIRED' | translate }}</p>
      </div>
    </div>
    <div class="form-block-flex">
      <div class="form-control" *ngIf="editForm.get('category').value == DocumentCategoryObject.CommercialAction">
        <label class="required">{{ 'DOCUMENT.FORM.ACTION.STARTDATE' | translate }}</label>
        <input type="date" formControlName="appliesFrom" />
        <p class="form-error" *ngIf="(editForm.get('appliesFrom')?.dirty || editForm.get('appliesFrom')?.touched) && editForm.get('appliesFrom')?.errors?.required">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
      <div class="form-control" *ngIf="editForm.get('category').value == DocumentCategoryObject.CommercialAction">
        <label class="required">{{ 'DOCUMENT.FORM.ACTION.ENDDATE' | translate }}</label>
        <input type="date" formControlName="appliesUntil" />
        <p class="form-error" *ngIf="(editForm.get('appliesUntil')?.dirty || editForm.get('appliesUntil')?.touched) && editForm.get('appliesUntil')?.errors?.required">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
      <div class="form-control">
        <label class="required">{{ 'DOCUMENT.FORM.VISIBILITY.STARTDATE' | translate }}</label>
        <input type="date" formControlName="visibleFrom" />
        <p class="form-error" *ngIf="(editForm.get('visibleFrom')?.dirty || editForm.get('visibleFrom')?.touched) && editForm.get('visibleFrom')?.errors?.required">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
      <div class="form-control">
        <label class="required">{{ 'DOCUMENT.FORM.VISIBILITY.ENDDATE' | translate }}</label>
        <input type="date" formControlName="visibleUntil" />
        <p class="form-error" *ngIf="(editForm.get('visibleUntil')?.dirty || editForm.get('visibleUntil')?.touched) && editForm.get('visibleUntil')?.errors?.required">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
      <div class="form-control">
        <label class="required">{{ 'DOCUMENT.FORM.DESCRIPTION' | translate }}</label>
        <textarea formControlName="description"></textarea>
        <p class="form-error" *ngIf="(editForm.get('description')?.dirty || editForm.get('description')?.touched) && editForm.get('description')?.errors?.required">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
      <div class="form-control">
        <label>{{ 'DOCUMENT.FORM.IMAGE' | translate }}</label>
        <app-file-dropzone
          (requestUploadFile)="uploadFile($event, FileType.Image)"
          (requestRemoveAttachment)="removeAttachment(FileType.Image)"
          [dropzoneText]="'DROPZONE.IMAGE.TEXT' | translate"
          [attachmentSrc]="editForm.get('imageUrl')?.value"
          [type]="AttachmentType.IMAGE"
          [style]="'min-height:15rem;'" />
        <input type="hidden" formControlName="imageUrl" />
      </div>
      <div class="form-control">
        <label>{{ 'DOCUMENT.FORM.ATTACHMENT' | translate }}</label>
        <app-file-dropzone
          (requestUploadFile)="uploadFile($event, FileType.Attachment)"
          (requestRemoveAttachment)="removeAttachment(FileType.Attachment)"
          [attachmentSrc]="editForm.get('attachments')?.value.length ? editForm.get('attachments')?.value[0].url : ''"
          [type]="AttachmentType.FILE"
          [dropzoneText]="'DROPZONE.ATTACHMENT.TEXT' | translate" />
      </div>
      <div class="form-control">
        <label>{{ 'DOCUMENT.FORM.LINK' | translate }}</label>
        <input type="text" formControlName="link" />
      </div>
      <div class="form-control">
        <label class="required">{{ 'DOCUMENT.FORM.AUDIENCES' | translate }}</label>
        <div class="flex j-space-between gap-15" style="margin-top: 1.5rem; padding: 0.5rem" formGroupName="audiences">
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_customer" />
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            {{ 'DOCUMENT.FORM.AUDIENCE.CUSTOMER' | translate }}
          </label>
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_hostess" />
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            {{ 'DOCUMENT.FORM.AUDIENCE.HOSTESS' | translate }}
          </label>
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_delegate" />
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            {{ 'DOCUMENT.FORM.AUDIENCE.DELEGATE' | translate }}
          </label>
        </div>
        <p class="form-error" *ngIf="(editForm.get('audiences')?.dirty || editForm.get('audiences')?.touched) && editForm.get('audiences')?.errors?.audiencesRequired">
          {{ 'INPUT.VALIDATION.REQUIRED' | translate }}
        </p>
      </div>
    </div>
    <div class="cta-container width-fit">
      <button (click)="validateForm()">
        <label>{{ 'GLOBAL.ACTION.SAVE' | translate }}</label>
      </button>
    </div>
  </form>
</div>
