import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loaded$: Subject<boolean> = new Subject();
  public loaded$ = this._loaded$.asObservable();

  constructor() {}

  public loaded(state = true) {
    this._loaded$.next(state);
  }
}
