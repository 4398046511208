<div class="pink-area m-fullwidth my-communications">
  <h1>{{ 'PROFILE.COMMUNICATION.VICTORIA' | translate }}</h1>

  <div class="form-group row m-column flex m-ac-center">
    <label class="flex row checkbox path" *ngIf="communications && !noCommunications">
      <input type="checkbox" *ngIf="communications" [(ngModel)]="communications.canSendFromVictoria" (ngModelChange)="updateValue($event, 'victoria')" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
    </label>
    <label class="flex row checkbox path" *ngIf="noCommunications">
      <input type="checkbox" (click)="updateRGPD()" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
    </label>
  </div>

  <div class="warning flex row ai-center" *ngIf="(communications && !communications?.canSendFromVictoria) || noCommunications">
    <div>
      <svg viewBox="0 0 28 28">
        <use xlink:href="#icon-warning"></use>
      </svg>
    </div>
    <div [innerHTML]="'PROFILE.COMMUNICATION.NOSUBSCRIBE' | translate"></div>
  </div>

  <h1 class="margin-top-50">{{ 'PROFILE.COMMUNICATION.DELEGATES' | translate }}</h1>
  <div *ngIf="communications?.detailsAddress?.length">
    <p>{{ 'PROFILE.COMMUNICATION.DELEGATES.CONSENT' | translate }}</p>
    <ul class="flex column wrap">
      <li class="flex-3" *ngFor="let pref of communications.detailsAddress">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" [(ngModel)]="pref.isOptIn" (ngModelChange)="updateValue($event, 'delegate', pref)" />
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          {{ pref.delegate?.displayName }}
        </label>
      </li>
      <!--<li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Céline VANGRIEKEN
        </label>
      </li>
      <li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Isabelle VANDONGEN
        </label>
      </li>
      <li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Ingrid MAENHOUT
        </label>
      </li>
      <li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Raf & Nancy DELEENHEER-LIPENS
        </label>
      </li>
      <li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Claudio FALZONE
        </label>
      </li>
      <li class="flex-3">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" checked>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          Jérôme VANOPBROEKE
        </label>
      </li>-->
    </ul>
  </div>
  <p *ngIf="!communications?.detailsAddress?.length">{{ 'PROFILE.COMMUNICATION.NODELEGATES' | translate }}</p>

  <div class="form-group row m-column m-ai-center m-j-center m-ac-center save-button">
    <!--<button class="btn btn-primary m-j-center m-ai-center m-ac-center m-fit-content">
      <div class="label">{{'GLOBAL.ACTION.UPDATE'| translate}}</div>
      <div class="icon icon-right icon">
        <svg viewBox="0 0 28 28">
          <use xlink:href="#icon-save"></use>
        </svg>
      </div>
    </button>-->
  </div>
</div>
