import { Component, inject } from '@angular/core';
import { ErrorService } from 'src/app/core/services/error.service';
import { SearchDelegateForm } from '../../../../../../core/types/size-dropdown.type';
import { UserStore } from '../../../../../../stores/user.store';
import { ContextStore } from '../../../../../../stores/context.store';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { CheckoutStore } from '../../../../../../stores/chechout.store';
import { DelegateResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-contact',
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss'],
})
export class OrderContactComponent {
  public resourcesStore = inject(ResourcesStore);
  public checkoutStore = inject(CheckoutStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);

  public error = inject(ErrorService);

  async updateCartDelegate(delegate: DelegateResponse) {
    //TODO create action using delegate.delegateId
    await this.checkoutStore.addDelegateToCart(delegate.id);
  }

  setDelegateSelected(delegate: DelegateResponse) {
    this.checkoutStore.saveSelectedDelegateInternally(delegate);
  }

  openSearchDelegateModal() {
    this.checkoutStore.openSearchDelegateModal();
  }

  closeSearchDelegateModal() {
    this.checkoutStore.closeSearchDelegateModal();
  }

  openEnterDemoCodeModal() {
    this.checkoutStore.openAddDemoCodeModal();
  }

  closeEnterDemoCodeModal() {
    this.checkoutStore.closeAddDemoCodeModal();
  }

  closeSelectDelegateModal() {
    this.checkoutStore.closeSelectDelegatesModal();
  }

  async searchDelegateByForm(data: SearchDelegateForm) {
    await this.checkoutStore.searchForDelegates(data);
  }
}
