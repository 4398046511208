<div class="delegate-card-container" *ngIf="resourcesStore.isLoaded()">
  <div class="flex j-center">
    <div class="card-img-container flex center-all" *ngIf="delegate.id">
      <img loading="lazy" class="thumb-image" [src]="getProfilePictureOrDefault(delegate.profileImage)" [alternativeImage] />
    </div>
    <div *ngIf="!delegate.id">
      <div class="flex j-center delegate-icon">
        <svg viewBox="0 0 26 32" class="icon peach">
          <use xlink:href="#icon-delegate"></use>
        </svg>
      </div>
    </div>
  </div>
  <h2 *ngIf="delegate.id">
    {{ (delegate.firstname + ' ' + delegate.lastname).trim() }} <br />
    <span class="v_certified" *ngIf="delegate.affiliateStatus && delegate.affiliateStatus == 3">
      <svg viewBox="0 0 37 36" class="icon peach">
        <use xlink:href="#victoria-certification"></use>
      </svg>
      &nbsp; {{ 'DEMO.DELEGATE.CERTIFIED' | translate }}
    </span>
  </h2>
  <h2 class="no-delegate-title" *ngIf="!delegate.id">{{ resourcesStore.i18n().resources.profileConsultant.labelNoConsultants }}</h2>
  <div *ngIf="delegate.id">
    <div class="flex personal-datas">
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.city">
        <svg viewBox="0 0 32 32" class="icon peach">
          <use xlink:href="#icon-location"></use>
        </svg>
        &nbsp; {{ delegate.city }}
      </div>
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.mobile">
        <a class="flex ai-center" href="tel: {{ delegate.mobile }}">
          <svg viewBox="0 0 32 32" class="icon peach">
            <use xlink:href="#icon-telephone"></use>
          </svg>
          &nbsp; {{ delegate.mobile }}
        </a>
      </div>
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.email">
        <a class="flex ai-center" href="mailto: {{ delegate.email }}">
          <svg viewBox="0 0 32 32" class="icon peach">
            <use xlink:href="#icon-newsletter"></use>
          </svg>
          &nbsp; {{ delegate.email }}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="delegate.id && delegate.eShopPitch && delegate.eShopPitch.length > 10" class="about-me">
    <h3>{{ 'DELEGATE.ABOUTME' | translate }}</h3>
    <p class="left">
      {{ delegate.eShopPitch }}
    </p>
  </div>
  <div *ngIf="!delegate.id" class="no-delegate-infos" [innerHtml]="'FIDELITY.DELEGATE.NODELEGATE' | translate"></div>
</div>
