import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { globalCatchError } from '../../utils/errorHandler';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentType } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private api: ApiService,
    private store: Store
  ) {}

  //GET
  getPaymentOptions(cartId: string, locale: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).availablePaymentTypes.byLocale(locale).get())),
      globalCatchError(this.store)
    );
  }

  async getDelegateProfileAsPromise(delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.delegates.byDelegateId(delegateId).get();
  }

  async getDelegateByCode(delegateCode: string) {
    const client = this.api.getClientInstance();
    return client.api.delegates.byCode.byDelegateCode(delegateCode).get();
  }

  //POST
  postPaymentPromise(cartId: string, languageCode: string, paymentType: PaymentType, redirectUrl: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).payments.post({ paymentType, languageCode, redirectUrl });
  }
}
