import {
  CreateDocumentRequest,
  DocumentResponse,
  GetDemoDocumentsResponse,
  UpdateDocumentRequest,
} from '@victoria-company/agora-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { DocumentsService } from '../core/services/V2/documents.service';
import { computed, inject } from '@angular/core';

export interface ActionState {
  documents: GetDemoDocumentsResponse;
  staffDocuments: GetDemoDocumentsResponse;
}

export const initialState = {
  documents: null,
  staffDocuments: null,
};

export const DocumentsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('actions'),
  withState(initialState),
  withComputed(store => ({
    commercialActions: computed(() => store.documents()?.documents?.filter((d: DocumentResponse) => d.category == 'CommercialAction') ?? []),
    hostessActions: computed(() => store.documents()?.documents?.filter((d: DocumentResponse) => d.category == 'HostessGuide') ?? []),
    commercialActionsForStaff: computed(() => store.staffDocuments()?.documents?.filter((d: DocumentResponse) => d.category == 'CommercialAction') ?? []),
    hostessActionsForStaff: computed(() => store.staffDocuments()?.documents?.filter((d: DocumentResponse) => d.category == 'HostessGuide') ?? []),
  })),
  withMethods((store, documentsService = inject(DocumentsService)) => ({
    resetDocuments() {
      patchState(store, { documents: null });
    },
    async loadDocuments(demoCodeOrId: string, locale:string) {
      const documents = await documentsService.getDocuments(demoCodeOrId, locale);
      patchState(store, { documents });
    },
    async loadDocumentsForStaff() {
      const staffDocuments = await documentsService.getDocumentsForStaff();
      patchState(store, { staffDocuments });
    },
    async createDocument(request:CreateDocumentRequest){
      const result = await documentsService.createDocument(request);

      if(result){
        patchState(store, () => ({
          staffDocuments: {
            documents: [result, ...(store.staffDocuments()?.documents ?? [])]
          }
        }))

        return true;
      } else return false;
    },
    async updateDocument(documentId:string, request:UpdateDocumentRequest){
      const result = await documentsService.updateDocument(documentId, request);

      if(result){
        patchState(store, () => ({
          staffDocuments:{
            documents: [...store.staffDocuments().documents.map((d:DocumentResponse) => d.id==result.id ? result : d)]
          }
        }))

        return true;
      } else return false;
    },
    async getDocumentById(documentId:string){
      console.log("Get document By Id: ", documentId);
      return store.staffDocuments()?.documents?.find((d:DocumentResponse) => d.id==documentId);
    },

    async deleteDocumentById(documentId:string){
      await documentsService.deleteDocumentById(documentId);
      patchState(store, () => ({
        staffDocuments:{
          documents: [...store.staffDocuments().documents.filter(d => d.id!=documentId)]
        }
      }))
    }

  }))
);
