import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ErrorService } from 'src/app/core/services/error.service';
import { VoucherResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../stores/context.store';
import { UserService } from '../../../../../core/services/V2/user.service';

@Component({
  selector: 'app-wallet-datas',
  templateUrl: './wallet-datas.component.html',
  styleUrls: ['./wallet-datas.component.scss'],
})
export class WalletDatasComponent {
  @Input() vouchers: VoucherResponse[];
  @Output() triggerCall = new EventEmitter<string>();

  readonly contextStore = inject(ContextStore);
  readonly userService = inject(UserService);
  //TODO: Check, for Legacy
  readonly error = inject(ErrorService);

  async copyValue(event: MouseEvent, value: string) {
    await navigator.clipboard.writeText(value);
    const el = event.target as HTMLElement;

    if (!el) return;

    el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
    setTimeout(() => {
      el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
    }, 3000);
  }

  async download(code: string, isUsed: boolean) {
    if (isUsed) return;

    const result = await this.userService.downloadVoucher(code, this.contextStore.locale());
    const mediaType = 'application/pdf';
    const blob = new Blob([result], { type: mediaType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
