import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value == '') {
        return null;
      }

      const currentDate = new Date(control.value);
      const controlDate = new Date(date);

      return currentDate > controlDate
        ? null
        : {
            dateMinimum: {
              'date-minimum': control.value,
              actual: date,
            },
          };
    };
  }

  static dateMaximum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value == '') {
        return null;
      }

      const currentDate = new Date(control.value);
      const controlDate = new Date(date);

      return currentDate <= controlDate
        ? null
        : {
            dateMaximum: {
              'date-maximum': control.value,
              actual: date,
            },
          };
    };
  }
}
