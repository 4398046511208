import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientCommunications } from 'src/app/core/models/clientCommunications.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { RGPDService } from 'src/app/core/services/rgpd.service';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss'],
})
export class OptInComponent implements OnInit {
  readonly contextStore = inject(ContextStore);

  clientEmail = '';
  clientHash = '';
  clientHash2 = '';
  clientDelegate = '';

  communications: ClientCommunications;

  private operation = {
    optIn: 1,
    optOut: 2,
  };

  constructor(
    public error: ErrorService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private rgpdService: RGPDService
  ) {}

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.e && params.c) {
        this.clientEmail = params.e;
        this.clientHash = params.c;
        this.clientHash2 = params.d;
        this.clientDelegate = params.f;

        this.rgpdService.getClientPublicCommunication(2, this.clientEmail, this.clientHash, this.clientHash2, this.clientDelegate);
      } else this.router.navigateByUrl('/');
    });

    this.rgpdService.communications$.subscribe(res => {
      if (res) {
        this.communications = res;
        this.rgpdService.postClientPublicCommunicationOptin(
          this.communications,
          500,
          this.operation.optIn,
          this.contextStore.contextId(),
          this.clientEmail,
          { delegateId: this.clientDelegate },
          this.clientHash,
          this.clientHash2
        );
      }
    });
  }
}
