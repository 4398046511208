import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from '../../../../core/services/translation.service';
import { ErrorService } from '../../../../core/services/error.service';
import { ModalService } from '../../../../core/services/modal.service';
import { StorageService } from '../../../../core/services/storage.service';
import { Resources1 } from '../../../../state/models/resources';
import { getProfilePictureOrDefault } from '../../../../core/utils/filter.utils';
import { CartResponse, DelegateResponse, UserProfileResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-contact-box',
  templateUrl: './order-contact-box.component.html',
  styleUrls: ['./order-contact-box.component.scss'],
})
export class OrderContactBoxComponent implements OnInit {
  @Input() delegate: DelegateResponse;
  @Input() profile: UserProfileResponse;
  @Input() resources: Resources1;
  @Input() cart: CartResponse;

  @Input() demoCode: string;
  @Input() contextId: number;

  @Output() requestUpdateCartDelegate = new EventEmitter();
  @Output() requestOpenSearchDelegateModal = new EventEmitter();

  // resources: any;
  hasDemoCookie = false;

  constructor(
    public modal: ModalService,
    private storage: StorageService,
    public translate: TranslationService,
    public error: ErrorService
  ) {}

  ngOnInit() {
    this.hasDemoCookie = this.storage.demoId != undefined;
  }
  public confirmDelegate() {
    this.requestUpdateCartDelegate.emit();
  }

  public openSearchDelegate() {
    this.requestOpenSearchDelegateModal.emit();
  }

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
