import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StorageService } from 'src/app/core/services/storage.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { getSSOLocale } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  registerURL = '#';
  isSandbox = environment.isSandbox;

  @Input() isComponent = false;
  @Input() redirectUri;

  @Output() requestRegistration: EventEmitter<any> = new EventEmitter();

  constructor(
    private trigger: TriggerService,
    private authService: OidcSecurityService,
    public storage: StorageService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.redirectUri) this.storage.redirectTo = this.redirectUri;
  }

  ngAfterViewInit() {}

  public login() {
    this.trigger.requestLogin();
  }

  public goToRegistration() {
    if (!this.isSandbox)
      this.authService
        .getAuthorizeUrl()
        .toPromise()
        .then(url => {
          this.registerURL = url.replace('authorize?', 'register?locale=' + getSSOLocale(this.storage.languageCode) + '&tenantId=' + environment.tenantId + '&');
          location.href = this.registerURL;
        });
    else {
      alert(this.translate.instant('GLOBAL.UNAUTHORIZED.SANDBOX'));
    }
  }
}
