import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent implements OnInit {
  @Input() withFrame = true;
  locale: string;

  constructor(private cookies: CookieService) {}

  ngOnInit() {
    this.locale = this.cookies.get('v_locale')?.replace('_', '-');
  }
}
