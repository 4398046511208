<form [formGroup]="generalForm" class="pink-area m-fullwidth my-datas" *ngIf="resourcesStore.isLoaded()">
  <h1>{{ 'PROFILE.INFORMATIONS.TITLE' | translate }}</h1>
  <div class="form-group my-datas-form">
    <div class="form-control flex-1">
      <label>{{ 'GLOBAL.USER.GENDER' | translate }}</label>
      <select formControlName="gender">
        <option [value]="GenderObject.Female">{{'GLOBAL.GENDER.MADAME'|translate}}</option>
        <option [value]="GenderObject.Male">{{'GLOBAL.GENDER.MISTER'|translate}}</option>
      </select>
    </div>
    <div class="form-control flex-1" [class.error]="form?.firstName.errors && (form?.firstName.dirty || form?.firstName.touched)">
      <label class="required">{{ 'GLOBAL.USER.FIRSTNAME' | translate }}</label>
      <input type="text" formControlName="firstName" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.firstName.errors && (form.firstName.dirty || form.firstName.touched)">
          <div *ngIf="form.firstName.errors.required">
            {{ resourcesStore.i18n().resources.register.firstNameLabelError }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-control flex-1" [class.error]="form?.lastName.errors && (form?.lastName.dirty || form?.lastName.touched)">
      <label class="required">{{ 'GLOBAL.USER.LASTNAME' | translate }}</label>
      <input type="text" formControlName="lastName" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.lastName.errors && (form.lastName.dirty || form.lastName.touched)">
          <div *ngIf="form.lastName.errors.required">
            {{ resourcesStore.i18n().resources.register.lastNameLabelError }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group my-datas-form">
    <div class="form-control flex-2" [class.error]="form?.address1.errors && (form?.address1.dirty || form?.address1.touched)">
      <label class="required">{{ 'GLOBAL.USER.ADRESS1' | translate }}</label>
      <input type="text" formControlName="address1" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.address1.errors && (form.address1.dirty || form.address1.touched)">
          <div *ngIf="form.address1.errors.required">
            {{ resourcesStore.i18n().resources.register.addressLabelError }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-control flex-1">
      <label>{{ 'GLOBAL.USER.ADRESS2' | translate }}</label>
      <input type="text" formControlName="address2" />
    </div>
  </div>
  <div class="form-group my-datas-form">
    <div class="form-control flex-1" [class.error]="form?.zipCode.errors && (form?.zipCode.dirty || form?.zipCode.touched)">
      <label class="required">{{ 'GLOBAL.USER.ZIPCODE' | translate }}</label>
      <input type="text" formControlName="zipCode" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.zipCode.errors && (form.zipCode.dirty || form.zipCode.touched)">
          <div *ngIf="form.zipCode.errors.required">
            {{ resourcesStore.i18n().resources.register.postalCodeLabelError }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-control flex-1" [class.error]="form?.city.errors && (form?.city.dirty || form?.city.touched)">
      <label class="required">{{ 'GLOBAL.USER.CITY' | translate }}</label>
      <input type="text" formControlName="city" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.city.errors && (form.city.dirty || form.city.touched)">
          <div *ngIf="form.city.errors.required">
            {{ resourcesStore.i18n().resources.register.cityLabelError }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-control flex-1">
      <label class="required">{{ 'GLOBAL.USER.COUNTRY' | translate }}</label>
      <select formControlName="country">
        <option [value]="g.id" [text]="g.value" *ngFor="let g of fs.getCountries()"></option>
      </select>
    </div>
  </div>
  <div class="form-group my-datas-form">
    <div class="form-control flex-1" [class.error]="form?.mobile.errors && (form?.mobile.dirty || form?.mobile.touched)">
      <label class="required">{{ 'GLOBAL.USER.MOBILE' | translate }}</label>
      <input digitOnly decimalSeparator="" type="text" pattern="[0-9]*" inputmode="numeric" formControlName="mobile" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.mobile.errors && (form.mobile.dirty || form.mobile.touched)">
          <div *ngIf="form.mobile.errors.required">
            {{ resourcesStore.i18n().resources.register.phoneLabelError }}
          </div>
          <div *ngIf="form.mobile.errors.pattern">
            {{ 'INPUT.VALIDATION.PHONEPATTERN' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-control flex-1">
      <label>{{ 'GLOBAL.USER.PHONE' | translate }}</label>
      <input digitOnly decimalSeparator="" type="text" pattern="[0-9]*" inputmode="numeric" formControlName="phone" />
      <div class="form-error-container flex column no-top">
        <div *ngIf="form.phone.errors && (form.phone.dirty || form.phone.touched)">
          <div *ngIf="form.phone.errors.required">
            {{ resourcesStore.i18n().resources.register.phoneLabelError }}
          </div>
          <div *ngIf="form.phone.errors.pattern">
            {{ 'INPUT.VALIDATION.PHONEPATTERN' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-control flex-1">
      <label>{{ 'GLOBAL.USER.LANGUAGE' | translate }}</label>
      <select formControlName="langage">
        <option [value]="g.id" [text]="g.value" *ngFor="let g of fs.getLanguages()"></option>
      </select>
    </div>
  </div>
  <div class="form-group my-datas-form">
    <div class="form-control flex-2">
      <label>{{ 'GLOBAL.USER.EMAIL' | translate }}</label>
      <input type="text" formControlName="email" />
    </div>
    <div class="form-control flex-1">
      <label [class.addbirthdate]="!form.birthDate.value">{{ 'GLOBAL.USER.BIRTHDATE' | translate }}</label>
      <input *ngIf="form.birthDate.value" type="text" formControlName="birthDate" [attr.disabled]="''" />
      <button
        *ngIf="!form.birthDate.value"
        class="btn btn-primary m-j-center m-ai-center m-ac-center m-fit-content save-button birthdate-btn"
        (click)="openBirthDateModal()"
        [disabled]="operationPending">
        <div class="label">{{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}</div>
      </button>
    </div>
  </div>
  <div class="form-group row m-column m-ai-center m-j-center m-ac-center">
    <button class="btn btn-primary m-j-center m-ai-center m-ac-center m-fit-content save-button" (click)="update()" [disabled]="operationPending">
      <div class="label">{{ 'GLOBAL.ACTION.UPDATE' | translate }}</div>
      <div class="icon icon-right icon">
        <svg viewBox="0 0 28 28" *ngIf="!operationPending">
          <use xlink:href="#icon-save"></use>
        </svg>
        <div *ngIf="operationPending" class="loader loader-mini"></div>
      </div>
    </button>
  </div>
</form>

<app-modal [id]="'myDatasBirthDateModal'" template="birthDateModal" modalClass="birthDateModal" (updateBirthDate)="updateBirthDate($event)"></app-modal>
<app-flow-error *ngIf="globalError" [error]="globalError" />
