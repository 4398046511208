import { Component, Input, inject, effect } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { ChildrenEntityOrTopNavEntity, Footer, NewsLetterBlock } from '../../../state/models/resources';
import { ResourcesStore } from '../../../stores/resources.store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public resourcesStore = inject(ResourcesStore);

  // resources().i18n
  // resourcesStore.i18n()
  //
  // resources().isLoaded
  // resourcesStore.isLoaded()

  @Input() reduce = false;

  footerLocales: Footer;
  newsLetterBlock: NewsLetterBlock;
  newletterLocales: ChildrenEntityOrTopNavEntity;
  langueChangedSubscription: Subscription;

  constructor(
    public dataService: GetDatasService,
    private storage: StorageService,
    public breadcrumb: BreadcrumbService
  ) {
    effect(() => {
      if (this.resourcesStore.isLoaded()) {
        this.footerLocales = this.resourcesStore.i18n().footer;
        this.newletterLocales = this.resourcesStore.i18n().header.topNav[0];
        this.newsLetterBlock = this.resourcesStore.i18n().newsLetterBlock;
      }
    });
  }

  toogleElement(e) {
    const target = e.target.closest('.area');
    if (target) target.getElementsByClassName('area-links-container')[0]?.classList.toggle('display');
  }

  getNewsletterUrl() {
    return getVictoriaUrl(this.storage.languageCode) + '/' + this.storage.languageCode?.replace('_', '-') + '/newsletter';
  }
}
