<div class="demo-order-actions">
  <div class="demo-order-actions-container">
    <div class="flex row j-space-between">
      <p class="bold">{{ 'DEMO.TUNNEL.TOTALTOPAY' | translate }}</p>
      <p class="bold">{{ cart.totalToPay | agoraCurrency }}</p>
    </div>
    <div *ngIf="!(cart.preContractualInformationDocument?.required && !cart.preContractualInformationDocument?.signed) || !internalPaymentType" class="validation-text">
      <p class="p-s" *ngIf="!(cart.preContractualInformationDocument?.required && !cart.preContractualInformationDocument?.signed)" (click)="requestGoToTheTop.emit()">
        {{ 'DEMO.TUNNEL.VALIDATION.DIP' | translate }}
      </p>
      <p class="p-s" *ngIf="cart.preContractualInformationDocument?.required && cart.preContractualInformationDocument?.signed && !internalPaymentType">
        {{ 'DEMO.TUNNEL.VALIDATION.PAYMENT_METHOD' | translate }}
      </p>
    </div>

    <div class="cta-container">
      <button (click)="requestValidateCurrentStep.emit()" [disabled]="disableCTA">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{ 'DEMO.TUNNEL.ACTION.SHOPNOW' | translate }}</label>
      </button>
    </div>
  </div>
</div>
