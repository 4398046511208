import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  readonly api = inject(ApiService);

  async cancelPayment(paymentId: string) {
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).setAsCancelled.post();
  }
}
