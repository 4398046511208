import { Component, Input, OnInit } from '@angular/core';
import { AlertPositions, AlertTypes } from 'src/app/core/enums/alert-type.enum';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() id: string;
  @Input() icon = '#icon-alert-success';
  @Input() message = '';
  @Input() urlLink: string = undefined;
  @Input() type: AlertTypes | string = AlertTypes.INFO;
  @Input() position: AlertPositions | string = AlertPositions.BOTTOM;

  public AlertTypes = AlertTypes;
  public AlertPositions = AlertPositions;

  constructor(public alertService: AlertService) {}

  ngOnInit() {}
}
