/* eslint-disable @typescript-eslint/naming-convention */
import { Component, effect, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { StorageService } from './core/services/storage.service';
import { TriggerService } from './core/services/trigger.service';
import { GetDatasService } from './core/services/get-datas.service';
import { JWTService } from './core/services/jwt.service';
import { getEshopUrlByLang, getSSOLocale } from './core/utils/filter.utils';
import { TranslationService } from './core/services/translation.service';
import { datadogRum } from '@datadog/browser-rum';
import { Alert } from './core/models/alert.model';
import { AlertService } from './core/services/alert.service';
import { ErrorService } from './core/services/error.service';
import { LoggerService } from './core/services/logger.service';
import { ScreenService } from './core/services/screen.service';
import { UserStore } from './stores/user.store';
import { CartStore } from './stores/cart.store';
import { WishlistStore } from './stores/wishlist.store';
import { ContextStore } from './stores/context.store';
import { CartService } from './core/services/V2/cart.service';
import { SocketService } from './core/services/V2/sockets.service';
import { CheckoutStore } from './stores/chechout.store';
import { CartStatusObject, DemoHostessGiftsResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from './stores/resources.store';
import { getStatusIdFromClubStatus } from './core/utils/common.utils';
import { ActivityNotification, ActivityType } from './core/types/activityNotification.type';
import { CatalogService } from './core/services/V2/catalog.service';
import { NotificationStore } from './stores/notification.store';
import { NotificationIcon, NotificationStyle } from './core/types/notification.options.type';
import { ErrorResponse } from './shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private firstLoad = true;
  public reduceDesign: boolean = undefined;
  public noLayout: boolean = undefined;
  public pinkBreadcrumb = false;
  public whiteLayout = false;
  public largeScreen = false;
  public alert: Alert = new Alert();
  public loginLogoutRequested = false;
  public isSandbox = environment.isSandbox;
  public globalError:ErrorResponse

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);
  private wishlistStore = inject(WishlistStore);
  private cartStore = inject(CartStore);
  private checkoutStore = inject(CheckoutStore);
  readonly socketService = inject(SocketService);
  readonly resourcesStore = inject(ResourcesStore);
  readonly notificationStore = inject(NotificationStore);
  readonly catalogService = inject(CatalogService);
  readonly translate = inject(TranslateService);

  victoriaToken = 'Not Provided';
  configId = 'desktop';
  checkSub: Subscription;
  authSub: Subscription;
  isLogin = false;
  isMenuOpen = false;
  isUserMenuOpen = false;
  showVersion = environment.showVersion;
  version = environment.version;

  //INTERNAL
  clientId: string;
  connectionCalled = false;
  fullPath = '/';
  subscriptions: Subscription[] = [];
  currentUrl: string;

  private delegateId = this.cookie.get('delegateId') ?? '0';
  private profile = this.cookie.get('victoria_profile') ?? '{}';

  constructor(
    public jwtService: JWTService,
    private getDatasService: GetDatasService,
    private trigger: TriggerService,
    public breadcrumb: BreadcrumbService,
    private cookie: CookieService,
    private oidcEventService: PublicEventsService,
    public translation: TranslationService,
    private storage: StorageService,
    private authService: OidcSecurityService,
    private route: Router,
    private active: ActivatedRoute,
    private alertService: AlertService,
    public errorService: ErrorService,
    private logger: LoggerService,
    public screen: ScreenService,
    private cartService: CartService
  ) {
    this.clientId = this.cookie.get('v_gi') ?? '';

    this.subscriptions.push(
      this.alertService.triggerAlert$.subscribe(data => {
        if (data) {
          this.alert.message = data.message;
          this.alert.icon = data.icon;
          this.alert.position = data.position;
          this.alert.type = data.type;
          this.alert.urlLink = data.urlLink;
          this.alertService.display(data.id, data.duration);
        }
      })
    );

    effect(
      async () => {
        if (this.contextStore.isLoaded()) {
          await Promise.all([this.wishlistStore.loadWishlist(), this.cartStore.loadCart()]);
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      async () => {
        if (this.userStore.isAuthenticated()) {
          await this.userUpdateListenerRegistration();
        }
      },
      { allowSignalWrites: true }
    );

    // if (this.localeCookie && this.localeCookie != null && this.localeCookie != '') this.storage.languageCode = this.localeCookie;
    // else if (!this.storage.languageCode || this.storage.languageCode == null) {
    //   this.storage.languageCode = getDefaultLangByUrl(window.location.origin);
    //   this.cookie.set("v_locale", this.storage.languageCode, 365, '/', getCookieDomainByLang(this.storage.languageCode));
    // }
    //
    // this.translate.use(this.storage.languageCode).subscribe(_ => {
    //   this.translation.initContextId(this.storage.languageCode);
    //   this.translation.loadJsonFiles();
    // });

    this.checkAuthentication();

    this.subscriptions.push(
      this.oidcEventService
        .registerForEvents()
        .pipe(filter(notification => notification.type === EventTypes.NewAuthenticationResult || notification.type === EventTypes.IdTokenExpired || notification.type === EventTypes.TokenExpired))
        .subscribe(
          data => {
            const token = this.jwtService.getToken();

            if (data.type === EventTypes.IdTokenExpired || data.type === EventTypes.TokenExpired) {
              const validCookie = this.jwtService.hasTokenCookie();

              if (!validCookie) {
                this.authService.logoffLocal();
                this.authService.revokeAccessToken(token);
                this.jwtService.deleteTokenCookie();
                this.route.navigate(['/']);
              }
            } else if (data.type === EventTypes.NewAuthenticationResult) {
              if (data.value.isAuthenticated) {
                //SET APPROPRIATED COOKIE DURATION
                let storedValues: any = localStorage.getItem(this.configId);
                storedValues = storedValues != null ? JSON.parse(storedValues) : null;

                if (storedValues) {
                  const accessTokenExpirationDate = new Date(storedValues.access_token_expires_at);
                  this.jwtService.setTokenCookie(this.translate.currentLang, accessTokenExpirationDate);
                }

                if (this.firstLoad) {
                  this.firstLoad = false;
                  //this.route.navigate(['/club/home']);
                }
              } else if (data.value.isRenewProcess) {
                this.route.navigate(['/']);
              }

              if (environment.datadog_active) {
                datadogRum.setUserProperty('clientId', this.clientId);
                datadogRum.setUserProperty('delegateId', this.delegateId);
                datadogRum.setUserProperty('isConnected', (token != undefined).toString());
              }

              if (this.profile) {
                const jsonProfile = JSON.parse(this.profile) || '{}';
                if (jsonProfile.firstName) datadogRum.setUserProperty('name', (jsonProfile.firstName ?? '') + ' ' + (jsonProfile.lastName ?? ''));
                if (jsonProfile.email) datadogRum.setUserProperty('email', jsonProfile.email ?? '');
                if (jsonProfile.delegateId) datadogRum.setUserProperty('linkedDelegateId', jsonProfile.delegateId);
                if (jsonProfile.linkedDelegateId) datadogRum.setUserProperty('linkedDelegateId', jsonProfile.linkedDelegateId);

                // (window as any).rudderanalytics.identify(
                //   jsonProfile.email,
                //   {
                //     email: jsonProfile.email,
                //     first_name: jsonProfile.firstName,
                //     last_name: jsonProfile.lastName,
                //     delegate_id: jsonProfile.delegateId
                //   }
                // );
              }
            }
          },
          err => {}
        )
    );
  }

  ngOnInit() {
    this.authService.checkAuth().subscribe(async ({ isAuthenticated, accessToken }) => {
      this.globalError = undefined;
      
      await this.userStore.setIsAuthenticated(isAuthenticated).catch(err => this.globalError = err);
      const anonymousCartId = this.storage.getAnonymousCartId();

      if (isAuthenticated) {
        this.userStore.setRolesFromToken(accessToken);
        await this.userStore.loadUserActiveDemoCode().catch(err => console.log(err));

        if (anonymousCartId) {
          await this.cartService.merge(anonymousCartId);
          this.storage.deleteAnonymousCartId();
        }

        if (this.userStore.profile()) {
          this.storage.clientFirstName = this.userStore.profile()?.firstname;
        }

        if (this.userStore.clubInformation()) {
          this.storage.registeredStatusId = getStatusIdFromClubStatus(this.userStore.clubInformation().clubStatus);
          this.getDatasService.updateClientStatusCookies(getStatusIdFromClubStatus(this.userStore.clubInformation().clubStatus));
        }

        // if (this.contextStore.isLoaded()) {
        //   await Promise.all([this.wishlistStore.loadWishlist(), this.cartStore.loadCart()]);
        // }
      }
    });

    this.currentUrl = window.location.pathname;
    this.subscriptions.push(
      this.active.queryParams.subscribe(params => {
        if (params.redirectTo) {
          this.storage.redirectTo = params.redirectTo;
        }
      })
    );

    this.subscriptions.push(
      this.trigger.requestLogin$.subscribe(d => {
        if (d == true) {
          this.login();
        }
      })
    );

    this.subscriptions.push(
      this.route.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.fullPath = event.url;
          if (environment.dev) console.log('Navigation to : ', this.fullPath);
          this.loginLogoutRequested = (event.url == '/logout')
        }
      })
    );

    this.subscriptions.push(
      this.jwtService.isAuthenticated$.subscribe(async isAuthenticated => {
        if (!isAuthenticated) {
          this.jwtService.deleteTokenCookie();
          this.jwtService.resetRoles();
        }
      })
    );
  }

  public login() {
    this.authService.authorize(null, {
      customParams: {
        locale: getSSOLocale(this.contextStore.locale()),
        back: getEshopUrlByLang(this.contextStore.locale()),
      },
    });
  }

  public registerListeners() {
    const clubElemList = Array.from(document.getElementsByClassName('club'));
    const megaMenuElems = Array.from(document.getElementsByClassName('mega-menu-content'));

    clubElemList.forEach(elem => {
      elem.addEventListener('mouseover', e => this.hoverMegaMenu(e));
    });

    megaMenuElems.forEach(megaElem => {
      megaElem.addEventListener('mouseout', () => this.hideAllMegaMenu(undefined, undefined));
    });
  }

  public updateMenuState($value) {
    this.isMenuOpen = $value;
  }

  public updateUserMenuState($value) {
    this.isUserMenuOpen = $value;
  }

  private async checkAuthentication() {
    this.storage.setTokenRenewing(true);
    if (this.authSub) this.authSub.unsubscribe();
    if (this.checkSub) this.checkSub.unsubscribe();

    let storedValues: any = localStorage.getItem(this.configId);
    if (storedValues && storedValues != '') {
      storedValues = JSON.parse(storedValues);
      this.logger.warnData('==> Identity Environment Match : ', storedValues?.authWellKnownEndPoints?.issuer.startsWith(environment.identity_url.substring(0, 20)));
      this.logger.warnData(
        '==> Global evaluation result : ',
        storedValues && true && storedValues != '' && storedValues?.authWellKnownEndPoints?.issuer &&
        !storedValues?.authWellKnownEndPoints?.issuer.startsWith(environment.identity_url.substring(0, 20))
      );
    }

    //Check Configuration
    if (
      storedValues && storedValues != '' && storedValues?.authWellKnownEndPoints?.issuer &&
      !storedValues?.authWellKnownEndPoints?.issuer.startsWith(environment.identity_url.substring(0, 20))
    ) {
      this.logger.warn('==> Configuration Mismatch, cleaning state...');
      this.jwtService.cleanState();
      this.jwtService.deleteTokenCookie();
      window.location.reload();
    }

    if (this.jwtService.isAuthenticated()) {
      this.storage.setTokenRenewing(false);
    }
  }

  private clickDropDownHandler(e) {
    const dropdownClicked = e.target.closest('.actions-dropdown');

    if (dropdownClicked) {
      this.closeDropDown();
      dropdownClicked.getElementsByClassName('dropdown-content')[0]?.classList.add('show');
      dropdownClicked.getElementsByTagName('svg')[0]?.classList.add('drop-rotate');
    } else this.closeDropDown();
  }

  private closeDropDown() {
    const dropdowns = document.getElementsByClassName('actions-dropdown');

    Array.from(dropdowns).forEach(x => {
      x.getElementsByClassName('dropdown-content')[0]?.classList.remove('show');
      x.getElementsByTagName('svg')[0]?.classList.remove('drop-rotate');
    });
  }

  private hoverMegaMenu(e) {
    const menuButtonClicked2 = e.target.parentElement.closest('.club');

    if (menuButtonClicked2) {
      const menu = menuButtonClicked2.children[1];
      const carret = menuButtonClicked2.children[0].children[1];
      const jumbo = document.getElementsByClassName('jumbo-shadow')[0];

      this.hideAllMegaMenu(menu, carret);

      if (menu && menu.classList.contains('block-menu')) {
        //???
      } else if (menu) {
        if (carret) carret.classList.add('rotate-carret');
        if (jumbo) jumbo.classList.add('visible');
        menu.classList.add('block-menu');
      }
    } else this.hideAllMegaMenu(undefined, undefined);
  }

  private hideAllMegaMenu(current, currentCarret) {
    const megaMenus = document.getElementsByClassName('mega-menu-content');
    const openCarrets = document.getElementsByClassName('carret-area');

    Array.from(megaMenus).forEach(item => {
      if (item != current) item.classList.remove('block-menu');
    });

    Array.from(openCarrets).forEach(item => {
      if (item != currentCarret) item.classList.remove('rotate-carret');
    });

    if (!current && !currentCarret) {
      const jumbo = document.getElementsByClassName('jumbo-shadow')[0];
      if (jumbo) jumbo.classList.remove('visible');
    }
  }

  // private openRedirectionModal() {
  //   this.modalService.open('#redirectionModal');
  //   setTimeout(_ => {
  //     this.jwtService.deleteTokenCookie();
  //     this.authService.logoff();
  //     window.location.href = getEshopUrlByContext(this.currentClient.contextId) + '/' + this.currentClient.locale;
  //   }, 5000);
  // }

  private async userUpdateListenerRegistration() {
    await this.socketService.listenForUserUpdates({
      onCartCreated: cart => {
        return this.cartStore.updateCartFromSocket(cart);
      },
      onCartChange: async cart => {
        if (cart.id == this.cartStore.cart()?.id) {
          await this.cartStore.updateCartFromSocket(cart);
          await this.checkoutStore.setUserNavigationStep();
          await this.checkoutStore.setInternalDeliveryDataIfNeeded();
        }
      },
      onWishlistChange: wishlist => {
        return this.wishlistStore.updateFromSocket(wishlist);
      },
      onUserDemoHostessGiftsChanged: async (demoId: string, gifts: DemoHostessGiftsResponse) => {
        await this.cartStore.updateGiftsFromSocket(gifts, this.contextStore.locale(), this.contextStore.contextId());
      },
      onUserActivityOccurred: async (activity: ActivityNotification) => {
        await this.handleUserNotification(activity);
      },
      onReconnected: () => {
        console.log('listenForUserUpdates is now re-connected');
        if (this.contextStore.isLoaded()) {
          return Promise.all([this.wishlistStore.loadWishlist(), this.cartStore.loadCart()]);
        }
      },
    });
  }

  private async handleUserNotification(activity: ActivityNotification) {
    switch (activity.type) {
      case ActivityType.AddToCart: {
        const productVariantId = activity.metadata.productVariantId;
        const product = await this.catalogService.getProductsByVariantIdsPromise(this.contextStore.contextId(), this.contextStore.locale(), [productVariantId]);
        if (product && activity.actorUserId == activity.affectedUserId)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.ARTICLEXADDEDTOCART', { X: product.products[0]?.name }),
            icon: NotificationIcon.SUCCESS,
            style: NotificationStyle.SUCCESS,
          });
        else if (product)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XADDEDARTICLEYTOCART', { X: activity.actorName, Y: product.products[0]?.name }),
            icon: NotificationIcon.SUCCESS,
            style: NotificationStyle.SUCCESS,
          });

        break;
      }
      case ActivityType.AddToWishlist: {
        const productId = activity.metadata.productId;
        const product = await this.catalogService.getProductsPromise(this.contextStore.contextId(), this.contextStore.locale(), [productId]);
        if (product && activity.actorUserId == activity.affectedUserId)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.ARTICLEXADDEDTOWISHLIST', { X: product.products[0]?.name }),
            icon: NotificationIcon.SUCCESS,
            style: NotificationStyle.SUCCESS,
          });
        else if (product)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XADDEDARTICLEYTOWISHLIST', { X: activity.actorName, Y: product.products[0]?.name }),
            icon: NotificationIcon.SUCCESS,
            style: NotificationStyle.SUCCESS,
          });
        break;
      }
      case ActivityType.RemoveFromCart: {
        const productVariantId = activity.metadata.productVariantId;
        const product = await this.catalogService.getProductsByVariantIdsPromise(this.contextStore.contextId(), this.contextStore.locale(), [productVariantId]);
        if (product && activity.actorUserId == activity.affectedUserId)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XDELETEDFROMCART', { X: product.products[0]?.name }),
            icon: NotificationIcon.INFO,
            style: NotificationStyle.INFO,
          });
        else if (product)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XDELETEDYFROMCART', { X: activity.actorName, Y: product.products[0]?.name }),
            icon: NotificationIcon.INFO,
            style: NotificationStyle.INFO,
          });
        break;
      }
      case ActivityType.RemoveFromWishlist: {
        const productId = activity.metadata.productId;
        const product = await this.catalogService.getProductsPromise(this.contextStore.contextId(), this.contextStore.locale(), [productId]);
        if (product && activity.actorUserId == activity.affectedUserId)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XDELETEDFROMWISHLIST', { X: product.products[0]?.name }),
            icon: NotificationIcon.INFO,
            style: NotificationStyle.INFO,
          });
        else if (product)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XDELETEDYFROMWISHLIST', { X: activity.actorName, Y: product.products[0]?.name }),
            icon: NotificationIcon.INFO,
            style: NotificationStyle.INFO,
          });

        break;
      }
      case ActivityType.CartApproved:
        this.notificationStore.notify({
          message: this.translate.instant('SOCKET.NOTIFICATION.CARTVALIDATEDBYX', { X: activity.actorName }),
          icon: NotificationIcon.SUCCESS,
          style: NotificationStyle.SUCCESS,
        });
        break;
      case ActivityType.CartStatusUpdated:
        if (activity.actorUserId == activity.affectedUserId) {
          switch (activity.metadata.newStatus) {
            case CartStatusObject.Finalized:
            case CartStatusObject.Confirmed:
              this.notificationStore.notify({
                message: this.translate.instant('SOCKET.NOTIFICATION.CART' + activity.metadata.newStatus?.toUpperCase()),
                icon: NotificationIcon.SUCCESS,
                style: NotificationStyle.SUCCESS,
              });
              break;
          }
        } else {
          switch (activity.metadata.newStatus) {
            case CartStatusObject.Finalized:
            case CartStatusObject.Confirmed:
              this.notificationStore.notify({
                message: this.translate.instant('SOCKET.NOTIFICATION.CART' + activity.metadata.newStatus?.toUpperCase() + 'BYX', { X: activity.actorName }),
                icon: NotificationIcon.INFO,
                style: NotificationStyle.INFO,
              });
              break;
          }
        }
        break;
      case ActivityType.CartItemVariantUpdated: {
        console.log('CartItemVariantUpdated : ', activity);
        const productVariantId = activity.metadata.productVariantId;
        const product = await this.catalogService.getProductsByVariantIdsPromise(this.contextStore.contextId(), this.contextStore.locale(), [productVariantId]);
        if (product && activity.actorUserId == activity.affectedUserId)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.ARTICLEXUPDATED', { X: product.products[0]?.name }),
            icon: NotificationIcon.SUCCESS,
            style: NotificationStyle.SUCCESS,
          });
        else if (product)
          this.notificationStore.notify({
            message: this.translate.instant('SOCKET.NOTIFICATION.XUPDATEDARTICLEY', { X: activity.actorName, Y: product.products[0]?.name }),
            icon: NotificationIcon.INFO,
            style: NotificationStyle.INFO,
          });
        break;
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  @HostListener('document:click', ['$event'])
  clickHandler(e) {
    const menu = document.getElementsByClassName('menu-visible')[0];
    const menuButtonClicked = e.target.closest('.btn-menu');
    const menuMobileButtonClicked = e.target.closest('.menu-action');
    const menuDropDownLangueClicked = e.target.closest('.select-lang-area');
    const menuNavigable = e.target.closest('.navigable');

    const langMenu = document.getElementsByClassName('lang-options open')[0];
    const langMenuMobile = document.getElementsByClassName('lang-options-mobile open')[0];

    if (langMenu && !menuDropDownLangueClicked) langMenu.classList.remove('open');
    if (langMenuMobile && !menuDropDownLangueClicked) langMenuMobile.classList.remove('open');

    //DropDownHandler
    this.clickDropDownHandler(e);

    //close modal
    if (e.target.id === 'myModal' && e.target.style.display === 'flex') {
      e.target.style.display = 'none';
    }

    if (menuButtonClicked == null && menuMobileButtonClicked == null && menu && !menuDropDownLangueClicked && !menuNavigable) {
      menu.classList.toggle('menu-visible');
      document.getElementsByTagName('main')[0].classList.toggle('fixedHeight');
      this.isMenuOpen = !this.isMenuOpen;
    } else if (menuButtonClicked == null && menuMobileButtonClicked == null && menu && (menuDropDownLangueClicked || menuNavigable)) {
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.screen.isMobile) this.hideAllMegaMenu(undefined, undefined);
  }
}
