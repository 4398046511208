import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommandeComponent } from './components/commande/commande.component';
import { PanierComponent } from './components/panier/panier.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { CommunicationsComponent } from './components/communications/communications.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { PublicOrderPaymentComponent } from './components/public-order-payment/public-order-payment.component';
import { EsoLandingComponent } from '../landing-pages/esolanding/eso-landing.component';
import { LanguageResolver } from 'src/app/core/resolver/langFragmentResolver.service';
import { CommandeConfirmationComponent } from './components/commande-confirmation/commande-confirmation.component';
import { PublicOrderPaymentConfirmationComponent } from './components/public-order-payment-confirmation/public-order-payment-confirmation.component';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: 'cart',
    pathMatch: 'full',
  },
  {
    path: 'cart',
    component: PanierComponent,
    data: {
      breadcrumb: 'GLOBAL.CART',
      showJumbotron: false,
      usePinkColor: true,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showDemoBanner: true,
    },
  },
  {
    path: 'checkout',
    component: CommandeComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: 'checkout/:cartId/confirmation',
    component: CommandeConfirmationComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: 'cart/:cartId/checkout',
    component: PublicOrderPaymentComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: 'cart/:cartId/checkout/confirmation',
    component: PublicOrderPaymentConfirmationComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    data: {
      breadcrumb: 'GLOBAL.WISHLIST',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreen: true,
      showDemoBanner: true,
    },
  },
  {
    path: 'demo/:delegateId',
    resolve: [LanguageResolver],
    component: EsoLandingComponent,
    data: {
      breadcrumb: 'GLOBAL.DEMO',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showHeader: false,
      showFooterDisclaimer: false,
      enlargeScreenMobile: false,
      maxScreen: true,
      usePinkColor: false,
    },
  },
  {
    path: 'communications',
    component: CommunicationsComponent,
    data: {
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreenMobile: true,
      enlargeScreen: true,
      showHeader: false,
      noContainer: true,
    },
  },
  {
    path: 'communications/optin',
    component: OptInComponent,
    data: {
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreenMobile: true,
      enlargeScreen: true,
      showHeader: false,
      noContainer: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicPagesRoutingModule {}
