import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CartResponse, DelegateResponse, GetCartAvailablePaymentTypesResponse_PaymentOption, PaymentType, PaymentTypeObject } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../stores/resources.store';
import { getProfilePictureOrDefault } from '../../../../core/utils/filter.utils';

@Component({
  selector: 'app-payment-mode-selection',
  templateUrl: './payment-mode-selection.component.html',
  styleUrls: ['./payment-mode-selection.component.scss'],
})
export class PaymentModeSelectionComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);

  @Input() isPublicLink = false;
  @Input() delegate: DelegateResponse;
  @Input() cart: CartResponse;
  @Input() internalPaymentType: PaymentType;
  @Input() paymentOptions: GetCartAvailablePaymentTypesResponse_PaymentOption[];
  @Output() requestSaveInternalPaymentType = new EventEmitter<PaymentType>();

  ngOnInit() {
    this.paymentOptions = this.isPublicLink ? this.paymentOptions.filter(p => p.paymentType != 'HandledByDelegate') : this.paymentOptions;
  }

  selectPaymentMethod(type: PaymentType) {
    this.requestSaveInternalPaymentType.emit(type);
  }

  protected readonly PaymentTypeObject = PaymentTypeObject;
  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
