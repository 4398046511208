export enum ErrorType {
  PROFILE = 1,
  DELEGATEPROFILE = 2,
  GETORDER = 3,
  GETORDERS = 4,
  SAVEORDER = 5,
  SAVEDELIVERY = 6,
  GETPAYMENTINFO = 7,
  SUMMARY = 8,
  LOOKUP = 9,
  DATATABLE = 10,
  CLIENTACTIONS = 11,
  VOUCHERS = 12,
  PLATES = 13,
  PRODUCTS = 14,
  DEMO = 15,
}
