import { StorageService } from 'src/app/core/services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sandbox-bar',
  templateUrl: './sandbox-bar.component.html',
  styleUrls: ['./sandbox-bar.component.scss'],
})
export class SandboxBarComponent implements OnInit {
  public closed = this.storage.sandboxBannerDismissed;

  constructor(private storage: StorageService) {}

  ngOnInit() {}

  close() {
    this.storage.sandboxBannerDismissed = true;
    this.closed = this.storage.sandboxBannerDismissed;
  }
}
