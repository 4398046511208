import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { ThemeModule } from './shared/theme/theme.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationService } from './core/services/translation.service';
import { AuthConfigModule } from './config/auth-config.module';
import { CookieService } from 'ngx-cookie-service';
import { ComponentsModule } from './shared/components/components.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PagesRoutingModule } from './features/club-pages/club-page-routing.module';
import { PublicPagesRoutingModule } from './features/public-pages/public-pages-routing.module';
import { StorageService } from './core/services/storage.service';
import { PipeModule } from './core/pipes/pipe.module';
import { ErrorComponent } from './features/club-pages/components/error/error.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { QRCodeModule } from 'angularx-qrcode';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { AuthStorageService } from './config/AuthStorageService.service';
import { MissingTranslationFromJSONHandler } from './core/utils/missing-translation-handlet';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ErrorHandlerService } from './core/services/errorHandling.service';
import { SupportComponent } from './features/support/support.component';
import { AgoraCurrency } from './core/pipes/agoraCurrency.pipe';
import { AgoraDateTime } from './core/pipes/agoraDate.pipe';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DemoStore } from './stores/demo.store';
import { UserStore } from './stores/user.store';
import { ContextStore } from './stores/context.store';
import { ResourcesStore } from './stores/resources.store';
import { RedirectionStore } from './stores/redirection.store';
import { NotificationStore } from './stores/notification.store';
import { ListingDemoStore } from './stores/listingDemo.store';
import { WishlistStore } from './stores/wishlist.store';
import { CartStore } from './stores/cart.store';
import { CheckoutStore } from './stores/chechout.store';
import { CheckoutPublicStore } from './stores/checkoutPublic.store';
import { CatalogStore } from './stores/catalog.store';
import './core/extensions/extensions';
import { DesoLandingComponent } from './features/landing-pages/desolanding/deso-landing.component';
import { RedirectionComponent } from './features/public-pages/components/redirection/redirection.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, ErrorComponent, SupportComponent, DesoLandingComponent, RedirectionComponent],
  imports: [
    HttpClientModule,
    CookieModule.withOptions(),
    BrowserModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    IonicModule.forRoot(),
    ComponentsModule,
    AppRoutingModule,
    PagesRoutingModule,
    PublicPagesRoutingModule,
    ThemeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient, StorageService],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationFromJSONHandler },
      useDefaultLang: false,
    }),
    AuthConfigModule,
    PipeModule,
    InputMaskModule,
    QRCodeModule,
    NgxSliderModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    // }),
  ],
  providers: [
    CookieService,
    { provide: AbstractSecurityStorage, useClass: AuthStorageService },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MissingTranslationFromJSONHandler,
    AgoraCurrency,
    AgoraDateTime,
    DemoStore,
    UserStore,
    ContextStore,
    ResourcesStore,
    RedirectionStore,
    NotificationStore,
    ListingDemoStore,
    WishlistStore,
    CartStore,
    CheckoutStore,
    CheckoutPublicStore,
    CatalogStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
