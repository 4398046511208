import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ag-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class AgoraButtonComponent {
  //IN
  //ICON
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  //Custom Class Button
  @Input() classes: string;
  //Custom Class Icon
  @Input() iconClasses: string;
  //Button Label
  @Input() label: string;
  //Button Actif/Inactif
  @Input() disabled: boolean;
  @Input() showLoader = false;

  //OUT
  @Output() triggerFunction = new EventEmitter();

  clicked() {
    this.triggerFunction.emit();
  }
}
