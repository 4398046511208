import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MultipartBody } from '@microsoft/kiota-abstractions';
import { CreateDocumentRequest, UpdateDocumentRequest } from '@victoria-company/agora-client';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  private readonly api = inject(ApiService);

  async getDocumentsForStaff() {
    const client = this.api.getClientInstance();
    return client.api.documents.get();
  }
  async getDocuments(demoCodeOrId: string, locale: string) {
    if (locale) locale = locale.replace('-', '_');

    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).documents.byLocale(locale).get();
    // return new Promise<DocumentResponse[]>(res => res(this.mock));
  }

  async uploadDocumentAttachment(file: File) {
    const body = new MultipartBody();
    const buffer = await file.arrayBuffer();
    body.addOrReplacePart(`attachment";filename="${file.name}`, 'application/octet-stream', buffer);

    const client = this.api.getClientInstance();
    return client.api.documentAttachments.upload.post(body);
  }

  async createDocument(request:CreateDocumentRequest){
    const client = this.api.getClientInstance();
    return client.api.documents.post(request);
  }

  async updateDocument(documentId:string, request:UpdateDocumentRequest){
    const client = this.api.getClientInstance();
    return client.api.documents.byDocumentId(documentId).put(request)
  }

  async deleteDocumentById(documentId:string){
    const client = this.api.getClientInstance();
    return client.api.documents.byDocumentId(documentId).delete();
  }
}
