import { Component, inject } from '@angular/core';
import { UserStore } from '../../../stores/user.store';
import { ContextStore } from '../../../stores/context.store';
import { CartStore } from '../../../stores/cart.store';
import { ListingDemoStore } from '../../../stores/listingDemo.store';
import { RedirectionStore } from '../../../stores/redirection.store';

@Component({
  selector: 'app-go-to-demo-banner',
  templateUrl: './go-to-demo-banner.component.html',
  styleUrls: ['./go-to-demo-banner.component.scss'],
})
export class GoToDemoBannerComponent {
  private userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly cartStore = inject(CartStore);
  readonly listingDemoStore = inject(ListingDemoStore);
  readonly redirectionStore = inject(RedirectionStore);

  async goToDemo() {
    if (!this.userStore.roles()?.includes('Delegate') && this.cartStore.cart()?.status == 'Active')
      await this.listingDemoStore.affectUserCartToDemo(this.cartStore.cart().id, this.userStore.userActiveDemoCode());
    await this.redirectionStore.redirectTo(`/myvictoria/demo/${this.userStore.userActiveDemoCode()}`);
  }
}
