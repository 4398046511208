import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { createMask } from '@ngneat/input-mask';
import { ClubService } from '../../../../core/services/V2/club.service';
import { UserStore } from '../../../../stores/user.store';
import { DomainException } from '../../../agora-pages/demo/enums/exceptions.enum';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-point-encoding',
  templateUrl: './point-encoding.component.html',
  styleUrls: ['./point-encoding.component.scss'],
})
export class PointEncodingComponent implements OnInit {
  readonly clubService = inject(ClubService);
  readonly userStore = inject(UserStore);

  pointEncodingForm: UntypedFormGroup;
  error: ErrorResponse;
  addCodeLoading = false;
  pointCodeMask: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    // @ts-ignore
    if (this.router.rawUrlTree.fragment === 'action-history') {
      const element = document.querySelector('#action-history');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    this.pointEncodingForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
    this.pointCodeMask = createMask<string>(createMask('(A|9)(A|9)(A|9)(A|9)-(A|9)(A|9)(A|9)(A|9)-(A|9)(A|9)(A|9)(A|9)'));
  }

  async saveCode() {
    if (!this.pointEncodingForm.valid) {
      this.error = { errorCode: 422, domainExceptionName: DomainException.CodeNotFoundDomainException };
      return;
    }

    if (this.pointEncodingForm.getRawValue()?.code?.includes('X')) {
      this.error = { errorCode: 422, domainExceptionName: DomainException.CodeNotFoundDomainException };
      return;
    }

    if (!this.addCodeLoading) {
      this.addCodeLoading = true;
      this.error = undefined;

      //TODO handle error properly
      try {
        await this.clubService.addClubCode(this.pointEncodingForm.getRawValue().code);
        await this.userStore.getClubActions(this.userStore.profile()?.id);
        await this.userStore.loadClubInformation();
        this.pointEncodingForm.controls.code.setValue(null);
      } catch (err) {
        this.error = err;
      } finally {
        this.addCodeLoading = false;
      }
    }
  }

  protected readonly DomainException = DomainException;
}
