import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { Router } from '@angular/router';
import { ContextStore } from '../../../stores/context.store';
import { UserStore } from '../../../stores/user.store';
import { CartStore } from '../../../stores/cart.store';
import { RedirectionStore } from '../../../stores/redirection.store';
import { ListingDemoStore } from '../../../stores/listingDemo.store';
import { getStatusIdFromClubStatus } from '../../../core/utils/common.utils';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
})
export class JumbotronComponent implements OnInit {
  readonly contextStore = inject(ContextStore);
  readonly userStore = inject(UserStore);
  readonly cartStore = inject(CartStore);
  readonly redirectionStore = inject(RedirectionStore);
  readonly listingDemoStore = inject(ListingDemoStore);

  @Input() reduce = false;

  demoCode: string;
  isDemoClient = false;

  constructor(
    public storage: StorageService,
    public router: Router
  ) {}

  ngOnInit() {}

  // async goToDemo() {
  //   await this.router.navigate([`/${this.contextStore.locale()}/myvictoria/demo/${this.userStore.userActiveDemoCode()}`]);
  // }

  async goToDemo() {
    if (!this.userStore.roles()?.includes('Delegate') && this.cartStore.cart()?.status == 'Active')
      await this.listingDemoStore.affectUserCartToDemo(this.cartStore.cart().id, this.userStore.userActiveDemoCode());
    await this.redirectionStore.redirectTo(`/myvictoria/demo/${this.userStore.userActiveDemoCode()}`);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (!document.getElementsByClassName('jumbotron')[0]) return;
    if (verticalOffset > 375) {
      document.getElementsByClassName('jumbotron')[0]?.classList.add('scrolled');
    } else {
      document.getElementsByClassName('jumbotron')[0]?.classList.remove('scrolled');
    }
  }

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
