export type ActivityNotification = {
  activityLogId: string;
  affectedUserId?: string;
  type: ActivityType;
  timeStamp: Date;
  actorUserId?: string;
  actorName?: string;
  metadata: any;
};

export enum ActivityType {
  AddToCart = 'AddToCart',
  RemoveFromCart = 'RemoveFromCart',
  CartApproved = 'CartApproved',
  AddToWishlist = 'AddToWishlist',
  RemoveFromWishlist = 'RemoveFromWishlist',
  CartItemVariantUpdated = 'CartItemVariantUpdated',
  CartStatusUpdated = 'CartStatusUpdated',
}
