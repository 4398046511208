<div class="breadcrum flex j-start ai-center ac-center d-only" [class.pink-container]="breadcrumb.usePinkColor$ | async">
  <div class="breadcrum-content">
    <ul>
      <li>
        <a [href]="'/'">Home</a>
        <svg class="breadcrum-separator" viewBox="0 0 32 32"><use xlink:href="#icon-arrow-right"></use></svg>
      </li>
      <li *ngFor="let breadcrumb of breadcrumbs$ | async">
        <a *ngIf="breadcrumb.url" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        <span *ngIf="!breadcrumb.url">{{ breadcrumb.label }}</span>
        <svg class="breadcrum-separator" viewBox="0 0 32 32"><use xlink:href="#icon-arrow-right"></use></svg>
      </li>
    </ul>
  </div>
</div>
