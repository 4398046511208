import {
  CartResponse,
  GetCartAvailablePaymentTypesResponse_PaymentOption,
  GetDemoResponse,
  PaymentResponse,
  PaymentResponseStatus,
  PaymentResponseStatusObject,
  PaymentType,
} from '@victoria-company/agora-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { CartService } from '../core/services/V2/cart.service';
import { CheckoutService } from '../core/services/V2/checkout.service';
import { CommonService } from '../core/services/V2/common.sevice';
import { Router } from '@angular/router';
import { ContextStore } from './context.store';

export interface CheckoutPublicState {
  isLoaded: boolean;
  isReloading: boolean;
  hasError: boolean;
  cart: CartResponse;
  demo: GetDemoResponse;
  internalPaymentType: PaymentType;
  paymentOptions: GetCartAvailablePaymentTypesResponse_PaymentOption[];
  paymentStatus: PaymentResponseStatus;
}

export const initialState: CheckoutPublicState = {
  cart: null,
  demo: null,
  internalPaymentType: null,
  paymentOptions: [],
  paymentStatus: null,
  isLoaded: false,
  isReloading: false,
  hasError: false,
};

export const CheckoutPublicStore = signalStore(
  { providedIn: 'root' },
  withDevtools('checkout public'),
  withState(initialState),
  withComputed(store => ({
    mustSignDIP: computed(() => !store.cart()?.preContractualInformationDocument?.signed),
    disableCTA: computed(() => !store.cart()?.preContractualInformationDocument?.signed || !store.internalPaymentType()),
    isValidLink: computed(() => getLinkValidityState(store.cart(), store.demo(), store.paymentStatus())),
  })),
  withMethods((store, contextStore = inject(ContextStore), cartService = inject(CartService)) => ({
    async loadCartById(cartId: string, loadCartOnly = false): Promise<void> {
      patchState(store, () => ({ isReloading: loadCartOnly }));

      const cart: CartResponse = await cartService.getCartByCartId(cartId);

      if (!cart) {
        patchState(store, () => ({ isReloading: false, hasError: true }));
        return;
      }

      if (loadCartOnly) {
        patchState(store, () => ({
          cart: {
            ...cart,
          },
        }));
      } else {
        const demoResponse = await cartService.getCartDemo(cart.demoId);
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());
        let payments: PaymentResponse = null;

        const sortedPayments =
          cart?.cartPayments
            .filter(p => p.type == 'Digital')
            .sort((a, b) => {
              if (a.creationDate > b.creationDate) return -1;
              else return 0;
            }) ?? [];

        if (sortedPayments[0]?.paymentId) {
          payments = await cartService.getCartPayment(sortedPayments[0].paymentId);
        }

        patchState(store, () => ({
          isReloading: false,
          isLoaded: true,
          hasError: false,
          cart: {
            ...cart,
          },
          demo: demoResponse,
          paymentOptions: paymentsOptions.paymentOptions,
          paymentStatus: payments?.status,
        }));
      }
    },
  })),
  withMethods((store, router = inject(Router), checkoutService = inject(CheckoutService), commonService = inject(CommonService)) => ({
    async signDip(cartId: string): Promise<void> {
      await checkoutService.postSignDipPromise(cartId);
      await store.loadCartById(cartId, true);
    },
    async proceedToPayment(cartId: string, locale: string, paymentType: PaymentType, redirectUrl: string): Promise<void> {
      const response = await commonService.postPaymentPromise(cartId, locale, paymentType, redirectUrl);
      if (response.checkoutUrl != null) window.location.href = response.checkoutUrl;
      else await router.navigateByUrl(redirectUrl);
    },
    setInternalPaymentType(paymentType: PaymentType) {
      patchState(store, () => ({
        internalPaymentType: paymentType,
      }));
    },
  }))
);

const getLinkValidityState = (cart: CartResponse, demo: GetDemoResponse, paymentStatus: PaymentResponseStatus) => {
  if (!cart || !demo.demo) return false;

  console.log('cart : ', cart);
  console.log('demo : ', demo);
  console.log('payment : ', paymentStatus);

  if (cart.status != 'Finalized' || paymentStatus == PaymentResponseStatusObject.Paid) return false;
  else return true;
};
