import { Gender } from '@victoria-company/agora-client';

export class DataForm {
  public gender: Gender;
  public firstName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public zip: string;
  public city: string;
  public country: string;
  public gsm: string;
  public phone: string;
  public langage: string;
  public email: string;
  public birthDate: Date;
  public zipCode: string;
  public mobile: string;
  public defaultAddressId: string;

  constructor() {}
}
