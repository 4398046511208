import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-booster-success',
  templateUrl: './booster-success.component.html',
  styleUrls: ['./booster-success.component.scss'],
})
export class BoosterSuccessComponent implements OnInit {
  @Input() rank: number = undefined;
  @Input() text: string = undefined;
  @Input() total: number = undefined;
  @Input() points: number = undefined;
  @Input() amount: number = undefined;

  constructor() {}

  ngOnInit() {}
}
