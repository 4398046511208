<div class="discounts-box">
  <h1 class="canela">{{ 'ORDER_TUNNEL.REDUCTIONS.TITLE' | translate }}</h1>
  <p class="description">{{ 'ORDER_TUNNEL.REDUCTIONS.CAPTION' | translate }}</p>
  <app-voucher-encoding
    [cart]="cart"
    [stateStatus]="voucherEncodingStatus"
    (requestValidateEncodedVoucherCode)="requestValidateEncodedVoucherCode.emit($event)"
    (requestResetStatuses)="requestResetStatuses.emit()"></app-voucher-encoding>
  <app-voucher-selection
    [cart]="cart"
    [stateStatus]="voucherSelectionStatus"
    [vouchers]="vouchers"
    [vouchersInCart]="vouchersInCart"
    (requestUseVoucher)="requestUseVoucher.emit($event)"
    (requestResetStatuses)="requestResetStatuses.emit()"></app-voucher-selection>
  <app-wallet-consumption
    [cart]="cart"
    [walletAvailableAmount]="walletAvailableAmount"
    [stateStatus]="walletConsumptionStatus"
    (requestUpdateWalletAmount)="requestUpdateWalletAmount.emit($event)"></app-wallet-consumption>
</div>
