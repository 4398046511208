import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private monitoringService: MonitoringService) {
    super();
  }

  handleError(error: Error) {
    if (environment.production == false) console.error(error);
    else this.monitoringService.logException(error);
  }
}
