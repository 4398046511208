import { Component, inject } from '@angular/core';
import { DocumentCategory, DocumentCategoryObject } from '@victoria-company/agora-client';
import { Router } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-staff-manager',
  templateUrl: './staff-manager.component.html',
  styleUrls: ['./staff-manager.component.scss'],
})
export class StaffManagerComponent {
  private router = inject(Router);
  private contextStore = inject(ContextStore);
  async goToManager(category: DocumentCategory) {
    await this.router.navigate([`/${this.contextStore.locale()}/club/manage/${category.toLowerCase()}`]);
  }

  protected readonly DocumentCategoryObject = DocumentCategoryObject;
}
