import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetProductsResponse } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  constructor(private api: ApiService) {}

  //WISHLIST
  public getWishlist(contextId: number, locale: string) {
    return this.api.getClient().pipe(
      switchMap(client => {
        return from(client.api.user.wishlist.get()).pipe(
          switchMap(data => {
            if (data.productIds.length === 0) return of<GetProductsResponse>({ products: [] });
            return from(
              client.api.catalog
                .byContextId(contextId)
                .byLanguageCode(locale)
                .products.get({
                  queryParameters: { productIds: data.productIds.join(',') },
                })
            );
          })
        );
      })
    );
  }

  public async getWishlistPromise(contextId: number, locale: string) {
    const client = this.api.getClientInstance();
    const wishlist = await client.api.user.wishlist.get();

    if (wishlist.productIds.length === 0) return { products: [] };
    else
      return client.api.catalog
        .byContextId(contextId)
        .byLanguageCode(locale)
        .products.get({
          queryParameters: { productIds: wishlist.productIds.join(',') },
        });
  }

  public add(productIds: string[]) {
    return this.api.getClient().pipe(
      switchMap(client => {
        return from(client.api.user.wishlist.post({ productIds })).pipe(map(() => productIds));
      })
    );
  }

  public addToUser(userId: string, productIds: string[]) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserId(userId).wishlist.post({ productIds });
  }

  public async addPromise(productIds: string[]) {
    const client = this.api.getClientInstance();
    await client.api.user.wishlist.post({ productIds });
    return productIds;
  }

  public delete(productId: string) {
    return this.api.getClient().pipe(
      switchMap(client => {
        return from(client.api.user.wishlist.delete({ productId })).pipe(map(() => productId));
      })
    );
  }

  public deleteFromUser(userId:string, productId:string){
    const client = this.api.getClientInstance();
    return client.api.users.byUserId(userId).wishlist.delete({productId})
  }

  public async deletePromise(productId: string) {
    const client = this.api.getClientInstance();
    return client.api.user.wishlist.delete({ productId });
  }
}
