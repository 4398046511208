<div class="jumbotron" [class.withBanner]="userStore.userActiveDemoCode()" *ngIf="!reduce">
  <div *ngIf="userStore.userActiveDemoCode()" class="cta-container primary flex row joinUsBanner">
    <button class="btn btn-primary flex party-button d-gap-10" (click)="goToDemo()">
      <svg viewBox="0 0 32 32" class="icon">
        <use xlink:href="#icon-party-rock"></use>
      </svg>
      {{ 'DEMO.JOINUS' | translate }}
      <svg viewBox="0 0 32 32" class="icon">
        <use xlink:href="#icon-arrow-right-long"></use>
      </svg>
    </button>
  </div>
  <div class="jumbotron-container flex row m-column ai-center m-j-space-between">
    <div class="jumbotron-title flex column j-start" [class.marginBanner]="userStore.userActiveDemoCode()" innerHTML="{{ 'JUMBO.TITLE' | translate: { Client: storage.clientFirstName } }}"></div>
    <div class="jumbotron-info flex j-end ai-end ac-end wrap">
      <button class="btn btn-primary encoding-button flex center-all" [routerLink]="['/' + contextStore.locale() + '/club/point-encoding']">
        <div class="label">
          {{ 'GLOBAL.POINTENCODING' | translate }}
        </div>
        <div class="icon white">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-diamond"></use>
          </svg>
        </div>
      </button>
      <div class="recap-infos-container">
        <div class="flex j-space-between ai-center">
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + contextStore.locale() + '/club/status']">
            <div class="flex" *ngIf="userStore.clubInformation()">
              <svg viewBox="0 0 108 80" class="icon-status height100">
                <use [attr.xlink:href]="'#icon-status-' + getStatusIdFromClubStatus(userStore.clubInformation().clubStatus) "></use>
              </svg>
            </div>
            <div class="flex" *ngIf="!userStore.clubInformation()">
              <svg viewBox="0 0 108 80" class="icon-status height100">
                <use xlink:href="#icon-status-1"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{ 'GLOBAL.YOURSTATUS' | translate }}</p>
              <p class="recap-value {{ userStore.clubInformation().clubPoints >= 1000 ? 'decreaseSize' : '' }}" *ngIf="userStore.clubInformation()">
                {{ 'CLIENT.STATUS' + getStatusIdFromClubStatus(userStore.clubInformation().clubStatus) | translate | uppercase }}
              </p>
              <p class="recap-value" *ngIf="!userStore.clubInformation()">-&#45;&#45;</p>
            </div>
          </div>
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + contextStore.locale() + '/club/point-encoding']">
            <div class="flex">
              <svg viewBox="0 0 32 32" class="icon-diamond height100">
                <use xlink:href="#icon-diamond"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{ 'GLOBAL.POINTS' | translate }}</p>
              <p class="recap-value {{ userStore.clubInformation().clubPoints >= 1000 ? 'decreaseSize' : '' }}" *ngIf="userStore.clubInformation()">{{ userStore.clubInformation().clubPoints }} pts</p>
              <p class="recap-value" *ngIf="!userStore.clubInformation()">-&#45;&#45; pts</p>
            </div>
          </div>
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + contextStore.locale() + '/club/wallet']">
            <div class="flex">
              <svg viewBox="0 0 32 32" class="icon-wallet height100">
                <use xlink:href="#icon-wallet"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{ 'GLOBAL.SOLDE' | translate }}</p>
              <p class="recap-value {{ userStore.clubInformation().clubPoints >= 1000 ? 'decreaseSize' : '' }}" *ngIf="userStore.clubInformation()">
                {{ userStore.clubInformation().walletAmount | agoraCurrency }}
              </p>
              <p class="recap-value" *ngIf="!userStore.clubInformation()">-&#45;&#45; €</p>
            </div>
          </div>
        </div>
      </div>
      <!-- END SUMMARY INFO -->
    </div>
  </div>
</div>
