import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() position: string;
  @Input() icon: string;
  @Input() iconViewbox: string;
  @Input() width: string;
  @Input() height: string;
  @Input() bottom: string;
  @Input() right: string;
  @Input() top: string;
  @Input() left: string;

  constructor() {}

  ngOnInit() {}
}
