import { AlertPositions } from '../enums/alert-type.enum';
import { NotificationIcon, NotificationStyle } from '../types/notification.options.type';

export class Alert {
  public id: string = 'display-alert-toast';
  public message: string = '';
  public title: string = '';
  public urlLink: string = undefined;
  public icon: string = NotificationIcon.INFO;
  public type: NotificationStyle | string = NotificationStyle.INFO;
  public position: AlertPositions | string = AlertPositions.BOTTOM;
  public duration: number;
}
