import { Component, effect, EventEmitter, inject, Input, Output } from '@angular/core';
import { MD5 } from 'object-hash';
import { ShoppingBag } from '../../../../state/models/resources';
import { ContextStore } from '../../../../stores/context.store';
import { WishlistStore } from '../../../../stores/wishlist.store';
import { CartStore } from '../../../../stores/cart.store';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, of } from 'rxjs';
import { CatalogService } from '../../../../core/services/V2/catalog.service';
import { CartItemResponse, ProductSearchModel } from '@victoria-company/agora-client';

@Component({
  selector: 'app-cart-articles',
  templateUrl: './cart-articles.component.html',
  styleUrls: ['./cart-articles.component.scss'],
})
export class CartArticlesComponent {
  @Input() resources: ShoppingBag;
  @Output() requestRedirectUserToCatalog = new EventEmitter();
  @Output() setCartHasMultiVariants = new EventEmitter<boolean>();

  public catalogService = inject(CatalogService);
  public contextStore = inject(ContextStore);
  public wishlistStore = inject(WishlistStore);
  public cartStore = inject(CartStore);

  public productsInCart = derivedAsync(
    () =>
      this.cartStore.cart()?.cartItems?.length
        ? this.catalogService
            .getProductsByVariantIds(
              this.contextStore.contextId(),
              this.contextStore.locale(),
              this.cartStore.cart()?.cartItems?.map(ci => ci.productVariantId)
            )
            .pipe(map(productResponse => productResponse.products))
        : of<ProductSearchModel[]>([]),
    { initialValue: [] }
  );

  public productsInWishlist = derivedAsync(
    () =>
      this.wishlistStore.items().length
        ? this.catalogService
            .getProducts(
              this.contextStore.contextId(),
              this.contextStore.locale(),
              this.wishlistStore.items().map(i => i.id)
            )
            .pipe(map(productResponse => productResponse.products))
        : of<ProductSearchModel[]>([]),
    { initialValue: [] }
  );

  //Zoom Modal
  zoomModalOpened = false;
  zoomModalImage = '';

  constructor() {
    effect(() => {
      if (this.productsInCart()) {
        this.setCartHasMultiVariants.emit(this.productsInCart().some(p => p.variants.length > 1));
      }
    });
  }

  checkIsInWishlist(item: CartItemResponse) {
    return this.wishlistStore.items().some(x => x.variants.some(v => v.id == item.productVariantId));
  }
  getProductInCartByVariantId(productVariantId: string): ProductSearchModel {
    return this.productsInCart()?.find(p => p.variants.some(v => v.id == productVariantId));
  }

  async updateCartItemSize(item: CartItemResponse, newVariantId: string) {
    await this.cartStore.updateItemSize(item.id, newVariantId, this.cartStore.cart()?.id);
  }

  async updateCartItemLinks(value: number, item: CartItemResponse) {
    await this.cartStore.updateCartItemLinks(value, item.id, this.cartStore.cart()?.id);
  }

  // async updateCartItemQuantity(item: CartItemResponse, quantity: number) {
  //   await this.cartStore.updateItemQuantity(item.productVariantId, quantity, this.cartStore.cart()?.id);
  // }

  async addRemoveFromWishlist(item: CartItemResponse) {
    const product = this.getProductInCartByVariantId(item.productVariantId);
    if (!product) return;

    if (
      this.wishlistStore
        .items()
        .map(i => i.id)
        .includes(product.id)
    )
      await this.wishlistStore.deleteItemFromWishlist(product.id);
    else await this.wishlistStore.addItemToWishlist(product.id);
  }

  addToCart(product: ProductSearchModel) {
    if (!product) return;
    this.cartStore.openAddToCartVariantSelectionModal(product);
  }

  cancelDeleteFromCart() {
    this.cartStore.cancelDeleteFromCartConfirmationModal();
  }

  async confirmDeleteFromCart() {
    await this.cartStore.deleteItemFromCart(this.cartStore.cart()?.id, this.cartStore.confirmDeleteItemFromCartModal().cartItemId);
  }

  removeFromCart(item: CartItemResponse) {
    this.cartStore.openDeleteFromCartConfirmationModal(this.cartStore.cart()?.id, item.productVariantId, item.id);
  }

  displayArticle() {
    document.getElementById('cart-articles-main').style.display = 'block';
    document.getElementById('expand-cart').style.display = 'none';
  }

  trackByItems(_: number, item: CartItemResponse): string {
    return MD5(item);
  }

  public hasBonusRemaining() {
    return this.cartStore.cart().hostessBonus - this.cartStore.cart().subTotal >= 0;
  }

  public requestContinueShopping() {
    this.requestRedirectUserToCatalog.emit();
  }

  openZoomModal(imageUrl: string) {
    this.zoomModalImage = imageUrl;
    this.zoomModalOpened = true;
  }

  closeZoomModal() {
    this.zoomModalOpened = false;
  }
}
