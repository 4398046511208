import { DeliveryType, DeliveryTypeObject, DemoStatus, DemoStatusObject } from '@victoria-company/agora-client';

export const toDemoStatusInt = (status: DemoStatus) => {
  switch (status) {
    case DemoStatusObject.PreSale:
      return 1;
    case DemoStatusObject.Opened:
      return 2;
    case DemoStatusObject.Closed:
      return 4;
    default:
      return 0;
  }
};

export const toDeliveryId = (type: DeliveryType) => {
  switch (type) {
    case DeliveryTypeObject.ToDelegate:
      return 1;
    case DeliveryTypeObject.ToCustomer:
      return 10;
  }
};
