import { Component, inject, Input } from '@angular/core';
// import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';
import { CartResponse, DemoResponse, PaymentType, PaymentTypeObject } from '@victoria-company/agora-client';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { getVictoriaUrl } from '../../../../../../core/utils/filter.utils';
import { UserStore } from '../../../../../../stores/user.store';
import { ContextStore } from '../../../../../../stores/context.store';
import { ResourcesStore } from '../../../../../../stores/resources.store';

@Component({
  selector: 'app-order-payment-status',
  templateUrl: './order-payment-status.component.html',
  styleUrls: ['./order-payment-status.component.scss'],
})
export class OrderPaymentStatusComponent {
  public resourcesStore = inject(ResourcesStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);

  @Input() demo: DemoResponse;
  @Input() cart: CartResponse;
  @Input() paymentType: PaymentType;
  @Input() email = null;

  constructor(
    // private gA: GoogleAnalyticService,
    public translate: TranslationService
  ) {}

  public getLogoUrl() {
    return `${getVictoriaUrl(this.contextStore.locale(), true)}/${this.contextStore.locale()}`;
  }

  // private openBecomeDelegateHostessModal() {
  //   window.setTimeout(() => this.modalService.openAgoraModal('become-delegate-modal'), 3000);
  // }
  protected readonly PaymentTypeObject = PaymentTypeObject;
}
