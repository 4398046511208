export enum AlertTypes {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export enum AlertPositions {
  TOP,
  BOTTOM,
}
