import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddAdvantageStatus } from '../../../../../../state/models/addAvantageStatus';
import { CartResponse, GetUserAdvantagesResponse_VoucherResponse, PaymentType } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-advantages',
  templateUrl: './order-advantages.component.html',
  styleUrls: ['./order-advantages.component.scss'],
})
export class OrderAdvantagesComponent {
  @Input() cart: CartResponse;
  @Input() vouchers: GetUserAdvantagesResponse_VoucherResponse[] = [];
  @Input() vouchersInCart: GetUserAdvantagesResponse_VoucherResponse[] = [];
  @Input() walletAvailableAmount: number;
  @Input() voucherEncodingStatus: AddAdvantageStatus;
  @Input() voucherSelectionStatus: AddAdvantageStatus;
  @Input() walletConsumptionStatus: AddAdvantageStatus;
  //
  @Output() requestSaveInternalPaymentType = new EventEmitter<PaymentType>();
  @Output() requestValidateEncodedVoucherCode = new EventEmitter<string>();
  @Output() requestUseVoucher = new EventEmitter<string>();
  @Output() requestUpdateWalletAmount = new EventEmitter();
  @Output() requestResetStatuses = new EventEmitter();
  //
  constructor() {}
}
