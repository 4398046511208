<div class="agora-modal-container pop-up select-payment-modal hostessGiftModal" [id]="id" [style.display]="opened ? 'flex' : 'none'" (click)="closeModalFromOutside($event)">
  <div class="modal">
    <div class="close">
      <svg viewBox="0 0 32 32" class="pointer" (click)="close()">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div>
      <app-hostess-select-gifts [isDelegate]="isDelegate" [selectedCategory]="clickedCategory" [hostessGifts]="hostessGifts" (requestSaveSelectedGift)="saveGift($event)"></app-hostess-select-gifts>
    </div>
    <div class="scroll-icon">
      <svg>
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </div>
  </div>
</div>
