import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartResponse, PaymentType } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-demo-actions',
  templateUrl: './order-demo-actions.component.html',
  styleUrls: ['./order-demo-actions.component.scss'],
})
export class OrderDemoActionsComponent {
  @Input() cart: CartResponse;
  @Input() internalPaymentType: PaymentType;
  @Input() operationPending = false;
  @Input() disableCTA = false;

  @Output() requestGoToTheTop = new EventEmitter();
  @Output() requestValidateCurrentStep = new EventEmitter();
  constructor() {}
}
