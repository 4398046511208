import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-booster-access',
  templateUrl: './booster-access.component.html',
  styleUrls: ['./booster-access.component.scss'],
})
export class BoosterAccessComponent implements OnInit {
  locale: string;

  constructor(
    public screen: ScreenService,
    private cookies: CookieService
  ) {}

  ngOnInit() {
    this.locale = this.cookies.get('v_locale')?.replace('_', '-');
  }
}
