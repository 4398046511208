<div class="panier-container" *ngIf="resourcesStore.isLoaded() && items.length && productsInCart().length">
  <h1>{{ resourcesStore.i18n().resources.shoppingBag.mainTitle }}</h1>
  <div>
    <ng-container *ngFor="let item of items; index as i" [ngClass]="">
      <app-order-item (requestZoomInImage)="openZoomModal($event)" [item]="item" [products]="productsInCart()" [border]="items.length !== i + 1"></app-order-item>
    </ng-container>
  </div>

  <app-zoom-in-picture-modal [opened]="zoomModalOpened" [imageUrl]="zoomModalImage" (requestCloseModal)="closeZoomModal()" />
</div>
