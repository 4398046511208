<div class="communications-container">
  <div class="header flex center-all column">
    <img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria" />
    <h1>{{ 'COMMUNICATION.TITLE' | translate }}</h1>
    <div class="dark"></div>
  </div>
  <div class="body-title flex ai-center column">
    <p class="subtitle" [innerHTML]="'COMMUNICATION.CAPTION' | translate"></p>
    <div class="heading flex column ai-center">
      <h2>{{ 'COMMUNICATION.EMAIL' | translate }}</h2>
      <h3>{{ clientEmail }}</h3>
    </div>
  </div>
  <div class="body-communications container flex column ai-start" *ngIf="(error.profileError$ | async) === false">
    <div class="communications-main center">
      <p [innerHTML]="'COMMUNICATION.OPTIN.CAPTION' | translate: { Y: this.clientEmail }"></p>
    </div>
  </div>
  <div class="body-communications container flex column ai-center error-box" *ngIf="error.profileError$ | async">
    <p class="subtitle">{{ 'COMMUNICATION.UNAUTHORIZED' | translate }}</p>
  </div>
</div>
