<div
  [id]="id"
  class="alert-box flex row j-space-between ai-center"
  [class.danger]="type == AlertTypes.DANGER"
  [class.success]="type == AlertTypes.SUCCESS"
  [class.warning]="type == AlertTypes.WARNING"
  [class.info]="type == AlertTypes.INFO"
  [class.top]="position == AlertPositions.TOP"
  [class.bottom]="position == AlertPositions.BOTTOM">
  <div id="alert-container" class="flex row ai-center gap-15">
    <svg>
      <use [attr.xlink:href]="icon"></use>
    </svg>
    <p>{{ message }}</p>
    <svg viewBox="0 0 32 32" class="hide-button" (click)="alertService.close(id)">
      <use href="#icon-close"></use>
    </svg>
  </div>
  <a *ngIf="urlLink" [routerLink]="[urlLink]">{{ 'GLOBAL.ACTION.VIEW' | translate | uppercase }}</a>
</div>
