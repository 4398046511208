import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[alternativeImage]',
})
export class ErrorImageDirective {
  @Input() alternativeImage: string = '/assets/images/empty-delegate.png';

  @HostListener('error', ['$event'])
  onError(event: Event) {
    const imgElement = event?.target as HTMLImageElement;
    imgElement.src = this.alternativeImage;
  }
}
