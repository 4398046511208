import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { ProductSearchModel } from '@victoria-company/agora-client';
import { doesProductHaveAvailabilities } from '../../../core/utils/variants.utils';
import { CartStore } from '../../../stores/cart.store';

@Component({
  selector: 'app-wishlist-item',
  templateUrl: './wishlist-item.component.html',
  styleUrls: ['./wishlist-item.component.scss'],
})
export class WishlistItemComponent implements OnInit {
  readonly cartStore = inject(CartStore);
  @Input() wishlistItem: ProductSearchModel;

  @Output() addToCartEvent = new EventEmitter<string>();
  @Output() deleteFromWishlistEvent = new EventEmitter<string>();
  @Output() productClickedEvent = new EventEmitter<string>();

  itemImage: string;
  itemHoverImage: string;
  isMouseOver = false;

  constructor(public getDataService: GetDatasService) {}

  ngOnInit() {
    this.itemImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.imageThumbnail);
    this.itemHoverImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.imageHover);
  }

  showElem(e: any) {
    //e.target.classList?.add("show");
  }

  hoverImage(e: any, isHover: boolean) {
    if (this.itemHoverImage && !this.itemHoverImage.endsWith('empty.jpg')) this.isMouseOver = isHover;
  }

  productClicked() {
    this.productClickedEvent.emit(this.wishlistItem.id);
  }

  addToCart() {
    this.addToCartEvent.emit(this.wishlistItem.id);
  }

  deleteFromWishlist() {
    this.deleteFromWishlistEvent.emit(this.wishlistItem.id);
  }

  productIsAvailable() {
    return doesProductHaveAvailabilities(this.wishlistItem, this.cartStore.cart() ? [this.cartStore.cart()] : [], this.cartStore.demo()?.demo, false);
  }
}
