import { Component, inject, Input } from '@angular/core';
import { getProfilePictureOrDefault } from '../../../core/utils/filter.utils';
import { DelegateResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../stores/resources.store';

@Component({
  selector: 'app-delegate-infos-card',
  templateUrl: './delegate-infos-card.component.html',
  styleUrls: ['./delegate-infos-card.component.scss'],
})
export class DelegateInfosCardComponent {
  public resourcesStore = inject(ResourcesStore);

  @Input() delegate: DelegateResponse;

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
