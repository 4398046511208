import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DocumentCategoryObject, DocumentResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../../stores/context.store';
import { ScreenService } from '../../../../../../core/services/screen.service';

@Component({
  selector: 'app-manage-document-card',
  templateUrl: './manage-document-card.component.html',
  styleUrls: ['./manage-document-card.component.scss'],
})
export class ManageDocumentCardComponent {
  readonly contextStore = inject(ContextStore)
  readonly screen = inject(ScreenService)
  @Input() document:DocumentResponse
  @Output() requestDeleteDocument = new EventEmitter()
  @Output() requestOpenActionSheet = new EventEmitter()


  getLocaleDisplayFromLocale(){
    switch(this.document.locale){
      case "fr_be": return "FR"
      case "nl_be": return "NL"
      case "fr_fr": return "FR"
      case "de_de": return "DE"
    }
  }

  delete(){
    this.requestDeleteDocument.emit()
  }

  openActionSheet() {
    this.requestOpenActionSheet.emit()
  }

  protected readonly DocumentCategoryObject = DocumentCategoryObject;
}
