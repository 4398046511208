import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-actions-sheet',
  templateUrl: './actions-sheet.component.html',
  styleUrls: ['./actions-sheet.component.scss'],
})
export class ActionsSheetComponent implements OnInit {
  @Input() id: string;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();

  //INTERNAL
  slideOut = false;

  constructor(private modal: ModalService) {}

  ngOnInit() {
    //console.log("Actions Sheet Init");
    this.open();
  }

  open() {
    if (this.id) this.modal.openActionsSheet(this.id);
    else this.modal.openActionsSheet();
  }

  close(value = '') {
    this.slideOut = true;

    window.setTimeout(() => {
      if (this.id) this.modal.closeActionsSheet(this.id);
      else this.modal.closeActionsSheet();

      this.closeAction.emit(value);
    }, 300);
  }
}
