import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translateWihParam' })
export class TranslateWithParam implements PipeTransform {
  transform(value: string, param: any): string {
    let str = value;
    for (const [key, el] of Object.entries(param)) {
      str = str.replace(`{${key}}`, el as string);
    }
    return str;
  }
}
