import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, ReplaySubject } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _accessToken$ = new ReplaySubject<string>(1);
  public accessToken$ = this._accessToken$.asObservable();

  public isAuthenticated$: Observable<boolean>;

  constructor(private oidc: OidcSecurityService) {
    this.isAuthenticated$ = this.oidc.isAuthenticated$.pipe(map(authResult => authResult.isAuthenticated));
  }

  public getTokenFromOIDC() {
    return this.oidc.getAccessToken();
  }
}
