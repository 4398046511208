<div class="order-item flex" [ngClass]="border ? 'border' : ''" *ngIf="resourcesStore.isLoaded() && item && products && product">
  <div class="item-image flex">
    <figure class="magnify" (click)="zoomInImage()">
      <img loading="lazy" [src]="getDataService.getProductImagesPathForThumbs(product.imageThumbnail)" />
    </figure>
  </div>
  <div class="item-info flex column flex-3">
    <div class="item-name">{{ product.name }}</div>
    <div class="item-price">{{ item.price | agoraCurrency }}</div>
    <div class="item-specs flex j-start ai-center">
      <div *ngIf="product.variants.length > 1">{{ resourcesStore.i18n().resources.profileOrders.sizesLabels }}: {{ getItemSize() }}</div>
<!--      <div>{{ resourcesStore.i18n().resources.profileOrders.quantityLabel }}: {{ item.quantity }}</div>-->
    </div>
  </div>
</div>
