import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-customisable-error',
  templateUrl: './customisable-error.component.html',
  styleUrls: ['./customisable-error.component.scss'],
})
export class CustomisableErrorComponent {
  private router: Router = inject(Router);
  private contextStore = inject(ContextStore);

  @Input() errorTitle: string;
  @Input() errorMessage: string;
  @Input() showReloadBtn = true;
  @Input() showGoHomeBtn = true;

  reload() {
    window.location.reload();
  }

  goHome() {
    this.router.navigate([`/${this.contextStore.locale()}`]);
  }
}
