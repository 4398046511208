import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Alert } from '../models/alert.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ScreenService } from './screen.service';
import { NotificationStyle } from '../types/notification.options.type';

const DEFAULT_TIMEOUT = 4000;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _triggerAlert$: BehaviorSubject<Alert> = new BehaviorSubject<Alert>(undefined);
  public triggerAlert$: Observable<Alert> = this._triggerAlert$.asObservable();

  private pendingAlert = 0;

  constructor(
    private translation: TranslateService,
    private toastrService: ToastrService,
    private screenService: ScreenService
  ) {}

  public raiseAlert(obj: Alert) {
    switch (obj.type) {
      case NotificationStyle.DANGER:
        this.toastrService.error(obj.message, obj.title, {
          closeButton: true,
          enableHtml: true,
          positionClass: this.screenService.isMobile ? 'toast-bottom-full-width' : 'toast-bottom-right',
        });
        break;
      case NotificationStyle.WARNING:
        this.toastrService.warning(obj.message, obj.title, {
          closeButton: true,
          enableHtml: true,
          positionClass: this.screenService.isMobile ? 'toast-bottom-full-width' : 'toast-bottom-right',
        });
        break;
      case NotificationStyle.SUCCESS:
        this.toastrService.success(obj.message, obj.title, {
          closeButton: true,
          enableHtml: true,
          positionClass: this.screenService.isMobile ? 'toast-bottom-full-width' : 'toast-bottom-right',
        });
        break;
      default:
        this.toastrService.info(obj.message, obj.title, {
          closeButton: true,
          enableHtml: true,
          positionClass: this.screenService.isMobile ? 'toast-bottom-full-width' : 'toast-bottom-right',
        });
    }
  }

  public display(id, duration: number = DEFAULT_TIMEOUT) {
    this.pendingAlert++;

    const alert = document.getElementById(id);
    alert.classList.add('visible');

    setTimeout(_ => this.hide(id), duration);
  }

  public close(id) {
    const alert = document.getElementById(id);
    alert.classList.remove('visible');
  }

  private hide(id) {
    this.pendingAlert--;

    if (this.pendingAlert === 0) {
      const alert = document.getElementById(id);
      alert.classList.remove('visible');
    }
  }

  public createMessage(key, parameters = undefined) {
    const objectParameters = {};

    if (parameters?.X) objectParameters['X'] = parameters.X;
    if (parameters?.Y) objectParameters['Y'] = parameters.Y;

    return this.translation.instant(key, objectParameters);
  }
}
