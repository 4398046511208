<div class="modal-container" [id]="'#searchDelegate'" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal search-delegate" *ngIf="resourcesStore.isLoaded()">
    <div class="modal-icon icon peach" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1>{{ resourcesStore.i18n().resources.popupFindConsultant.mainTitle }}</h1>
    <div class="modal-body search-delegate">
      <div class="flex j-space-between selection">
        <div class="tab flex center-all flex-1 selection-title" [class.selected]="selectedTab == 'localisation'" (click)="switchTab('localisation')">
          {{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCode }}
        </div>
        <div class="tab flex center-all flex-1 selection-title" [class.selected]="selectedTab == 'name'" (click)="switchTab('name')">
          {{ resourcesStore.i18n().resources.popupFindConsultant.tabName }}
        </div>
      </div>
      <div class="search-form" *ngIf="data">
        <!-- Localisation Search Tab  -->
        <div class="flex column gap-8" *ngIf="selectedTab == 'localisation'">
          <p class="text_large">{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodeDescription }}</p>
          <div class="form-block">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.streetLabel }}</label>
            <input type="text" [(ngModel)]="dataModel.address" />
          </div>
          <div class="flex gap-20 postal" [class.error]="hasFormError && !dataModel.zip">
            <div class="form-block">
              <label>{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodePlaceholder }}</label>
              <input type="text" [(ngModel)]="dataModel.zip" />
              <div class="form-error-container" *ngIf="hasFormError && !dataModel.zip">
                {{ resourcesStore.i18n().resources.popupFindConsultant.postalCodeLabelError }}
              </div>
            </div>
            <div class="form-block" [class.error]="hasFormError && !dataModel.city">
              <label>{{ resourcesStore.i18n().resources.popupFindConsultant.cityLabel }}</label>
              <input type="text" [(ngModel)]="dataModel.city" />
              <div class="form-error-container" *ngIf="hasFormError && !dataModel.city">
                {{ resourcesStore.i18n().resources.popupFindConsultant.cityLabelError }}
              </div>
            </div>
          </div>
          <div class="form-block" [class.error]="hasFormError && !dataModel.country">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.countryLabel }}</label>
            <select [(ngModel)]="dataModel.country">
              <option *ngFor="let opt of this.formService.getCountries()" [value]="opt.id">{{ opt.value }}</option>
            </select>
            <div class="form-error-container" *ngIf="hasFormError && !dataModel.country">
              {{ resourcesStore.i18n().resources.popupFindConsultant.countryLabelError }}
            </div>
          </div>
          <div *ngIf="hasError">
            <p class="error-box">{{ resourcesStore.i18n().resources.popupFindConsultant.labelInvalidGeolocation }}</p>
          </div>
          <div class="flex j-center margin-top-15">
            <button class="btn btn-primary j-center ai-center ac-center" (click)="validateFormAndCheck()">
              {{ resourcesStore.i18n().resources.popupFindConsultant.buttonLabel }}
            </button>
          </div>
        </div>
        <!-- Name search Tab -->
        <div class="flex column gap-8" *ngIf="selectedTab == 'name'">
          <p class="text_large">{{ resourcesStore.i18n().resources.popupFindConsultant.tabNameDescription }}</p>
          <div class="form-block flex rox">
            <input type="text" [(ngModel)]="dataModel.name" name="delegateName" placeholder="{{ resourcesStore.i18n().resources.popupFindConsultant.tabNamePlaceholder }}" />
            <button class="btn btn-primary btn-icon-only j-center no-padding-btn" (click)="validateDelegateAndCheck()">
              <svg viewBox="0 0 32 32" class="width20">
                <use xlink:href="#icon-search"></use>
              </svg>
            </button>
          </div>
          <p class="error-box" *ngIf="hasError">{{ resourcesStore.i18n().resources.popupFindConsultant.lastNameLabelError }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
