<app-optin-dip-box [cart]="cart" (requestSignDip)="requestSignDip.emit()"></app-optin-dip-box>

<div class="payment-mode-selection-box" *ngIf="cart.totalToPay > 0" [class.no-margin-top]="!cart.preContractualInformationDocument.required">
  <div *ngIf="paymentError && resourcesStore.isLoaded()" class="order-payment-error">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span *ngIf="paymentError == PaymentResponseStatusObject.Canceled">{{ resourcesStore.i18n().resources.checkoutPayment.messageLabelError }}</span>
        <span *ngIf="paymentError == PaymentResponseStatusObject.Failed">{{ resourcesStore.i18n().resources.checkoutPayment.topicLabelError }}</span>
        <span *ngIf="paymentError == PaymentResponseStatusObject.Expired">{{ resourcesStore.i18n().resources.resetpassword.shortDescription }}</span>
        <span *ngIf="paymentError == PaymentResponseStatusObject.Unknown">{{ 'ALERT.ERROR.GLOBAL' | translate }}</span>
      </div>
    </div>
  </div>
  <app-payment-mode-selection
    [delegate]="delegate"
    [cart]="cart"
    [internalPaymentType]="internalPaymentType"
    [paymentOptions]="paymentOptions"
    (requestSaveInternalPaymentType)="requestSaveInternalPaymentType.emit($event)"></app-payment-mode-selection>
</div>

<app-order-demo-delegate *ngIf="screen.isDesktop || (screen.isTablet && screen.isLandscape)" [delegate]="delegate"></app-order-demo-delegate>
