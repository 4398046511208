import { Component, inject, Input } from '@angular/core';
import { GetClubInformationResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../stores/context.store';
import { getStatusIdFromClubStatus } from '../../../../core/utils/common.utils';

@Component({
  selector: 'app-new-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
})
export class NewStatusCardComponent  {
  @Input() clubInformation:GetClubInformationResponse
  @Input() forecasts: any[];
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  @Input() showStatusLink: boolean;
  @Input() paddingBottom = true;
  @Input() hiddeFooterAction = false;
  @Input() dropPadding = false;

  readonly contextStore = inject(ContextStore)

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
