import { Resources, RseBannerTextsEntity } from '../state/models/resources';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../core/services/translation.service';
import { computed, inject } from '@angular/core';
import { ContextStore } from './context.store';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { filterURL, getVictoriaUrl } from '../core/utils/filter.utils';

export interface ResourceState {
  i18n: Resources;
  collectionUrl: string;
  isLoaded: boolean;
}

export const initialState: ResourceState = { i18n: undefined, collectionUrl: undefined, isLoaded: false };

export const ResourcesStore = signalStore(
  { providedIn: 'root' },
  withDevtools('resources'),
  withState(initialState),
  withComputed(({ i18n }) => ({
    rseBanners: computed(() => {
      const rseBannerTexts = i18n()?.rseBannerTexts?.rseBannerTexts ?? [];
      return [...rseBannerTexts].sort(sortBanners).map(x => ({
        displayIcon: x.displayIcon,
        clickLinks: x.clickLinks,
        displayText: x.displayText,
      }));
    }),
  })),
  withMethods((store, contextStore = inject(ContextStore), http = inject(HttpClient), translate = inject(TranslateService), translation = inject(TranslationService)) => ({
    async loadResources(locale: string): Promise<void> {
      if (locale && locale == contextStore.locale() && store.i18n()) return;

      let jsonUrl = '';

      switch (contextStore.locale()) {
        case 'nl-nl':
        case 'fr-be':
        case 'nl-be':
          jsonUrl = `${environment.base_url_benelux}/data/data_${contextStore.locale().replace('-', '_')}.json`;
          break;
        case 'de-de':
          jsonUrl = `${environment.base_url_allemagne}/data/data_${contextStore.locale().replace('-', '_')}.json`;
          break;
        case 'fr-fr':
          jsonUrl = `${environment.base_url_france}/data/data_${contextStore.locale().replace('-', '_')}.json`;
          break;
        default:
          jsonUrl = `${environment.base_url_france}/data_fr_fr.json`;
          break;
      }

      const resources = await firstValueFrom(http.get<Resources>(jsonUrl));
      translate.use(contextStore.locale().replace('-', '_'));

      patchState(store, () => ({
        i18n: resources,
        collectionUrl: resources.header.mainNav[0].navLink,
        isLoaded: true,
      }));
    },
    getEshopCollectionLink() {
      const navLink = store.i18n().header.mainNav[0].navLink;
      const url = filterURL(navLink);
      return getVictoriaUrl(contextStore.locale()) + url;
    },
  }))
);

const sortBanners = (x: RseBannerTextsEntity, y: RseBannerTextsEntity) => {
  if (x.sortOrder > y.sortOrder) return 1;
  else if (x.sortOrder < y.sortOrder) return -1;
  else return 0;
};
