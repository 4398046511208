<div class="status-card">
  <div class="header">
    <div class="card-title flex column ac-center ai-center">
      <span class="status-text">{{ 'GLOBAL.YOURSTATUS' | translate }}</span>
      <span class="status-value uppercase">{{ 'CLIENT.STATUS' + getStatusIdFromClubStatus(clubInformation.clubStatus) | translate | uppercase }}</span>
    </div>
  </div>
  <div class="body">
    <div class="image">
      <div class="card-img-container flex center-all">
        <img loading="lazy" src="assets/images/business/statut-{{ getStatusIdFromClubStatus(clubInformation.clubStatus) }}.svg" />
      </div>
    </div>
    <div class="content">
      <div class="full-height">
        <div class="datas {{ paddingBottom ? 'paddingBottom' : '' }} {{ dropPadding ? 'dropPadding-bottom' : '' }}">
          <div class="card-info flex column ac-center ai-center">
            <div class="info-main">
              <div class="info-main flex column ac-center ai-center total-points pad-top">
                {{ 'GLOBAL.XPOINTS' | translate: { Points: clubInformation.clubPoints } }}
              </div>
            </div>
          </div>
        </div>
        <div class="actions" *ngIf="showStatusLink">
          <div class="card-info flex column ac-center ai-center">
            <div class="card-action">
              <app-wavedlink [linkUrl]="'/' + contextStore.locale() + '/club/status'" linkText="{{ 'GLOBAL.ADVANTAGES' | translate }}"></app-wavedlink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
