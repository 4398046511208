import { Component, ElementRef, EventEmitter, Input, Output, AfterViewInit, inject } from '@angular/core';
import { ScreenService } from 'src/app/core/services/screen.service';
import { CartResponse } from '@victoria-company/agora-client';
import { environment } from '../../../../../environments/environment';
import { ContextStore } from '../../../../stores/context.store';


@Component({
  selector: 'app-optin-dip-box',
  templateUrl: './optin-dip-box.component.html',
  styleUrls: ['./optin-dip-box.component.scss'],
})
export class OptinDipBoxComponent implements AfterViewInit {

  contextStore = inject(ContextStore)

  @Input() cart: CartResponse;
  @Output() requestSignDip = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private screen: ScreenService
  ) {
  }

  ngAfterViewInit() {
    this.constructDipLink()
  }

  public signDIP() {
    if (this.cart.preContractualInformationDocument?.signed) return;
    else this.requestSignDip.emit();
  }

  private constructDipLink() {
    // const element = this.elementRef.nativeElement.querySelector('a');
    const jsElement = this.elementRef.nativeElement.querySelector('a');

    if (this.screen.isMobile) jsElement?.setAttribute('download', 'victoria-dip-' + this.cart.id + '.pdf');
    if (this.screen.isDesktop) jsElement?.setAttribute('target', '_blank');

    const url = `${environment.api_url}/api/carts/${this.cart.id}/pre-contractual-information-document/download?Locale=${this.contextStore.locale()}`
    jsElement?.setAttribute('href', url);
  }
}
