import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { OrderItemResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { GetDatasService } from '../../../../../../core/services/get-datas.service';

@Component({
  selector: 'app-view-order-item',
  templateUrl: './view-order-item.component.html',
  styleUrls: ['./view-order-item.component.scss'],
})
export class ViewOrderItemComponent implements OnInit {

  @Input() item : OrderItemResponse
  @Input() border = false;
  @Output() requestZoomInImage = new EventEmitter<string>();

  readonly resourcesStore = inject(ResourcesStore)
  readonly getDataService = inject(GetDatasService)

  zoomInImage(){
    this.requestZoomInImage.emit(this.getDataService.getProductImagesPathForHigh(this.item.sku + '.jpg'))
  }

  constructor() { }

  ngOnInit() {}

  protected readonly Math = Math;
}
