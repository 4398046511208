import { CustomValidators } from 'src/app/core/utils/customValidators';
import { FormGroupType } from '../../../../shared/components/form/types/form-group.type';
import { Validators } from '@angular/forms';

export const ADD_CLIENT_FORM: FormGroupType = {
  gender: [1],
  firstName: ['', [Validators.required, Validators.maxLength(50)]],
  lastName: ['', [Validators.required, Validators.maxLength(50)]],
  address1: ['', [Validators.required, Validators.maxLength(100)]],
  address2: ['', Validators.maxLength(100)],
  zipCode: [null, [Validators.required, Validators.maxLength(50)]],
  city: ['', [Validators.required, Validators.maxLength(50)]],
  countryCode: [null, [Validators.required, Validators.maxLength(20)]],
  mobile: ['', [Validators.required, Validators.pattern, Validators.maxLength(50)]],
  phone: ['', [Validators.pattern, Validators.maxLength(50)]],
  email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/), Validators.maxLength(300)]],
  birthdate: [null, [CustomValidators.dateMinimum(new Date('1920-01-01').toDateString()), CustomValidators.dateMaximum(new Date().toDateString())]],
};
