import { Component, inject, OnInit } from '@angular/core';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CartResponse, PaymentResponse, PaymentResponseStatusObject, PaymentTypeObject } from '@victoria-company/agora-client';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../../../../core/services/V2/cart.service';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { PaymentsService } from '../../../../core/services/V2/payments.service';
const MAX_OPEN_REQUEST = 10;
@Component({
  selector: 'app-public-order-payment-confirmation',
  templateUrl: './public-order-payment-confirmation.component.html',
  styleUrls: ['./public-order-payment-confirmation.component.scss'],
})
export class PublicOrderPaymentConfirmationComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);
  readonly paymentsService = inject(PaymentsService);

  private openRequest = 0;

  constructor(
    public screen: ScreenService,
    private router: Router,
    private activated: ActivatedRoute,
    private cartService: CartService
  ) {}

  public cart: CartResponse;
  public payment: PaymentResponse;

  async ngOnInit() {
    const lyfPayStatus = this.activated.snapshot.queryParams.status ?? '';
    const params = this.activated.snapshot.params;

    if (params.cartId) {
      const cart = await this.cartService.getCartByCartId(params.cartId);
      this.cart = cart;

      if (cart) {
        this.cart = cart;

        const sortedPayments =
          this.cart.cartPayments
            ?.filter(p => p.type == 'Digital')
            .sort((a, b) => {
              if (a.creationDate > b.creationDate) return -1;
              else return 0;
            }) ?? [];

        if (sortedPayments.length && sortedPayments[0]?.result == 'Open' && lyfPayStatus == 'canceled') {
          //Handle LyfPayPayment update
          await this.paymentsService.cancelPayment(sortedPayments[0]?.paymentId);
          return await this.handlingPayment(sortedPayments[0]?.paymentId);
        } else if (sortedPayments.length && sortedPayments[0]?.paymentId) return await this.handlingPayment(sortedPayments[0]?.paymentId);
      } else await this.router.navigate([`/${this.contextStore.locale()}/public/cart/${params.cartId}/checkout`]);
    }
  }

  private async handlingPayment(paymentId: string) {
    this.payment = await this.cartService.getCartPayment(paymentId);
    if (!this.payment) return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${PaymentResponseStatusObject.Unknown}`);
    switch (this.payment.status) {
      case PaymentResponseStatusObject.Open:
        if (this.payment.type == PaymentTypeObject.HandledByDelegate) return;
        else if (this.openRequest >= MAX_OPEN_REQUEST) {
          return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${PaymentResponseStatusObject.Unknown}`);
        } else {
          this.openRequest++;
          return setTimeout(async () => await this.handlingPayment(this.payment.id), 1500);
        }
      case PaymentResponseStatusObject.Pending:
        return setTimeout(async () => await this.handlingPayment(this.payment.id), 1500);
      case PaymentResponseStatusObject.Expired:
      case PaymentResponseStatusObject.Failed:
      case PaymentResponseStatusObject.Canceled:
      case PaymentResponseStatusObject.Unknown:
        return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${this.payment.status}`);
    }
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
}
