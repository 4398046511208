import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorShareService {
  private translate = inject(TranslateService);
  constructor() {}

  /**
   * Determine if the share API is available for the current device
   *
   * @returns true if the navigator.share API is available
   */
  public canShare(): boolean {
    if (navigator?.share) return true;
    else return false;
  }

  /**
   * Uses the navigator.share API
   *
   * @params data - ShareModel object wich contains title, text & url properties
   * @returns true if the navigator.share API is available
   */
  public async share(data: ShareModel): Promise<void> {
    if (navigator.share) {
      let files = [];
      if (data.imageUrl) {
        const response = await fetch(data.imageUrl);
        const image = await response.blob();
        files = [
          new File([image], this.translate.instant('DEMO.SHARE.INVITATION.FILENAME'), {
            type: 'image/jpeg',
          }),
        ];
      }

      return navigator.share({
        title: data.title ?? '',
        text: data.text ?? '',
        url: data.url ?? '',
        files,
      });
    } else {
      return new Promise((res, rej) => rej());
    }
  }
}

export class ShareModel {
  public title: string;
  public text?: string;
  public url: string;
  public imageUrl?: string;
}
