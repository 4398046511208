<div class="status-card flex column ac-center ai-center fullwidth-only">
  <div class="container" *ngIf="(errorService.summaryError$ | async) === false">
    <div [ngSwitch]="cardType">
      <app-wallet-card
        *ngSwitchCase="cardTypeEnum.WALLET"
        [clubInformation]="data"
        [showTooltip]="showTooltip"
        [footerInformationTooltipPosition]="footerInformationTooltipPosition"
        [footerInformationTooltipText]="footerInformationTooltipText"
        [footerInformationTooltipWidth]="footerInformationTooltipWidth"
        [footerInformationTooltipHeight]="footerInformationTooltipHeight"
        [showWalletButton]="showWalletButton"
        [paddingBottom]="paddingBottom"></app-wallet-card>
      <app-new-status-card
        *ngSwitchCase="cardTypeEnum.STATUS"
        [clubInformation]="data"
        [forecasts]="forecasts"
        [showTooltip]="showTooltip"
        [footerInformationTooltipPosition]="footerInformationTooltipPosition"
        [footerInformationTooltipText]="footerInformationTooltipText"
        [footerInformationTooltipWidth]="footerInformationTooltipWidth"
        [footerInformationTooltipHeight]="footerInformationTooltipHeight"
        [showStatusLink]="showStatusLink"
        [paddingBottom]="paddingBottom"
        [hiddeFooterAction]="hiddeFooterAction"
        [dropPadding]="dropPadding"></app-new-status-card>
      <app-delegate-card
        *ngSwitchCase="cardTypeEnum.DELEGATE"
        [delegateData]="data"
        [showTooltip]="showTooltip"
        [footerInformationTooltipPosition]="footerInformationTooltipPosition"
        [footerInformationTooltipText]="footerInformationTooltipText"
        [footerInformationTooltipWidth]="footerInformationTooltipWidth"
        [footerInformationTooltipHeight]="footerInformationTooltipHeight"></app-delegate-card>
    </div>
  </div>

  <div class="container min-height" *ngIf="errorService.summaryError$ | async">
    <div class="header">
      <div class="card-title flex column ac-center ai-center">
        <span *ngIf="cardType == cardTypeEnum.STATUS" class="status-text">{{ 'GLOBAL.YOURSTATUS' | translate }}</span>
        <span *ngIf="cardType == cardTypeEnum.WALLET" class="status-text">{{ 'GLOBAL.WALLET' | translate }}</span>
        <span *ngIf="cardType == cardTypeEnum.DELEGATE" class="status-text">{{ 'GLOBAL.DELEGATE' | translate }}</span>
      </div>
    </div>
    <div class="loader-bullet bullet-center">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
