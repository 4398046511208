import { Component, inject, Input } from '@angular/core';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { OrderResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../stores/resources.store';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-order-short',
  templateUrl: './order-short.component.html',
  styleUrls: ['./order-short.component.scss'],
})
export class OrderShortComponent {
  @Input() order: OrderResponse;
  @Input() border: string;
  @Input() actionLink: string = undefined;
  @Input() delivered: boolean = false;

  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore);
  readonly getDataService = inject(GetDatasService);
}
