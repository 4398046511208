import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  async playSound(filePath: string) {
    const audio = new Audio(filePath);
    await audio.play();
  }
}
