import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Breadcrumb } from 'src/app/core/models/breadcrumb.model';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { getVictoriaUrl } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss'],
})
export class BreadcrumComponent implements OnInit, AfterViewInit, OnDestroy {
  breadcrumbs$: Observable<Breadcrumb[]>;
  langueChangedSubscription: Subscription;

  baseUrl = '';

  @Input() pinkBreadcrumb = false;

  constructor(
    public readonly breadcrumb: BreadcrumbService,
    private translate: TranslateService
  ) {
    this.breadcrumbs$ = breadcrumb.breadcrumbs$;
  }

  ngOnInit() {
    this.baseUrl = getVictoriaUrl(this.translate.currentLang);
  }

  public ngOnDestroy(): void {
    if (this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
  }
}
