<div class="pink-area m-fullwidth my-acces">
  <h1>{{ 'PROFILE.CHANGEPASSWORD.TITLE' | translate }}</h1>

  <p>{{ 'PROFILE.CHANGEPASSWORD.LABEL' | translate }}</p>

  <div class="m-form-group column m-column m-ai-center m-j-center m-ac-center change-password">
    <button class="btn btn-primary m-j-center m-ai-center m-ac-center m-fit-content" (click)="requestNewPassword()" [disabled]="operationPending">
      <div class="label">{{ 'PROFILE.CHANGEPASSWORD.ACTION' | translate }}</div>
      <div class="icon icon-right icon">
        <svg viewBox="0 0 28 28" *ngIf="!operationPending">
          <use xlink:href="#icon-lock"></use>
        </svg>
        <div *ngIf="operationPending" class="loader loader-mini"></div>
      </div>
    </button>
  </div>
</div>

<div *ngIf="userStore.connectedAsRole() != 'Delegate'" class="pink-area m-fullwidth my-acces email">
  <h1>{{ 'PROFILE.CHANGEEMAIL.TITLE' | translate }}</h1>
  <div class="flex row ai-center block-margin warning">
    <div class="icon icon-right icon peach m-icon-xl icon-warning">
      <svg viewBox="0 0 28 28">
        <use xlink:href="#icon-warning"></use>
      </svg>
    </div>
    <p>{{ 'PROFILE.CHANGEEMAIL.LABEL' | translate }}</p>
  </div>
  <form [formGroup]="emailForm">
    <div class="form-group email-form">
      <div class="form-control flex-1">
        <label>{{ 'PROFILE.CHANGEEMAIL.EMAIL.OLD' | translate }}</label>
        <input type="email" formControlName="email" [attr.disabled]="''" />
      </div>
      <div class="form-control flex-1" [class.error]="fEmail?.newemail.errors && (fEmail?.newemail.dirty || fEmail?.newemail.touched)">
        <label>{{ 'PROFILE.CHANGEEMAIL.EMAIL.NEW' | translate }}</label>
        <input type="email" formControlName="newemail" />
        <div class="form-error-container" *ngIf="fEmail?.newemail.errors && (fEmail?.newemail.dirty || fEmail?.newemail.touched)">{{ 'GLOBAL.EMAIL.ERROR' | translate }}</div>
      </div>
    </div>
  </form>

  <div class="form-group row m-column m-ai-center m-j-center m-ac-center save-button">
    <button class="btn btn-primary m-j-center m-ai-center m-ac-center m-fit-content" (click)="update()" [disabled]="operationPending">
      <div class="label">{{ 'GLOBAL.ACTION.UPDATE' | translate }}</div>
      <div class="icon icon-right icon">
        <svg viewBox="0 0 28 28" *ngIf="!operationPending">
          <use xlink:href="#icon-save"></use>
        </svg>
        <div *ngIf="operationPending" class="loader loader-mini"></div>
      </div>
    </button>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
