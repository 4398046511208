export enum NotificationIcon {
  SUCCESS = '#icon-alert-success',
  INFO = '#icon-info',
  WARNING = '#icon-warning',
}

export enum NotificationStyle {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}
