export enum OrderActionEnum {
  CART_SENT_BY_CLIENT = 1,
  CART_VALIDATED_BY_CLIENT = 2,
  CART_VALIDATED_BY_DELEGATE = 3,
  VOUCHERS_VALIDATED = 10,
  DELIVERY_VALIDATED = 20,
  DIP_VALIDATED_BY_CLIENT = 30,
  DIP_VALIDATED_BY_DELEGATE = 31,
  DIP_SENT = 32,
  PAYMENT_PAID = 40,
  PAYMENT_HANDLED_BY_DELEGATE = 41,
  PAYMENT_SET_TYPE_BY_DELEGATE = 43,
  PAYMENT_SENT = 42,
  ORDER_CONFIRMED = 50,
  ORDER_CONFIRMATION_CANCELED = 51,
  DIP_UNVALIDATE = 130,
  PAYMENT_UNVALIDATE = 141,
  PRESENCE_SET = 1000,
  PRESENCE_UNSET = 1001,
  BECOMING_DELEGATE_SET = 1010,
  BECOMING_DELEGATE_UNSET = 1011,
  DEMO_REQUEST_SET = 1020,
  DEMO_REQUEST_UNSET = 1021,
}
