<div class="modal-container" id="shipping-info" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal" *ngIf="resourcesStore.isLoaded()">
    <div class="modal-icon icon peach" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1>{{ resourcesStore.i18n().resources.popupShippingOptions.mainTitle }}</h1>
    <div class="modal-body shipping">
      <p>{{ resourcesStore.i18n().resources.popupShippingOptions.description | removeHtmlTag }}</p>
      <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.popupShippingOptions.stepsDescription">
        <div class="delivery-info border-bottom">
          <h2 class="flex ai-start">
            <span>{{ item.number }}</span
            >{{ item.stepTitle }}
          </h2>
          <p>{{ item.text }}</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
