import { Component, inject, Input } from '@angular/core';
import { TranslationService } from '../../../../core/services/translation.service';
import { GetDatasService } from '../../../../core/services/get-datas.service';
import { getProfilePictureOrDefault } from '../../../../core/utils/filter.utils';
import { DelegateResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../stores/resources.store';

@Component({
  selector: 'app-delegate-card',
  templateUrl: './delegate-card.component.html',
  styleUrls: ['./delegate-card.component.scss'],
})
export class DelegateCardComponent {
  @Input() delegateData: DelegateResponse;
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  resourcesStore = inject(ResourcesStore);

  constructor(
    public translate: TranslationService,
    public getDatasService: GetDatasService
  ) {}

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
