import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

export const globalCatchError = <T>(store: Store, customHandler?: (err: any) => void): OperatorFunction<T, T> =>
  catchError(err => {
    if (customHandler)
      //Attention, customHandler must throw error if error doesn't have to be handled globally
      customHandler(err);
    //TODO define errorState for globalErrorHandling
    //notificationStore.notify({ title: 'Erreur', message: "L'opération demandé n'a pas pu être effectuée." });
    throw err;
  });
