import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsService } from '../../../../../core/services/forms.service';
import { SearchDelegateForm } from '../../../../../core/types/size-dropdown.type';
import { ResourcesStore } from '../../../../../stores/resources.store';

@Component({
  selector: 'app-search-delegate-modal',
  templateUrl: './search-delegate-modal.component.html',
  styleUrls: ['./search-delegate-modal.component.scss'],
})
export class SearchDelegateModalComponent implements OnInit, OnChanges {
  public resourcesStore = inject(ResourcesStore);

  @Input() isOpened: boolean;
  @Input() data: SearchDelegateForm;
  @Output() requestCloseSearchDelegateModal = new EventEmitter();
  @Output() requestSearchDelegate = new EventEmitter<SearchDelegateForm>();

  selectedTab = 'localisation';
  hasError = false;
  hasFormError = false;
  dataModel: SearchDelegateForm;

  constructor(public formService: FormsService) {}

  ngOnInit() {
    this.selectedTab = 'localisation';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) this.dataModel = { ...changes.data.currentValue };
  }

  public switchTab(tab: string) {
    this.hasError = false;
    this.hasFormError = false;
    this.selectedTab = tab;
    this.dataModel = {
      city: null,
      zip: null,
      name: null,
      country: null,
      address: null,
    };
  }

  public close() {
    this.requestCloseSearchDelegateModal.emit();
  }

  public validateFormAndCheck() {
    if (this.dataModel.country && (this.dataModel.city || this.dataModel.zip)) {
      this.requestSearchDelegate.emit(this.dataModel);
      this.close();
    } else this.hasError = true;
  }

  public validateDelegateAndCheck() {
    if (this.dataModel.name && this.dataModel.name.trim() != '') {
      this.requestSearchDelegate.emit(this.dataModel);
      this.close();
    } else this.hasError = true;
  }
}
