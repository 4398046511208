import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DemoHostessGiftsResponse_DemoSelectedGift, GiftCategory } from '@victoria-company/agora-client';
import { DemoHostessGifts, DemoHostessGifts_GiftsForCategory } from '../../../../stores/demo.store';

@Component({
  selector: 'app-hostess-gift-cards-container',
  templateUrl: './hostess-gift-cards-container.component.html',
  styleUrls: ['./hostess-gift-cards-container.component.scss'],
})
export class HostessGiftCardsContainerComponent {
  @Input() activeCategory: GiftCategory;
  @Input() locked = false;
  @Input() hostessGifts: DemoHostessGifts = null;
  @Input() isModal = true;

  @Output() requestCardOnClickBehavior = new EventEmitter();

  cardClicked(gift: DemoHostessGifts_GiftsForCategory) {
    this.requestCardOnClickBehavior.emit(gift.category);
  }

  getSelectedGift(category: GiftCategory, selectedGifts: DemoHostessGiftsResponse_DemoSelectedGift[] = []) {
    if (!selectedGifts.length) return null;

    const selectedHostessGift = selectedGifts.find(s => s.category == category);
    if (!selectedHostessGift) return null;

    return this.hostessGifts.giftsForCategories.find(g => g.category == category)?.choices.find(c => c.product.variants.some(v => v.id == selectedHostessGift.productVariantId));
  }

  getRemainingGiftsToChoose() {
    return this.hostessGifts?.giftsForCategories.length - this.hostessGifts?.selectedGifts.length;
  }
}
