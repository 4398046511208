import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TriggerService {
  _requestLogin$: Subject<boolean> = new Subject();
  requestLogin$: Observable<boolean> = this._requestLogin$.asObservable();

  _triggerUpdateStep$: Subject<number> = new BehaviorSubject(0);
  triggerUpdateStep$ = this._triggerUpdateStep$.asObservable();

  _refreshHistoryActionsTable$: Subject<boolean> = new BehaviorSubject(false);
  refreshHistoryActionsTable$: Observable<boolean> = this._refreshHistoryActionsTable$.asObservable();

  _refreshVouchersTable$: Subject<boolean> = new BehaviorSubject(false);
  refreshVouchersTable$: Observable<boolean> = this._refreshVouchersTable$.asObservable();

  _resetDefaultValueAddVoucher$: Subject<boolean> = new BehaviorSubject(false);
  resetDefaultValueAddVoucher$: Observable<boolean> = this._resetDefaultValueAddVoucher$.asObservable();

  _redirectUserInProperContext$: Subject<boolean> = new BehaviorSubject(false);
  redirectUserInProperContext$: Observable<boolean> = this._redirectUserInProperContext$.asObservable();

  private _triggerSocketReload: Subject<boolean> = new BehaviorSubject(false);
  triggerSocketReload$: Observable<boolean> = this._triggerSocketReload.asObservable();

  private _triggerBackToClients$: Subject<boolean> = new BehaviorSubject(false);
  triggerBackToClients$: Observable<boolean> = this._triggerBackToClients$.asObservable();

  constructor() {}

  requestLogin() {
    this._requestLogin$.next(true);
  }

  requestOrderNextStep(step: number) {
    this._triggerUpdateStep$.next(step);
  }

  refreshHistoryActionsTable(trig = false) {
    this._refreshHistoryActionsTable$.next(trig);
  }

  refreshVouchersTable(trig = false) {
    this._refreshVouchersTable$.next(trig);
  }

  resetDefaultValueAddVoucher(trig = false) {
    this._resetDefaultValueAddVoucher$.next(trig);
  }

  redirectUserInProperContext(trig = false) {
    this._redirectUserInProperContext$.next(trig);
  }

  socketReload(trig = false) {
    this._triggerSocketReload.next(trig);
  }

  setIsInClientDetails(trig = false) {
    this._triggerBackToClients$.next(trig);
  }
}
