import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import {
  GiftCategory
} from '@victoria-company/agora-client';
import { GetDatasService } from '../../../../core/services/get-datas.service';
import { CatalogService } from '../../../../core/services/V2/catalog.service';
import { ContextStore } from '../../../../stores/context.store';
import { DemoHostessGifts_GiftsForCategory_GiftChoice } from '../../../../stores/demo.store';

@Component({
  selector: 'app-hostess-gift-card',
  templateUrl: './hostess-gift-card.component.html',
  styleUrls: ['./hostess-gift-card.component.scss'],
})
export class HostessGiftCardComponent {


  @Input() category: GiftCategory;
  @Input() gifts: DemoHostessGifts_GiftsForCategory_GiftChoice[];
  @Input() isActiveGift: boolean;
  @Output() requestCardOnClickBehavior = new EventEmitter();

  readonly getDataService = inject(GetDatasService);
  readonly catalogService = inject(CatalogService)
  readonly contextStore = inject(ContextStore)

  //TODO specify gift type
  @Input() selectedGift: DemoHostessGifts_GiftsForCategory_GiftChoice;
  @Input() rank: number = 1;



  giftCardClicked() {
    this.requestCardOnClickBehavior.emit(this.category);
  }

  categoryIsAvailable() {
    return this.gifts.some(g => g.missingTurnOver == 0);
  }
}
