import { Component, inject, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { getAreaByUrl, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { ContextStore } from '../../../stores/context.store';
import { UserStore } from '../../../stores/user.store';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  readonly cookies = inject(CookieService);
  readonly contextStore = inject(ContextStore);
  readonly userStore = inject(UserStore);
  constructor(
    private authService: OidcSecurityService,
    private jwtService: JWTService,
    private dataServices: GetDatasService
  ) {}

  ngOnInit() {
    const isAuthenticate = this.userStore.isAuthenticated();
    let language = this.contextStore.locale();

    if (!language) language = this.cookies.get('v_locale');
    if (!language) {
      const area = getAreaByUrl(window.location.origin);
      const locales = environment.allowedLocales[area];
      language = locales.length ? locales[0] : 'fr-fr';
    }

    this.dataServices.deleteClientStatusCookie();

    if (isAuthenticate) {
      this.authService.logoff().subscribe(_ => {
        this.jwtService.deleteTokenCookie();
        this.jwtService.cleanState();
        this.jwtService.setLoggedOut();
      });
    } else {
      window.location.href = getVictoriaUrl(language) + `/${language}`;
    }
  }
}
