import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { UserService } from '../core/services/V2/user.service';
import { DemoService } from '../core/services/V2/demo.service';
import {
  ClubActionResponse,
  ClubCustomerActionTypesResponse,
  DelegateResponse,
  GetClubInformationResponse,
  OrderResponse,
  UpdateUserProfileRequest,
  UserProfileResponse,
  VoucherResponse,
} from '@victoria-company/agora-client';
import jwtDecode from 'jwt-decode';
import { CommonService } from '../core/services/V2/common.sevice';
import { NotificationStore } from './notification.store';
import { AlertTypes } from '../core/enums/alert-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { DateOnly } from '@microsoft/kiota-abstractions';
import { ClubService } from '../core/services/V2/club.service';

const transformSSORoles = (roles: string[]) => {
  const appRoles = [];
  if (roles.includes('r_delegate')) appRoles.push('Delegate');
  if (roles.includes('r_client')) appRoles.push('Client');

  return appRoles;
};

export interface UserState {
  isAuthenticated: boolean;
  roles: string[];
  connectedAsRole: string;
  profile: UserProfileResponse;
  clubInformation: GetClubInformationResponse;
  clubVouchers: {
    getUsed: boolean;
    vouchers: VoucherResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
  };
  trackableActions: ClubCustomerActionTypesResponse[];
  clubActions: {
    actions: ClubActionResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
    filters: {
      showPurchases: boolean;
      showVouchers: boolean;
      showOthers: boolean;
    };
  };
  clubOrders: {
    orders: OrderResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
  };
  clubOrder: OrderResponse;
  delegate: DelegateResponse;
  isProfileLoaded: boolean;
  userActiveDemoCode: string;
  userCode: string;
}

export const initialState: UserState = {
  userActiveDemoCode: null,
  profile: null,
  clubInformation: null,
  clubVouchers: {
    getUsed: true,
    vouchers: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: true,
  },
  trackableActions: [],
  clubActions: {
    actions: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: false,
    filters: {
      showPurchases: true,
      showVouchers: true,
      showOthers: true,
    },
  },
  clubOrders: {
    orders: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: true,
  },
  clubOrder: null,
  delegate: null,
  isProfileLoaded: false,
  isAuthenticated: null,
  roles: null,
  connectedAsRole: null,
  userCode: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialState),
  withComputed(({ roles, profile }) => ({
    hasProfile: computed(() => profile() != null),
    hasRoles: computed(() => roles() != null),
  })),
  withMethods((store, commonService = inject(CommonService), userService = inject(UserService), demoService = inject(DemoService), clubService = inject(ClubService)) => ({
    async loadUserActiveDemoCode(): Promise<void> {
      const activeDemo = await demoService.getActiveDemo();
      patchState(store, () => ({
        userActiveDemoCode: activeDemo.activeDemo?.code,
      }));
    },
    async loadProfile(): Promise<void> {
      const profile = await userService.getProfile();
      patchState(store, () => ({
        profile,
        isProfileLoaded: true,
      }));
    },
    async loadClubInformation(): Promise<void> {
      const clubInformation = await userService.getClubInformation();
      patchState(store, () => ({
        clubInformation,
      }));
    },
    async loadDelegate(): Promise<void> {
      if (store.hasProfile() && store.profile().assignedDelegateId) {
        const delegateProfileResponse = await commonService.getDelegateProfileAsPromise(store.profile().assignedDelegateId);
        patchState(store, { delegate: delegateProfileResponse });
      }
    },
    async loadUserCode(): Promise<void> {
      const userCode = await userService.getUserCode();
      patchState(store, { userCode });
    },
    //Not a computed bc connectedAsRole will evolve with role switch
    setRolesFromToken(token: string): void {
      const roles = token ? (jwtDecode(token) as { roles: string[] }).roles : [];
      patchState(store, () => ({
        roles: transformSSORoles(roles),
        connectedAsRole: roles.includes('r_delegate') ? 'Delegate' : 'Client',
      }));
    },
    async getTrackableActions(contextId: number) {
      const getActionsResponse = await clubService.getTrackableActions(contextId);
      if (getActionsResponse) {
        patchState(store, {
          trackableActions: getActionsResponse.customerActions,
        });
      }
    },

    async setClubActionsFilterShowPurchase(value: boolean) {
      patchState(store, {
        clubActions: {
          ...store.clubActions(),
          pageIndex: 0,
          filters: {
            ...store.clubActions().filters,
            showPurchases: value,
          },
        },
      });
    },
    async setClubActionsFilterShowVouchers(value: boolean) {
      patchState(store, {
        clubActions: {
          ...store.clubActions(),
          pageIndex: 0,
          filters: {
            ...store.clubActions().filters,
            showVouchers: value,
          },
        },
      });
    },
    async setClubActionsFilterShowOthers(value: boolean) {
      patchState(store, {
        clubActions: {
          ...store.clubActions(),
          pageIndex: 0,
          filters: {
            ...store.clubActions().filters,
            showOthers: value,
          },
        },
      });
    },

    async getClubActions(userId: string) {
      const getClubActionsResult = await clubService.getClubActions(
        userId,
        store.clubActions().pageIndex,
        store.clubActions().pageSize,
        store.clubActions().filters.showPurchases,
        store.clubActions().filters.showVouchers,
        store.clubActions().filters.showOthers
      );

      if (getClubActionsResult) {
        patchState(store, {
          clubActions: {
            ...store.clubActions(),
            actions: store.clubActions().pageIndex == 0 ? [...getClubActionsResult.actions] : [...store.clubActions().actions, ...getClubActionsResult.actions],
            pageIndex: getClubActionsResult.hasMore ? store.clubActions().pageIndex + 1 : store.clubActions().pageIndex,
            hasMore: getClubActionsResult.hasMore,
          },
        });
      }
    },

    async getClubOrders() {
      const getClubOrdersResult = await clubService.getClubOrders(store.clubOrders()?.pageIndex, store.clubOrders()?.pageSize);
      if (getClubOrdersResult) {
        patchState(store, {
          clubOrders: {
            ...store.clubOrders(),
            hasMore: getClubOrdersResult.hasMore,
            pageIndex: getClubOrdersResult.hasMore ? store.clubOrders()?.pageIndex + 1 : store.clubOrders()?.pageIndex,
            orders: store.clubOrders()?.pageIndex == 0 ? [...getClubOrdersResult.orders] : [...(store.clubOrders()?.orders ?? []), ...getClubOrdersResult.orders],
          },
        });
      }
    },

    async getClubOrderById(id: string) {
      if (store.clubOrder()?.id == id) return;

      const order = await clubService.getClubOrderById(id);
      patchState(store, {
        clubOrder: order ?? null,
      });
    },
  })),
  withMethods((store, userService = inject(UserService), clubService = inject(ClubService), translate = inject(TranslateService), notificationStore = inject(NotificationStore)) => ({
    async setIsAuthenticated(isAuthenticated: boolean): Promise<void> {
      //prevent to execute multiple call when user already authenticated
      if (store.isAuthenticated() && isAuthenticated) return;

      patchState(store, () => ({
        isAuthenticated: isAuthenticated,
      }));

      if (isAuthenticated) {
        await store.loadProfile();
        await store.loadClubInformation();
        await store.loadDelegate();
      }
    },
    async updateProfile(request: UpdateUserProfileRequest): Promise<void> {
      await userService.updateProfile(request);

      notificationStore.notify({
        title: '',
        message: translate.instant('ALERT.PROFILE.UPDATED'),
        icon: '#icon-success',
        style: AlertTypes.SUCCESS,
      });
      store.loadProfile();
    },
    async addBirthdateToProfile(birthdate: Date, contextId: number) {
      const date: DateOnly = DateOnly.fromDate(birthdate);
      await clubService.setBirthdate(date);
      await store.loadClubInformation();
      await store.loadProfile();
      await store.getTrackableActions(contextId);
    },
    async createVoucherClubFromWallet(amount: number) {
      return await clubService.createVoucherClub(amount);
    },
    async getVouchers(pageIndex: number = 0, getUsed: boolean = true, pageSize: number = 20) {
      const result = await userService.getVouchers(pageIndex, pageSize, getUsed);

      patchState(store, {
        clubVouchers: {
          ...store.clubVouchers(),
          vouchers: result.pageIndex == 0 ? [...(result.vouchers ?? [])] : [...(store.clubVouchers()?.vouchers ?? []), ...(result.vouchers ?? [])],
          pageIndex: result.hasMore ? result.pageIndex + 1 : result.pageIndex,
          pageSize: result.pageSize,
          hasMore: result.hasMore,
          getUsed: getUsed,
        },
      });
    },
    async changeUserEmail(email: string) {
      await userService.updateUserEmail(email);
      patchState(store, {
        profile: {
          ...store.profile(),
          email,
        },
      });
    },
  }))
);
