import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ScreenService } from 'src/app/core/services/screen.service';
import { AddAdvantageStatus } from '../../../../state/models/addAvantageStatus';
import { ResourcesStore } from '../../../../stores/resources.store';
import { CartResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-voucher-encoding',
  templateUrl: './voucher-encoding.component.html',
  styleUrls: ['./voucher-encoding.component.scss'],
})
export class VoucherEncodingComponent implements OnChanges {
  public resourcesStore = inject(ResourcesStore);

  @Input() isValidationTunnel: boolean = false;
  @Input() cart: CartResponse;
  @Input() stateStatus: AddAdvantageStatus;
  @Output() requestValidateEncodedVoucherCode = new EventEmitter<string>();
  @Output() requestResetStatuses = new EventEmitter();

  voucherCode: string;
  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.stateStatus && changes.stateStatus.currentValue) {
      const status: AddAdvantageStatus = changes.stateStatus.currentValue;
      if (status.added) this.notifyAsDone();
    }
  }

  validateVoucherCode() {
    if (this.voucherCode) this.requestValidateEncodedVoucherCode.emit(this.voucherCode);
  }

  private notifyAsDone() {
    const animationDuration = 3500;

    if (this.screen.isMobile) {
      document.getElementsByClassName('encode-voucher-done')[0]?.classList.toggle('done');
      document.getElementsByClassName('encode-voucher-check')[0]?.classList.toggle('display');
      setTimeout(() => {
        document.getElementsByClassName('encode-voucher-check')[0]?.classList.toggle('display');
        document.getElementsByClassName('encode-voucher-done')[0]?.classList.toggle('done');
        this.requestResetStatuses.emit();
        this.voucherCode = null;
      }, animationDuration);
    } else
      setTimeout(() => {
        this.requestResetStatuses.emit();
        this.voucherCode = null;
      }, animationDuration);
  }
}
