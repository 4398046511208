import { Component, inject, OnInit } from '@angular/core';
import { JWTService } from '../../../../../../core/services/jwt.service';
import { ClientCommunications } from '../../../../../../core/models/clientCommunications.model';
import { RGPDService } from '../../../../../../core/services/rgpd.service';
import { UserStore } from '../../../../../../stores/user.store';
import { ContextStore } from '../../../../../../stores/context.store';

@Component({
  selector: 'app-mycommunications',
  templateUrl: './mycommunications.component.html',
  styleUrls: ['./mycommunications.component.scss'],
})
export class MycommunicationsComponent implements OnInit {
  readonly contextStore = inject(ContextStore);

  communications: ClientCommunications;
  noCommunications = false;

  readonly userStore = inject(UserStore);

  private operation = {
    optIn: 1,
    optOut: 2,
  };
  private target = {
    victoria: 1,
    fidelity: 2,
    delegate: 500,
  };

  constructor(
    private jwtService: JWTService,
    private rgpdService: RGPDService
  ) {}

  ngOnInit() {
    this.rgpdService.getClientCommunications(this.contextStore.contextId(), this.jwtService.getSubFromToken(), this.userStore.profile()?.email);

    this.rgpdService.communications$.subscribe(res => {
      if (res) {
        this.communications = res;
      }
    });

    this.rgpdService.noCommunications$.subscribe(res => {
      this.noCommunications = res;
    });
  }

  updateValue(value: boolean, target: string, pref?: any) {
    this.rgpdService.postClientCommunication(
      this.communications,
      this.target[target],
      value ? this.operation.optIn : this.operation.optOut,
      this.contextStore.contextId(),
      this.jwtService.getSubFromToken(),
      this.userStore.profile()?.email,
      pref
    );
  }

  updateRGPD() {
    this.rgpdService.postClientCommunication(null, this.target.victoria, this.operation.optIn, this.contextStore.contextId(), this.jwtService.getSubFromToken(), this.userStore.profile()?.email, null);
  }
}
