import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getAreaByUrl } from '../core/utils/filter.utils';
import { UserStore } from '../stores/user.store';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  configId = 'desktop';
  isAuthenticated = false;

  readonly userStore = inject(UserStore);
  readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const params = route.params;
    const area = getAreaByUrl(window.location.origin);
    const availableLanguages = environment.allowedLocales[area];

    if (params.id && availableLanguages.includes(params.id)) {
      if (this.userStore.isAuthenticated()) return this.router.navigateByUrl('/' + params.id + '/club/home');
      else return true;
    } else return this.router.navigate(['/']);
  }
}
