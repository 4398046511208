<div class="communications-container">
  <div class="header flex center-all column">
    <img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria" />
    <h1>{{ 'COMMUNICATION.TITLE' | translate }}</h1>
    <div class="dark"></div>
  </div>
  <div class="body-title flex ai-center column">
    <p class="subtitle" [innerHTML]="'COMMUNICATION.CAPTION' | translate"></p>
    <div class="heading flex column ai-center">
      <h2>{{ 'COMMUNICATION.EMAIL' | translate }}</h2>
      <h3>{{ clientEmail }}</h3>
    </div>
  </div>
  <div class="body-communications container flex column ai-start" *ngIf="!(error.profileError$ | async)">
    <div class="communications-main">
      <h2>{{ 'PROFILE.COMMUNICATION.VICTORIA' | translate }}</h2>
      <div class="flex row">
        <label class="flex row checkbox path" *ngIf="communications && !noCommunications">
          <input type="checkbox" *ngIf="communications" [(ngModel)]="communications.canSendFromVictoria" (ngModelChange)="updateValue($event, 'victoria')" />
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
        </label>
        <label class="flex row checkbox path" *ngIf="noCommunications">
          <input type="checkbox" (click)="updateRGPD()" />
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
        </label>
      </div>

      <div class="warning flex row ai-center" *ngIf="communications && !communications?.canSendFromVictoria">
        <div>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#icon-warning"></use>
          </svg>
        </div>
        <div [innerHTML]="'PROFILE.COMMUNICATION.NOSUBSCRIBE' | translate"></div>
      </div>
    </div>
    <div class="communications-delegate">
      <h2>{{ 'PROFILE.COMMUNICATION.DELEGATES' | translate }}</h2>
      <div *ngIf="communications?.detailsAddress?.length">
        <p>{{ 'PROFILE.COMMUNICATION.DELEGATES.CONSENT' | translate }}</p>
        <div class="flex row" *ngFor="let pref of communications.detailsAddress">
          <label class="flex row checkbox path text-margin-bottom">
            <input type="checkbox" [(ngModel)]="pref.isOptIn" (ngModelChange)="updateValue($event, 'delegate', pref)" />
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            {{ pref.delegate?.displayName }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="body-communications container flex column ai-center error-box" *ngIf="error.profileError$ | async">
    <p class="subtitle">{{ 'COMMUNICATION.UNAUTHORIZED' | translate }}</p>
  </div>
</div>
