import { Injectable } from '@angular/core';
import { AgoraClient, createAgoraClient } from '@victoria-company/agora-client';
import { FetchRequestAdapter } from '@microsoft/kiota-http-fetchlibrary';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AgoraAuthenticationProvider } from './agoraAuthenticationProvider';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private client = new ReplaySubject<AgoraClient>(1);
  private _client: AgoraClient | null;

  public constructor(private authService: AuthService) {
    this.authService.isAuthenticated$.pipe(first()).subscribe(() => {
      const adapter = new FetchRequestAdapter(new AgoraAuthenticationProvider(this.authService));
      adapter.baseUrl = environment.api_url;
      this.client.next(createAgoraClient(adapter));
    });
  }

  public getClient(): Observable<AgoraClient> {
    return this.client.asObservable().pipe(first());
  }

  public getClientInstance(): AgoraClient {
    if (this._client == null) {
      const adapter = new FetchRequestAdapter(new AgoraAuthenticationProvider(this.authService));
      adapter.baseUrl = environment.api_url;
      this._client = createAgoraClient(adapter);
    }
    return this._client;
  }

  public getTypeSenseSettings() {
    const client = this.getClientInstance();
    return client.api.catalog.typesenseSettings.get();
  }
}
