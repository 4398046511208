<div class="discount-box" *ngIf="walletAvailableAmount || cart.walletAmount != 0">
  <div class="wallet-consumption-container">
    <div class="header">{{ 'ORDER_TUNNEL.REDUCTIONS.WALLET' | translate }}</div>
    <div class="flex ai-center avantages-box-description">
      <div>
        <div class="wallet-bullet flex ai-center j-center">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-wallet"></use>
          </svg>
        </div>
      </div>
      <div [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XWALLETAVAILABLE' | translate: { X: walletAvailableAmount | agoraCurrency }"></div>
    </div>
    <div class="flex row gap-15 ai-center pink-frame">
      <div class="flex row j-space-between pink-frame-input m-column" *ngIf="cart.walletAmount === 0">
        <div class="form-control flex row ai-center wallet-input">
          <span>{{ 'ORDER_TUNNEL.REDUCTIONS.AMOUNT' | translate }}</span>
          <div class="flex row ai-center">
            <form [formGroup]="walletAmountForm">
              <input data-testId="wallet-encoding" type="number" min="0.00" step="0.01" formControlName="walletAmount" (change)="validateInputValue()" />
            </form>
            <span class="currency">€</span>
          </div>
        </div>
        <button
          data-testId="wallet-encoding-action"
          class="btn btn-primary {{ cart.walletAmount > 0 ? 'disabled' : '' }} {{ enableWalletButton ? '' : 'disabled' }}"
          [ngClass]="cart.demoId ? 'secondary' : ''"
          (click)="useWalletAmount()"
          [disabled]="stateStatus.isBusy || !enableWalletButton">
          {{ 'GLOBAL.ACTION.USE' | translate }}
          <div *ngIf="stateStatus.isBusy" class="loader loader-mini"></div>
        </button>
      </div>
      <div class="flex row j-space-between ai-center width100 m-column" *ngIf="cart.walletAmount > 0">
        <p [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XFROMTOTAL' | translate: { X: cart.walletAmount | agoraCurrency }"></p>
        <div>
          <button class="btn btn-primary" data-testId="wallet-encoding-action-remove" (click)="resetWalletAmount()" [disabled]="stateStatus.isBusy">
            {{ 'GLOBAL.ACTION.REMOVE' | translate }}
            <div *ngIf="stateStatus.isBusy" class="loader loader-mini"></div>
          </button>
        </div>
      </div>
      <app-flow-error *ngIf="stateStatus.error" [error]="stateStatus.error" [showError]="true" />
    </div>
  </div>
</div>
