import { Component, inject } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { CartStore } from '../../../../stores/cart.store';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss'],
})
export class PanierComponent {
  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);
  public resourcesStore = inject(ResourcesStore);
  public cartStore = inject(CartStore);
  readonly breadcrumb = inject(BreadcrumbService);

  // private openRedirectionModal() {
  //   this.modalService.open('#redirectionModal');
  //   setTimeout(_ => {
  //     this.jwtService.deleteTokenCookie();
  //     this.authService.logoff();
  //     window.location.href = getEshopUrlByContext(this.clientDatas.contextId) + '/' + this.clientDatas.locale;
  //   }, 5000);
  // }
}
