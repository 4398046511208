import { signalStore, withMethods } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { ContextStore } from './context.store';

export const RedirectionStore = signalStore(
  { providedIn: 'root' },
  withDevtools('redirection'),
  withMethods((store, contextStore = inject(ContextStore), router = inject(Router)) => ({
    async redirectTo(segment?: string): Promise<boolean> {
      if (segment) return router.navigate([`${contextStore.locale()}/${segment}`]);
      else return router.navigate([`${contextStore.locale()}/`]);
    },
  }))
);
