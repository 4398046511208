import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ContextStore } from '../../../../../../stores/context.store';

@Component({
  selector: 'app-back-to-manager',
  templateUrl: './back-to-manager.component.html',
  styleUrls: ['./back-to-manager.component.scss'],
})
export class BackToManagerComponent implements OnInit{
  contextStore = inject(ContextStore)
  active = inject(ActivatedRoute)
  router = inject(Router)

  category:string;
  action:string = ''

  ngOnInit(){
    //IC Direct access
    this.getParameters()

    //IC Internal Navigation
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.getParameters());
  }


  async navigate() {

    if(this.action) await this.router.navigate([`/${this.contextStore.locale()}/club/manage/${this.category.toLowerCase()}`])
    else if(this.category)await this.router.navigate([`/${this.contextStore.locale()}/club/manager`])
  }

  private getParameters(){
    //GET param from main component
    this.active.params.subscribe(params => {
      if (params.category) this.category = params.category.toUpperCase();
    });

    //GET param from first child component
    this.active.firstChild.params. subscribe(params => {
      if (params.action) this.action = params.action.toUpperCase();
      else this.action = '';
    })
  }
}
