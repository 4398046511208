import { Component, effect, ElementRef, HostListener, inject, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NavigatorShareService } from 'src/app/core/services/navigator-share.service';
import { UserStore } from '../../../../stores/user.store';
import { ClubService } from '../../../../core/services/V2/club.service';
import { GetBoosterInformationResponse } from '@victoria-company/agora-client';
import { getUserReferralLink } from '../../../../core/utils/common.utils';

@Component({
  selector: 'app-booster',
  templateUrl: './booster.component.html',
  styleUrls: ['./booster.component.scss'],
})
export class BoosterComponent implements OnDestroy {
  hasBoosters = true;
  locale: string;
  referralLink: string;
  referralsData: GetBoosterInformationResponse;

  readonly userStore = inject(UserStore)
  readonly clubService = inject(ClubService)

  @ViewChild('conditions') cdtElement: ElementRef;

  //NG LifeCycle
  subscriptions: Subscription[] = [];

  constructor(
    private navigatorShare: NavigatorShareService,
    private translate: TranslateService,
    private router: Router,
  ) {
    effect(async () => {
      if(this.userStore.profile() && this.userStore.clubInformation()){
        await this.loadReferralInfos();
      }
    })
  }

  share() {
    if (!this.navigatorShare.canShare() && this.referralLink) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.navigatorShare
      .share({
        title: 'Referral Link',
        text: this.translate.instant('SHARE.REFERRALLINK.TEXT'),
        url: this.referralLink,
      })
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
  }

  copy() {
    navigator.clipboard.writeText(this.referralLink).then(r => {});

    document.getElementById('icon-copy-booster-check')?.classList.toggle('display');
    document.getElementById('icon-copy-booster')?.classList.toggle('display');
    setTimeout(() => {
      document.getElementById('icon-copy-booster-check')?.classList.toggle('display');
      document.getElementById('icon-copy-booster')?.classList.toggle('display');
    }, 1000);
  }

  displayConditions() {
    if (this.cdtElement) {
      this.cdtElement?.nativeElement?.classList?.toggle('show');
    }
  }

  private async loadReferralInfos(){
    if(this.userStore.connectedAsRole()=="Delegate"){
      await this.router.navigate([`/${this.locale}/club/home`]);
    } else {
      this.referralLink = getUserReferralLink(this.userStore.profile()?.contextId, this.userStore.clubInformation()?.referralRoute);
      this.referralsData = await this.clubService.getReferralData(this.userStore.profile()?.id)
    }
  }

  @HostListener('window:beforeunload')
  @HostListener('unload')
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
