import { Component, inject, Input, OnInit } from '@angular/core';
import { GetClubInformationResponse } from '@victoria-company/agora-client';
import { getStatusIdFromClubStatus } from '../../../../../core/utils/common.utils';
import { ContextStore } from '../../../../../stores/context.store';
import { UserStore } from '../../../../../stores/user.store';

@Component({
  selector: 'app-status-data-wrapper',
  templateUrl: './status-data-wrapper.component.html',
  styleUrls: ['./status-data-wrapper.component.scss'],
})
export class StatusDataWrapperComponent implements OnInit {
  @Input() clubInformation: GetClubInformationResponse;
  loaded = false;

  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);

  async ngOnInit() {
    await this.userStore.getTrackableActions(this.contextStore.contextId());
  }

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
