import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartVoucherResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-cart-voucher',
  templateUrl: './cart-voucher.component.html',
  styleUrls: ['./cart-voucher.component.scss'],
})
export class CartVoucherComponent {
  //V2
  @Input() canDeleteVoucher: boolean = false;
  @Input() voucher: CartVoucherResponse;
  @Output() requestDeleteVoucher = new EventEmitter();

  //V1
  // @Input() voucher: LocalOrderVoucher;
  // @Input() demoCode: string;
  // @Input() isOrderValidation = false;
  // @Input() isReceipt = false;
  // @Input() order: ClientOrder;
  // @Input() disableDeleteVouchersWallet = false;

  deleteVoucher() {
    this.requestDeleteVoucher.emit();
  }

  // deleteVoucher(code: string) {
  //   if (this.demoCode && this.isOrderValidation) {
  //     const clientGuid = this.route.snapshot.params?.clientGuid ?? this.order.clientSub;
  //     this.orderService.deleteVoucherClientOrder(this.demoCode, clientGuid, code, this.order.resolvedId);
  //   } else {
  //     this.orderService.deleteVoucherOrderGuest(code);
  //   }
  // }
}
