import { ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CardType } from '../../../../core/enums/card-type.enum';
import { ModalService } from '../../../../core/services/modal.service';
import { TriggerService } from '../../../../core/services/trigger.service';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnDestroy {
  readonly userStore = inject(UserStore)
  readonly contextStore = inject(ContextStore);

  refreshTableDatas: Subject<void> = new Subject<void>();
  displayCreationDialog: Subject<void> = new Subject<void>();
  displayCreatedDialog: Subject<void> = new Subject<void>();
  cardType = CardType;
  locale: string;

  public addVoucherAvailable = false;

  private langueChangedSubscription: Subscription;

  constructor(
    protected cd: ChangeDetectorRef,
    public modal: ModalService,
    private triggerService: TriggerService
  ) {}

  public ngOnDestroy(): void {
    if (this.langueChangedSubscription) {
      this.langueChangedSubscription.unsubscribe();
    }
  }

  // public ngAfterViewInit() {
  //   if (this.langueChangedSubscription) {
  //     this.langueChangedSubscription.unsubscribe();
  //   }
  //   this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe(async value => {
  //     if (value === true) {
  //       this.translate.use(this.storage.languageCode);
  //     }
  //   });
  // }

  displayCreationDialogAction() {
    this.modal.open('#walletCreateVoucher');
    this.triggerService.resetDefaultValueAddVoucher(true);
  }
}
