<app-page-title mainTitle="{{ 'PROFILE.TITLE' | translate }}" subTitle="{{ 'PROFILE.SUBTITLE' | translate }}"> </app-page-title>

<div class="Profil-Container">
  <ul class="profil-nav flex row j-start m-j-center">
    <li class="flex-1">
      <a [routerLink]="['data']" routerLinkActive="selected" class="flex row m-column j-center ai-center ac-center profile-link flex-1">
        <div class="icon icon-left m-icon-xl m-no-margin">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-members"></use>
          </svg>
        </div>
        <div class="label space-nowrap">{{ 'MENU.PROFILE.INFORMATIONS' | translate }}</div>
      </a>
    </li>
    <li class="flex-1">
      <a [routerLink]="['access']" routerLinkActive="selected" class="flex row m-column j-center ai-center ac-center profile-link flex-1">
        <div class="icon icon-left m-icon-xl m-no-margin">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-settings"></use>
          </svg>
        </div>
        <div class="label space-nowrap">{{ 'MENU.PROFILE.ACCES' | translate }}</div>
      </a>
    </li>
    <li class="flex-1">
      <a [routerLink]="['communication']" routerLinkActive="selected" class="flex row m-column j-center ai-center ac-center profile-link flex-1">
        <div class="icon icon-left m-icon-xl m-no-margin">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-communication"></use>
          </svg>
        </div>
        <div class="label space-nowrap">{{ 'MENU.PROFILE.COMMUNICATION' | translate }}</div>
      </a>
    </li>
  </ul>

  <router-outlet></router-outlet>
</div>
