import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { getCookieDomainByUrl } from '../utils/filter.utils';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private cookie: CookieService) {}

  public get languageCode(): string {
    return localStorage.getItem('lc');
  }

  public set languageCode(value: string) {
    localStorage.setItem('lc', value);
  }

  public get token(): string {
    return localStorage.getItem('victoria_jwt_token');
  }

  public set token(value: string) {
    localStorage.setItem('victoria_jwt_token', value);
  }

  public get clientFirstName(): string {
    return localStorage.getItem('cft');
  }

  public set clientFirstName(value: string) {
    localStorage.setItem('cft', value);
  }

  public set redirectTo(value: string) {
    localStorage.setItem('rt', value.toString());
  }

  public get redirectTo() {
    return localStorage.getItem('rt');
  }

  public deleteRedirectTo() {
    localStorage.removeItem('rt');
  }

  public set registeredStatusId(status: any) {
    sessionStorage.setItem('rid', status);
  }

  public set demoCode(value: string) {
    localStorage.setItem('demoCode', value);
  }

  public get demoCode() {
    return localStorage.getItem('demoCode');
  }

  public set currentTray(value: any) {
    localStorage.setItem('currentTray', value);
  }

  public get currentTray() {
    return localStorage.getItem('currentTray') ?? 0;
  }

  public setTempDemoComment(demoCode: any, value: string) {
    localStorage.setItem('tempCommentBackup' + demoCode, value);
  }

  public getTempDemoComment(demoCode: any) {
    return localStorage.getItem('tempCommentBackup' + demoCode) ?? undefined;
  }

  public get sandboxBannerDismissed() {
    return sessionStorage.getItem('hideBanner') !== null;
  }

  public set sandboxBannerDismissed(value: boolean) {
    sessionStorage.setItem('hideBanner', value.toString());
  }

  public setTokenRenewing(active: boolean) {
    if (active) sessionStorage.setItem('renewing', 'true');
    else sessionStorage.removeItem('renewing');
  }

  public currentDemoCode() {
    const demoCode = this.cookie.get('currentDemoCode');

    if (demoCode == 'undefined' || demoCode == null || demoCode == '') return this.demoId;
    else return demoCode;
  }

  public set demoId(value: string) {
    this.cookie.set('demoId', value, 30, '/', getCookieDomainByUrl(window.location.origin));
  }

  public get demoId() {
    const demoId = this.cookie.get('demoId');

    if (demoId == 'undefined' || demoId == null || demoId == '') return '';
    else return demoId;
  }

  public removeDemoId() {
    this.cookie.delete('demoId', '/', getCookieDomainByUrl(window.location.origin));
  }

  public set delegateCode(value: string) {
    this.cookie.set('delegateCode', value, 30, '/', getCookieDomainByUrl(window.location.origin));
  }

  public get delegateCode() {
    const delegateCode = this.cookie.get('delegateCode');

    if (delegateCode == 'undefined' || delegateCode == null || delegateCode == '') return '';
    else return delegateCode;
  }

  public removeDelegateCode() {
    this.cookie.delete('delegateCode', '/', getCookieDomainByUrl(window.location.origin));
  }

  public set delegateId(value: string) {
    this.cookie.set('delegateId', value, 30, '/', getCookieDomainByUrl(window.location.origin));
  }

  public get delegateId() {
    const delegateId = this.cookie.get('delegateId');

    if (delegateId == 'undefined' || delegateId == null || delegateId == '') return '';
    else return delegateId;
  }

  public removeDelegateId() {
    this.cookie.delete('delegateId', '/', getCookieDomainByUrl(window.location.origin));
  }

  public get currentRole() {
    const role = sessionStorage.getItem('cr') ?? undefined;
    return role;
  }

  public set currentRole(value) {
    sessionStorage.setItem('cr', value.toString());
  }

  public getDismissAlert(demoCode): DismissDemoAlert {
    const value = this.cookie.get('alert_' + demoCode);
    if (value == null || value == '') return { start: true, end: true };
    else return JSON.parse(value);
  }

  public setDismissAlert(demoCode, obj) {
    this.cookie.set('alert_' + demoCode, JSON.stringify(obj), null, '/', getCookieDomainByUrl(window.location.origin));
  }

  public getTrackingListing() {
    const value = this.cookie.get(`tracking_lising_demo`);
    if (value == null || value == '') return false;
    else return true;
  }

  public setTrackingListing() {
    this.cookie.set(`tracking_lising_demo`, 'true', null, '/', getCookieDomainByUrl(window.location.origin));
  }

  public getTrackingDemo(demoCode) {
    const value = this.cookie.get(`tracking_${demoCode}_home`);
    if (value == null || value == '') return false;
    else return true;
  }

  public setTrackingDemo(demoCode: string) {
    this.cookie.set(`tracking_${demoCode}_home`, 'true', null, '/', getCookieDomainByUrl(window.location.origin));
  }

  public getTrackingJewels(demoCode) {
    const value = this.cookie.get(`tracking_${demoCode}_jewels`);
    if (value == null || value == '') return false;
    else return true;
  }

  public setTrackingJewels(demoCode: string) {
    this.cookie.set(`tracking_${demoCode}_jewels`, 'true', null, '/', getCookieDomainByUrl(window.location.origin));
  }

  public getTrackingUnivers(demoCode) {
    const value = this.cookie.get(`tracking_${demoCode}_univers`);
    if (value == null || value == '') return false;
    else return true;
  }

  public setTrackingUnivers(demoCode: string) {
    this.cookie.set(`tracking_${demoCode}_univers`, 'true', null, '/', getCookieDomainByUrl(window.location.origin));
  }

  /// V2 NEWS ///
  public getAnonymousWishlist(): WishlistAnonymousData {
    const storageInfo = this.cookie.get('victoria_anonymous_wishlist');
    if (storageInfo) return JSON.parse(storageInfo);
    else {
      this.setAnonymousWishlist({ productIds: [] });
      return { productIds: [] };
    }
  }
  public setAnonymousWishlist(data: WishlistAnonymousData) {
    this.cookie.set('victoria_anonymous_wishlist', JSON.stringify(data), 180, '/', getCookieDomainByUrl(window.location.origin));
  }

  public getAnonymousCartId(): string {
    return this.cookie.get('victoria_anonymous_cart_id');
  }
  public setAnonymousCartId(cartId: string) {
    this.cookie.set('victoria_anonymous_cart_id', cartId, 180, '/', getCookieDomainByUrl(window.location.origin));
  }
  public deleteAnonymousCartId() {
    this.cookie.delete('victoria_anonymous_cart_id', '/', getCookieDomainByUrl(window.location.origin));
  }
  public getDismissHostessAreaOption(demoCode: string) {
    const cookie = this.cookie.get('hostess_area_dismissed_' + demoCode);
    return cookie && cookie != null;
  }

  public setDismissHostessAreaOption(demoCode: string) {
    this.cookie.set('hostess_area_dismissed_' + demoCode, 'true', 180, '/', getCookieDomainByUrl(window.location.origin));
  }
}

type WishlistAnonymousData = { productIds: string[] };
export type DismissDemoAlert = { start: boolean; end: boolean };
