import { inject, Injectable, signal } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import {
  AttendeeRole,
  CartResponse,
  DemoAttendeeOverviewResponse,
  DemoAttendeeResponse,
  DemoHostessGiftsResponse,
  DemoHostessSummaryResponse,
  DemoResponse,
  DemoSummaryResponse,
  WishlistResponse,
} from '@victoria-company/agora-client';
import { UserStore } from '../../../stores/user.store';
import { ActivityNotification } from '../../types/activityNotification.type';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private hubConnection: HubConnection;
  private currentDemoWatched?: string = null;
  private anonymousCartWached?: string = null;
  private userStore = inject(UserStore);
  public connectionState = signal<HubConnectionState>(HubConnectionState.Disconnected);

  constructor(private authService: AuthService) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.api_url + '/hubs/agora', {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => firstValueFrom(this.authService.getTokenFromOIDC()),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: context => (context.previousRetryCount < 5 ? context.previousRetryCount * 2000 : 10000),
      })
      .configureLogging(LogLevel.Information)
      .build();

    //TODO : VERIFY VALIDITY OF THIS
    this.hubConnection.keepAliveIntervalInMilliseconds = 29000;
    this.hubConnection.serverTimeoutInMilliseconds = 59000;
  }

  async listenForUserUpdates(handlers: {
    onCartCreated: (cart: CartResponse) => Promise<void>;
    onCartChange: (cart: CartResponse) => Promise<void>;
    onWishlistChange: (wishlist: WishlistResponse) => Promise<void>;
    onUserDemoHostessGiftsChanged: (demoId: string, gifts: DemoHostessGiftsResponse) => Promise<any>;
    onUserActivityOccurred: (activity: ActivityNotification) => Promise<void>;
    onReconnected: () => Promise<any>;
  }) {
    //Handle disconnected state behavior
    await this.handleHubConnectionState();

    //Invoke ListenFor only if hub is in effective connected state
    if (this.hubConnection.state == 'Connected') {
      console.log('listenForUserUpdates Hub is now connected');
      await this.hubConnection.invoke('ListenForUserUpdates');
    } else {
      console.log('listenForUserUpdates Hub is not in connected state : ', this.hubConnection.state);
      console.log('trying to re-execute listener');
      await this.listenForUserUpdates(handlers);
      return;
    }
    this.hubConnection.on('CartCreated', cart => {
      console.log('Cart created : ', cart);

      if (this.userStore.profile()?.id == cart.userId) handlers.onCartCreated(cart);
    });
    this.hubConnection.on('CartUpdated', cart => {
      console.log('Cart changes : ', cart);
      if (this.userStore.profile()?.id == cart.userId && cart.id) handlers.onCartChange(cart);
    });
    this.hubConnection.on('WishlistUpdated', (_userId, wishlist) => {
      console.log('Wishlist updated  : ', _userId, wishlist);

      if (this.userStore.profile()?.id == _userId) handlers.onWishlistChange(wishlist);
    });
    this.hubConnection.on('UserDemoHostessGiftsChanged', async (demoId, gifts) => {
      await handlers.onUserDemoHostessGiftsChanged(demoId, gifts);
    });
    this.hubConnection.on('UserActivityOccurred', async (activity: ActivityNotification) => {
      await handlers.onUserActivityOccurred(activity);
    });
    this.hubConnection.onreconnected(async () => {
      console.log('ListenForUserUpdates Hub is now re-connected');
      await this.hubConnection.invoke('ListenForUserUpdates');
      await handlers.onReconnected();
    });
  }

  async listenForValidationCartUpdates(
    cartId: string,
    handlers: {
      onDemoAttendeeCartChange: (cart: CartResponse) => any;
    }
  ) {
    //Handle disconnected state behavior
    await this.handleHubConnectionState();

    this.hubConnection.on('DemoAttendeeCartUpdated', cart => {
      if (cart.id != cartId) return;

      handlers.onDemoAttendeeCartChange(cart);
    });
  }

  async listenForAnonymousCartUpdates(
    cartId: string,
    handlers: {
      onCartChange: (cart: CartResponse) => Promise<void>;
      onReconnected: () => Promise<any>;
    }
  ) {
    //Handle disconnected state behavior
    await this.handleHubConnectionState();

    this.anonymousCartWached = cartId;

    //Invoke ListenFor only if hub is in effective connected state
    if (this.hubConnection.state == 'Connected') {
      console.log('listenForCartUpdates Hub is now connected');
      await this.hubConnection.invoke('ListenForCartUpdates', this.anonymousCartWached);
    } else {
      console.log('listenForCartUpdates Hub is not in connected state : ', this.hubConnection.state);
    }

    this.hubConnection.on('CartUpdated', cart => {
      console.log('Anonymous user cart updated');
      handlers.onCartChange(cart);
    });
    this.hubConnection.onreconnected(async () => {
      console.log('listenForCartUpdates Hub is now re-connected');
      await this.hubConnection.invoke('ListenForCartUpdates', this.anonymousCartWached);
      await handlers.onReconnected();
    });
  }

  async listenForDemoUpdates(
    demoId: string,
    handlers: {
      onDemoAttendeeCartRemovedFromDemo: (demoId: string, userId: string, cartId: string) => any;
      onDemoAttendeeCartChange: (cart: CartResponse) => any;
      onDemoAttendeeCartCreated: (cart: CartResponse) => any;
      onDemoAttendeeWishlistChange: (userId: string, wishlist: WishlistResponse) => any;
      onDemoAttendeeChange: (demoAttendeeChanged: {
        couldBecomeDelegate?: boolean;
        couldBecomeHostess?: boolean;
        willAttend?: boolean;
        requestedBecomeDelegate?: boolean;
        requestedBecomeHostess?: boolean;
        requestedToHostDemoOn?: Date;
        role?: AttendeeRole;
        userId: string;
        demoId: string;
      }) => any;
      onDemoAttendeeJoined: (demoAttendee: DemoAttendeeResponse) => any;
      onDemoChange: (demo: DemoResponse) => any;
      onDemoHostessAttendeeJoined: (demoAttendee: DemoAttendeeOverviewResponse) => any;
      onDemoHostessGiftsChanged: (demoId: string, gifs: DemoHostessGiftsResponse) => any;
      onDemoHostessSummaryChanged: (demoId: string, hostessSummary: DemoHostessSummaryResponse) => any;
      onDemoSummaryChanged: (demoId: string, summary: DemoSummaryResponse) => any;
      onDemoActivityOccurred: (activity: ActivityNotification) => Promise<any>;
      onReconnected: () => any;
    }
  ) {
    //Handle disconnected state behavior
    await this.handleHubConnectionState();

    this.currentDemoWatched = demoId;
    console.log('watching demo updates');

    //Invoke Listing only if hub is in effective connected state
    if (this.hubConnection.state == 'Connected') {
      console.log('listenForDemoUpdates Hub is now connected');
      await this.hubConnection.invoke('ListenForDemoUpdates', this.currentDemoWatched);
    } else {
      console.log('listenForDemoUpdates Hub is not in connected state : ', this.hubConnection.state);
    }

    this.hubConnection.on('DemoAttendeeCartRemovedFromDemo', (demoId, userId, cartId) => {
      console.log('Demo Attendee Cart Removed : ', demoId, userId, cartId);
      handlers.onDemoAttendeeCartRemovedFromDemo(demoId, userId, cartId);
    });
    this.hubConnection.on('DemoAttendeeCartUpdated', cart => {
      console.log('Demo Attendee cart Updated : ', cart);
      handlers.onDemoAttendeeCartChange(cart);
    });
    this.hubConnection.on('DemoAttendeeCartCreated', cart => {
      console.log('Demo Attendee cart Created : ', cart);
      handlers.onDemoAttendeeCartCreated(cart);
    });
    this.hubConnection.on('DemoAttendeeWishlistUpdated', (userId, wishlist) => {
      console.log('Demo Attendee Wishlist Updated : ', userId, wishlist);
      handlers.onDemoAttendeeWishlistChange(userId, wishlist);
    });
    this.hubConnection.on('DemoAttendeeUpdated', demoAttendeeHubResponse => {
      console.log('Demo attendee updated : ', demoAttendeeHubResponse);
      handlers.onDemoAttendeeChange(demoAttendeeHubResponse);
    });
    this.hubConnection.on('DemoAttendeeJoined', demoAttendeeResponse => {
      console.log('Demo attendee joined : ', demoAttendeeResponse);
      handlers.onDemoAttendeeJoined(demoAttendeeResponse);
    });
    this.hubConnection.on('DemoUpdated', demoResponse => {
      console.log('Demo updated : ', demoResponse);
      handlers.onDemoChange(demoResponse);
    });
    this.hubConnection.on('DemoHostessAttendeeJoined', demoAttendeeResponse => {
      console.log('Demo attendee joined : ', demoAttendeeResponse);
      handlers.onDemoHostessAttendeeJoined(demoAttendeeResponse);
    });
    this.hubConnection.on('DemoHostessGiftsChanged', async (demoId, gifts) => {
      await handlers.onDemoHostessGiftsChanged(demoId, gifts);
    });
    this.hubConnection.on('DemoHostessSummaryChanged', (demoId, hostessSummary) => {
      console.log('hostess Summary changed : ', hostessSummary);
      handlers.onDemoHostessSummaryChanged(demoId, hostessSummary);
    });
    this.hubConnection.on('DemoSummaryChanged', (demoId, summary) => {
      console.log('DemoSummaryChanged : ', summary);
      handlers.onDemoSummaryChanged(demoId, summary);
    });
    this.hubConnection.on('DemoActivityOccurred', (activity: ActivityNotification) => {
      console.log('DemoActivityOccurred : ', activity);
      handlers.onDemoActivityOccurred(activity);
    });
    this.hubConnection.onreconnected(async () => {
      console.log('listenForDemoUpdates Hub is now re-connected');
      await this.hubConnection.invoke('ListenForDemoUpdates', this.currentDemoWatched);
      handlers.onReconnected();
    });
  }

  async switchListenForDemoUpdates(demoId: string) {
    if (this.hubConnection.state == 'Connected') {
      await this.hubConnection.invoke('UnsubscribeFromDemoUpdates', this.currentDemoWatched);
      await this.hubConnection.invoke('ListenForDemoUpdates', demoId);
    }
    this.currentDemoWatched = demoId;
  }

  private async handleHubConnectionState() {
    if (this.hubConnection.state == 'Disconnected') {
      return this.hubConnection.start().finally(() => {
        this.connectionState.set(this.hubConnection.state);
        console.log('HubConnection state : ', this.connectionState());
      });
    } else {
      this.connectionState.set(this.hubConnection.state);
      console.log('HubConnection state : ', this.connectionState());
    }
  }
}
