import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ResourcesStore } from '../../../../../stores/resources.store';
import { ContextStore } from '../../../../../stores/context.store';

@Component({
  selector: 'app-size-guide-modal',
  templateUrl: './size-guide-modal.component.html',
  styleUrls: ['./size-guide-modal.component.scss'],
})
export class SizeGuideModalComponent {
  @Input() opened = false;
  @Output() requestCloseModal = new EventEmitter();
  isClosable = true;
  selectedTab = '0';

  resourcesStore = inject(ResourcesStore);
  contextStore = inject(ContextStore);

  switchTab(tab: string) {
    this.selectedTab = tab;
  }

  close() {
    this.requestCloseModal.emit();
  }
}
