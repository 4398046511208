import { NgModule } from '@angular/core';
import { RemoveHtmlTag } from './remove-html-tag.pipe';
import { CommonModule } from '@angular/common';
import { TranslateWithParam } from './translate-with-param.pipe';
import { UpperFirstCase } from './upper-first-case.pipe';
import { Youtube } from './youtube.pipe';
import { AgoraDate, AgoraDateTime, AgoraLocalDateTime, AgoraTextDate, AgoraTime, AgoraDateTimeUTC, AgoraDateUTC, AgoraTimeUTC } from './agoraDate.pipe';
import { AgoraCurrency, AgoraCurrencyInt } from './agoraCurrency.pipe';
@NgModule({
  declarations: [
    RemoveHtmlTag,
    TranslateWithParam,
    UpperFirstCase,
    Youtube,
    AgoraDate,
    AgoraDateTime,
    AgoraLocalDateTime,
    AgoraTime,
    AgoraTextDate,
    AgoraCurrency,
    AgoraCurrencyInt,
    AgoraDateTimeUTC,
    AgoraDateUTC,
    AgoraTimeUTC,
  ],
  imports: [CommonModule],
  exports: [
    RemoveHtmlTag,
    TranslateWithParam,
    UpperFirstCase,
    Youtube,
    AgoraDate,
    AgoraDateTime,
    AgoraLocalDateTime,
    AgoraTime,
    AgoraTextDate,
    AgoraCurrency,
    AgoraCurrencyInt,
    AgoraDateTimeUTC,
    AgoraDateUTC,
    AgoraTimeUTC,
  ],
})
export class PipeModule {}
