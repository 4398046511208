import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProgressionStep } from '../../../core/types/size-dropdown.type';
import { CheckoutOrValidationProgressionStep } from '../../../core/enums/checkoutOrValidationProgressionStep.enum';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss'],
})
export class FormStepperComponent {
  @Input() steps: ProgressionStep[];
  @Input() currentStep: CheckoutOrValidationProgressionStep;
  @Input() isDemo = false;

  @Output() requestNavigateToStep: EventEmitter<CheckoutOrValidationProgressionStep> = new EventEmitter();

  navigateToStep(item: ProgressionStep) {
    if (item.canNavigate) {
      this.requestNavigateToStep.emit(item.step);
    }
  }
}
