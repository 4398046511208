import { Component, inject, Input } from '@angular/core';
import { ResourcesStore } from '../../../../stores/resources.store';
import { GetClubInformationResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
})
export class WalletCardComponent {
  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore)

  @Input() clubInformation:GetClubInformationResponse;
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  @Input() showWalletButton: boolean;
  @Input() paddingBottom = true;



}
