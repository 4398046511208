<div class="order-container" *ngIf="resourcesStore.isLoaded() && cart && (payment || cart.status == 'Finalized' || cart.status == 'Confirmed')">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle"> </app-page-title>
  <div class="frame-container flex j-center ai-start">
    <app-order-payment-status
      *ngIf="cart.status == 'Confirmed' || (payment && payment?.status == 'Paid')"
      [paymentType]="cart.totalToPay == 0 ? undefined : payment?.type"
      [cart]="cart"
      [email]="userStore.profile()?.email"></app-order-payment-status>
    <app-order-payment-pending *ngIf="cart.status != 'Confirmed' && payment?.status != 'Paid'"></app-order-payment-pending>
  </div>
</div>
