import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserStore } from '../../../../../stores/user.store';

@Component({
  selector: 'app-wallet-data-wrapper',
  templateUrl: './wallet-data-wrapper.component.html',
  styleUrls: ['./wallet-data-wrapper.component.scss'],
})
export class WalletDataWrapperComponent implements OnInit {

  readonly userStore = inject(UserStore)
  private formBuilder = inject(UntypedFormBuilder)

  formFilter: UntypedFormGroup;
  loaded = false;
  loadedMore = false;


  async ngOnInit() {
    this.formFilter = this.formBuilder.group({
      showUsed: [this.userStore.clubVouchers()?.getUsed],
    });

    this.formFilter.controls.showUsed.valueChanges.subscribe(async value => {
      await this.userStore.getVouchers(0, value)
    });

    await this.userStore.getVouchers();
    this.loaded = true;

  }

  async getMoreDatas() {
    this.loadedMore = true;
    await this.userStore.getVouchers(this.userStore.clubVouchers()?.pageIndex, this.userStore.clubVouchers()?.getUsed)
    this.loadedMore = false;
  }
}
