import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage.service';
import { ContextStore } from '../../../../../../stores/context.store';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { CartStore } from '../../../../../../stores/cart.store';

@Component({
  selector: 'app-nocart',
  templateUrl: './nocart.component.html',
  styleUrls: ['./nocart.component.scss'],
})
export class NocartComponent {
  @Input() isAuthenticated: boolean;

  public resourcesStore = inject(ResourcesStore);
  public contextStore = inject(ContextStore);
  public cartStore = inject(CartStore);

  constructor(
    private router: Router,
    private storage: StorageService,
  ) {}

  goToCollection() {
    if (this.isAuthenticated && this.cartStore.demo()) {
      return `/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`;
    } else return this.resourcesStore.getEshopCollectionLink();
  }

  async goToAuthentication() {
    this.storage.redirectTo = '/' + this.contextStore.locale() + '/public/cart';
    await this.router.navigateByUrl('/' + this.contextStore.locale());
  }
}
