import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  public log(message: string) {
    if (!environment.production) console.log(message);
  }

  public logData(message: string, ...value: any) {
    if (!environment.production) console.log(message, value);
  }

  public warn(message: string) {
    if (!environment.production) console.warn(message);
  }

  public warnData(message: string, ...value: any) {
    if (!environment.production) console.warn(message, value);
  }

  public err(message: string) {
    if (!environment.production) console.error(message);
  }

  public errData(message: string, ...value: any) {
    if (!environment.production) console.error(message, value);
  }
}
