import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

if (environment.datadog_active) {
  datadogRum.init({
    applicationId: '8ccf810a-5485-404f-b72b-b223b8dcba94',
    clientToken: environment.datadog_client_token,
    site: 'datadoghq.eu',
    service: 'eshop',
    env: environment.datadog_env,
    version: environment.version,
    traceSampleRate: 100,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    telemetrySampleRate: 100,
    silentMultipleInit: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

