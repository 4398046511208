import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private api: ApiService) {}

  public getProductByIdOrReference(contextId: number, locale: string, idOrReference: string) {
    const client = this.api.getClientInstance();
    return client.api.catalog.byContextId(contextId).byLanguageCode(locale).products.byIdOrReference(idOrReference).get();
  }

  public getProducts(contextId: number, locale: string, productIds: string[]) {
    return this.api.getClient().pipe(
      filter(client => productIds && productIds.length > 0),
      switchMap(client =>
        from(
          client.api.catalog
            .byContextId(contextId)
            .byLanguageCode(locale)
            .products.get({ queryParameters: { productIds: productIds.join(',') } })
        )
      )
    );
  }

  public getProductsPromise(contextId: number, locale: string, productIds: string[]) {
    const client = this.api.getClientInstance();
    if (productIds && productIds.length > 0) {
      return client.api.catalog
        .byContextId(contextId)
        .byLanguageCode(locale)
        .products.get({ queryParameters: { productIds: productIds.join(',') } });
    }
  }

  public getProductsByVariantIdsPromise(contextId: number, locale: string, productVariantIds: string[]) {
    const client = this.api.getClientInstance();
    if (productVariantIds && productVariantIds.length > 0) {
      return client.api.catalog
        .byContextId(contextId)
        .byLanguageCode(locale)
        .products.get({ queryParameters: { productVariantIds: productVariantIds.join(',') } });
    } else return null;
  }

  public getProductsByVariantIds(contextId: number, locale: string, productVariantIds: string[]) {
    return this.api.getClient().pipe(
      filter(client => productVariantIds && productVariantIds.length > 0),
      switchMap(client =>
        from(
          client.api.catalog
            .byContextId(contextId)
            .byLanguageCode(locale)
            .products.get({ queryParameters: { productVariantIds: productVariantIds.join(',') } })
        )
      )
    );
  }
}
