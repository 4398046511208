<div class="cart-recap-info-container" *ngIf="cartStore.isLoaded()">
  <div class="cart-recap-head">
    <div class="heading-block">
      {{ resources.shoppingBag.label }}
    </div>
    <div
      class="cart-validation-waiting waiting"
      *ngIf="!cartStore.cart().isHostessCart && cartStore.cart()?.approval?.required && !cartStore.cart()?.approval.requested && !cartStore.cart()?.approval.approvedByDelegate">
      <div class="infos-icon">
        <svg>
          <use xlink:href="#icon-help-info-solid"></use>
        </svg>
      </div>
      <p class="p-s">
        {{ 'DEMO.TUNNEL.VALIDATION.ACTIVE' | translate }}
        <br />
        {{ 'DEMO.TUNNEL.INFO.SENDCART' | translate }}
      </p>
    </div>
    <div
      class="cart-validation-waiting pending"
      *ngIf="!cartStore.cart().isHostessCart && cartStore.cart()?.approval?.required && cartStore.cart()?.approval.requested && !cartStore.cart()?.approval.approvedByDelegate">
      <div class="valid-icon">
        <svg>
          <use xlink:href="#icon-check"></use>
        </svg>
      </div>
      <p class="p-s">{{ 'DEMO.TUNNEL.INFO.CARTSENT' | translate: { X: cartStore.cart().approval.requestedOn | date: 'short' } }}</p>
    </div>
    <div class="cart-validation-waiting valid" *ngIf="!cartStore.cart().isHostessCart && cartStore.cart()?.approval?.required && cartStore.cart()?.approval.approvedByDelegate">
      <!--    <div class="cart-validation-waiting valid" *ngIf="this.demo?.mustValidateCart && isDemoCart && demoCode && isCartValid && cartState.cart?.flowStatuses?.payment?.status != 11">-->
      <div class="valid-icon">
        <svg>
          <use xlink:href="#icon-check"></use>
        </svg>
      </div>
      <p class="p-s">
        {{ 'DEMO.TUNNEL.VALIDATION.DONE' | translate }}
      </p>
    </div>

    <div
      class="recap-description"
      *ngIf="!cartStore.cart().isHostessCart && cartStore.cart()?.approval?.required && cartStore.cart()?.approval?.requested && cartStore.cart()?.approval?.approvedByDelegate">
      <p>{{ resources.shoppingBag.text | removeHtmlTag }}</p>
    </div>
    <!-- <div class="recap-description" *ngIf="isDemoCart &&  demoCode && isCartValidationWaiting && !isCartValid">
      <p>{{'DEMO.TUNNEL.INFO.SENDCART' | translate}}</p>
    </div> -->
  </div>
  <div class="cart-recap-vouchers">
    <p>{{ 'CART.SUMMARY.REDUCTIONSCOMESNEXT' | translate }}</p>
  </div>
  <div class="cart-recap-info flex column">
    <div class="recap-row flex row j-space-between">
      <div *ngIf="!cartStore.cart()?.isHostessCart">{{ resources.shoppingBag.subtotal }} :</div>
      <div *ngIf="cartStore.cart()?.isHostessCart">{{ 'DEMO.SUMMARY.HOSTESSCART' | translate }}</div>
      <div class="amount right">{{ cartStore.cart()?.total | agoraCurrency }}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="cartStore.cart()?.cartVouchers?.length > 0">
      <div>{{ resources.shoppingBag.birthdayLabelError }} :</div>
      <div class="amount right">- {{ cartStore.cart()?.vouchersTotal | agoraCurrency }}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="cartStore.cart()?.walletAmount > 0">
      <div>{{ 'CART.SUMMARY.TOTALWALLET' | translate }}</div>
      <div class="amount right">- {{ cartStore.cart()?.walletAmount | agoraCurrency }}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="!cartStore.cart()?.isHostessCart">
      <div>{{ resources.shoppingBag.shippingLabel }} :</div>
      <div *ngIf="cartStore.cart()?.delivery?.type != deliveryOptions.ToCustomer">
        <a (click)="openShippingInfoModal()">{{ resources.shoppingBag.shippingButtonLabel }}</a>
      </div>
      <div *ngIf="cartStore.cart()?.delivery?.type == deliveryOptions.ToCustomer">
        <a (click)="openShippingInfoModal()">{{ cartStore.cart()?.deliveryFee | agoraCurrency }}</a>
      </div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="cartStore.cart() && cartStore.cart().isHostessCart && cartStore.cart().total != cartStore.cart()?.subTotal">
      <div>{{ resources.shoppingBag.categoriesLabel }} :</div>
      <div class="amount right">{{ cartStore.cart()?.subTotal | agoraCurrency }}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="cartStore.cart()?.isHostessCart">
      <div>{{ 'DEMO.SUMMARY.BONUS' | translate }}</div>
      <div class="amount right">{{ -cartStore.cart()?.hostessBonus | agoraCurrency }}</div>
    </div>
    <div class="recap-row flex row j-space-between available-amount" *ngIf="cartStore.cart()?.isHostessCart && hasBonusRemaining()">
      <div>{{ 'DEMO.SUMMARY.AMOUNT.AVAILABLE' | translate }}</div>
      <div class="amount right">
        {{ cartStore.cart().hostessBonus - cartStore.cart().subTotal | agoraCurrency }}
      </div>
    </div>
    <div class="recap-row flex row j-space-between available-amount" *ngIf="cartStore.cart()?.isHostessCart && cartStore.cart()?.totalToPay > 0">
      <div>{{ 'DEMO.SUMMARY.AMOUNT.TOPAY' | translate }}</div>
      <div class="amount right">
        {{ cartStore.cart().totalToPay | agoraCurrency }}
      </div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="cartStore.cart() && !cartStore.cart()?.isHostessCart">
      <div>{{ resources.shoppingBag.categoriesLabel }} :</div>
      <div class="amount right">{{ cartStore.cart()?.totalToPay | agoraCurrency }}</div>
    </div>
    <div class="cta-container cart-actions">
      <button
        class="btn btn-primary secondary j-center"
        *ngIf="!cartStore.cart().isHostessCart && cartStore.cart()?.approval?.required && !cartStore.cart()?.approval?.requested && !cartStore.cart()?.approval.approvedByDelegate"
        (click)="requestApproval()"
        [disabled]="operationPending">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{ 'DEMO.TUNNEL.ACTION.SENDCART' | translate }}</label>
      </button>

      <button *ngIf="cartStore.cart()?.isHostessCart && hasBonusRemaining()" (click)="requestContinueShopping()">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{ 'CART.CONTINUE.SHOPPING' | translate }}</label>
      </button>

      <button *ngIf="!cartStore.cart()?.isHostessCart" (click)="validateOrder()" [disabled]="cartStore.cart()?.approval?.required && !cartStore.cart()?.approval.approvedByDelegate">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{ resources.shoppingBag.checkoutButtonLabel }}</label>
      </button>
      <p *ngIf="cartStore.cart()?.isHostessCart" class="cart-validation-message">
        {{ "HOSTESS.CART.VALIDATIONBYDELEGATE" | translate }}
      </p>
    </div>

    <p *ngIf="cartStore.cart()?.totalToPay <= 0 && cartStore.itemsCount() > 0 && cartStore.cart()?.vouchersTotal - cartStore.cart()?.total > 0">
      {{ resources.checkoutPayment.addressLabel2 | translateWihParam: { surplus: (cartStore.cart()?.vouchersTotal - cartStore.cart()?.total | agoraCurrency) } }}
    </p>

    <div *ngIf="!cartStore.cart().isHostessCart" class="flex ai-center j-center cart-recap-providers">
      <div class="provider" *ngFor="let item of paymentMethods?.paymentMethods">
        <img loading="lazy" [src]="item.id == 'lyfpay' ? '/assets/images/cb.svg' : item.image" [alt]="item.description" />
      </div>
    </div>
  </div>
</div>
