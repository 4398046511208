import { Component, inject } from '@angular/core';
import { UserStore } from '../../../../stores/user.store';

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.scss'],
})
export class DelegateComponent {
  readonly userStore = inject(UserStore);
}
