<button [ngClass]="classes" [disabled]="disabled" (click)="clicked()">
  <svg *ngIf="leftIcon" [ngClass]="iconClasses" viewBox="0 0 32 32">
    <use [attr.xlink:href]="leftIcon"></use>
  </svg>
  <label *ngIf="label">{{ label }}</label>
  <svg *ngIf="rightIcon" [ngClass]="iconClasses" viewBox="0 0 32 32">
    <use [attr.xlink:href]="rightIcon"></use>
  </svg>
  <div *ngIf="showLoader && !rightIcon" class="loader loader-mini"></div>
</button>
