import { signalStore, withMethods } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { AlertService } from '../core/services/alert.service';
import { inject } from '@angular/core';
import { Alert } from '../core/models/alert.model';
import { NotificationIcon, NotificationStyle } from '../core/types/notification.options.type';

export const NotificationStore = signalStore(
  { providedIn: 'root' },
  withDevtools('notification'),
  withMethods((store, alertService = inject(AlertService)) => ({
    notify(params: { title?: string; message?: string; icon?: string; style?: string }): void {
      const popup = new Alert();
      popup.message = params.message;
      popup.title = params.title;
      popup.type = params.style ?? NotificationStyle.DANGER;
      popup.icon = params.icon ?? NotificationIcon.WARNING;
      popup.duration = 6000;

      alertService.raiseAlert(popup);
    },
  }))
);
