import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { firstValueFrom, from } from 'rxjs';
import { UpdateUserProfileRequest } from '@victoria-company/agora-client';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private api: ApiService,
    private http: HttpClient
  ) {}

  public async updateProfile(updateUserProfileRequest: UpdateUserProfileRequest) {
    const client = this.api.getClientInstance();
    return client.api.user.profile.put(updateUserProfileRequest);
  }

  public async updateUserProfile(userId: string, updateUserProfileRequest: UpdateUserProfileRequest) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserId(userId).profile.put(updateUserProfileRequest);
  }

  public getProfileByUserCode(userCode: string) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserId(userCode).profile.get();
  }

  public getUserInfoByCode(userCode: string) {
    const client = this.api.getClientInstance();
    return from(client.api.invitationCodes.byCode(userCode).user.get());
  }

  public async getProfile() {
    const client = this.api.getClientInstance();
    return client.api.user.profile.get();
  }

  public async getClubInformation() {
    const client = this.api.getClientInstance();
    return client.api.user.clubInformation.get();
  }

  public async getUserCode() {
    const client = this.api.getClientInstance();
    return client.api.user.invitationCode.get();
  }

  public async getVouchers(pageIndex: number, pageSize: number, includeUsed: boolean) {
    const client = this.api.getClientInstance();
    return client.api.user.vouchers.get({ queryParameters: { pageIndex: pageIndex, pageSize: pageSize, includeUsed: includeUsed } });
  }

  public async downloadVoucher(voucherCode: string, locale: string) {
    const client = this.api.getClientInstance();
    return client.api.user.vouchers.byVoucherCode(voucherCode).download.byLocale(locale).get();
  }

  public async updateUserEmail(email: string) {
    const client = this.api.getClientInstance();
    return client.api.user.email.put({ email });
  }

  async requestStartSSOForgotPasswordFlow(email: string) {
    return firstValueFrom(this.http.post(environment.identity_url + 'api/user/forgot-password', { loginId: email, applicationId: '1ba11db4-a6ee-43d7-a896-a7dc27c81e8e' }));
  }
}
