<div class="order-paid flex column j-start ai-center ac-center" *ngIf="resourcesStore.isLoaded() && cart">
  <h1 class="canela center">{{ resourcesStore.i18n().resources.checkoutConfirmation.secondTitle }}</h1>
  <p class="m-center" *ngIf="(paymentType === PaymentTypeObject.Digital || !paymentType) && email">
    {{ resourcesStore.i18n().resources.checkoutConfirmation.introText | translateWihParam: { email } }}
  </p>
  <p class="m-center" *ngIf="(paymentType === PaymentTypeObject.Digital || !paymentType) && !email">{{ 'ORDER.CONFIRMATION.EMAILINFO' | translate }}</p>
  <p class="m-center">
    {{
      translate.getTranslationMultiple(
        resourcesStore.i18n().resources.checkoutConfirmation.description | removeHtmlTag,
        'ORDER.VALIDATION.CLIENT.TODELEGATE',
        paymentType === PaymentTypeObject.HandledByDelegate,
        undefined,
        undefined,
        true
      )
    }}
  </p>
  <div class="flex j-center rowCTA">
    <app-wavedlink
      *ngIf="paymentType === PaymentTypeObject.Digital || !paymentType"
      [linkText]="resourcesStore.i18n().resources.checkoutConfirmation.buttonLabel"
      [externalUrl]="getLogoUrl()"></app-wavedlink>
    <app-wavedlink
      *ngIf="((!demo || demo.status != 'Opened') && userStore.isAuthenticated() && paymentType === PaymentTypeObject.Digital) || !paymentType"
      [linkText]="'MENU.ORDERS.ORDERS' | translate"
      [linkUrl]="'/' + contextStore.locale() + '/club/orders'"></app-wavedlink>
    <app-wavedlink
      *ngIf="demo && demo.status == 'Opened'"
      [linkText]="'GLOBAL.ACTION.BACKTODEMO' | translate"
      [linkUrl]="'/' + contextStore.locale() + '/myvictoria/demo/' + cart.demoId"></app-wavedlink>
  </div>
</div>

<app-modal-become-hostess-delegate [cart]="cart" />
