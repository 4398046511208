import { inject, Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { getCookieDomainByUrl } from '../utils/filter.utils';
import { StorageService } from './storage.service';

import jwtDecode from 'jwt-decode';
import { UserStore } from '../../stores/user.store';

const cookieName = 'victoria_jwt_token';
// const cookieSSOName: string = "victoria_jwt_token_sso";

@Injectable({
  providedIn: 'root',
})
export class JWTService implements OnDestroy {
  private userStore = inject(UserStore);
  public initialized = new BehaviorSubject<boolean>(false);

  private _hasDelegateRole$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasDelegateRole$: Observable<boolean> = this._hasDelegateRole$.asObservable();

  public _connectedAsDelegate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public connectedAsDelegate$: Observable<boolean> = this._connectedAsDelegate$.asObservable();

  //INTERNAL
  private _token$ = new BehaviorSubject<string>(null);
  public token$ = this._token$.asObservable();

  private _userData$ = new BehaviorSubject<any>(undefined);
  public userData$ = this._userData$.asObservable();

  private _isAuthenticated$ = new BehaviorSubject<boolean>(undefined);
  public isAuthenticated$ = this._isAuthenticated$.asObservable();

  private subscriptions: Subscription[] = [];

  constructor(
    private cookie: CookieService,
    private oidc: OidcSecurityService,
    public storage: StorageService
  ) {
    this.subscriptions.push(
      this.oidc.checkAuth(window.origin).subscribe(loginResponse => {
        this.userStore.setIsAuthenticated(loginResponse.isAuthenticated);
      })
    );

    this.subscriptions.push(
      this.oidc.isAuthenticated$.subscribe(authResult => {
        this.init(authResult.isAuthenticated);
      })
    );
  }

  public getTokenFromOIDC() {
    return this.oidc.getAccessToken();
  }

  public init(isAuthenticated: boolean) {
    this.setToken();
    this.setIsAuthenticated(isAuthenticated);

    if (!isAuthenticated) {
      this.deleteTokenCookie();
      this.resetRoles();
    } else {
      this.initConnectedUserRoles();
      this.oidc.getAuthenticationResult().subscribe(result => {
        if (result) {
          const expirationDate = new Date();
          expirationDate.setSeconds(expirationDate.getSeconds() + result.expires_in);

          this.setTokenCookie(undefined, expirationDate);
        } else {
          this.deleteTokenCookie();
        }
      });
    }

    this._isAuthenticated$.next(isAuthenticated);
  }

  //AUTHENTICATION CHECK
  public isAuthenticated() {
    return this._isAuthenticated$.value;
  }

  private setIsAuthenticated(isAuthenticated) {
    this._isAuthenticated$.next(isAuthenticated);
  }

  //SWITCH ENVIRONNEMENT Config
  public initConnectedUserRoles() {
    if (this.isAuthenticated()) {
      const roles = this.getRolesFromToken();
      if (roles.includes('r_delegate')) {
        this._hasDelegateRole$.next(true);

        if (this.storage.currentRole) this._connectedAsDelegate$.next(this.storage.currentRole == '1');
        else this._connectedAsDelegate$.next(true);
      }
    }
  }

  public switchRole() {
    //1. Conseillère - 3. Cliente
    if (this._hasDelegateRole$.value === true) {
      const isDelegate = this._connectedAsDelegate$.value;

      if (isDelegate) this.storage.currentRole = '3';
      else this.storage.currentRole = '1';

      this._connectedAsDelegate$.next(!this._connectedAsDelegate$.value);
    } else this.storage.currentRole = '3';
  }

  public resetRoles() {
    this._hasDelegateRole$.next(false);
    this._connectedAsDelegate$.next(false);
  }

  //TOKEN MANIPULATION
  //GET

  public getToken() {
    return this._token$.getValue();
  }

  private setToken() {
    return this.oidc.getAccessToken().subscribe(token => this._token$.next(token));
  }

  public isDelegate() {
    const token = this.getToken();
    const decoded = this.decodeToken(token);
    if (decoded == null) return undefined;
    else {
      const roles = decoded.roles;
      return roles.includes('r_delegate');
    }
  }

  private getRolesFromToken() {
    const token = this.getToken();
    const decoded = this.decodeToken(token);
    if (decoded != null) return decoded.roles;
    else return [];
  }

  public getSubFromToken() {
    const token = this.getToken();
    const decoded = this.decodeToken(token);
    if (decoded != null) return decoded.sub;
    else return null;
  }

  public getEmailFromToken() {
    const token = this.getToken();
    const decoded = this.decodeToken(token);
    if (decoded != null) return decoded.email;
    else return null;
  }

  public getClientIdFromToken() {
    const token = this.getToken();
    const decoded = this.decodeToken(token);
    if (decoded != null) return decoded.client_id;
    else return null;
  }

  public getTokenCookieName() {
    return cookieName;
  }

  public hasTokenCookie() {
    return this.cookie.get(cookieName) !== '';
  }

  //SETcookieName
  public setTokenCookie(lang, duration: any = 1 / 24) {
    this.cookie.set(cookieName, this.getToken(), duration, '/', getCookieDomainByUrl(window.origin), true, 'Strict'); //former cookie domain get by lang -  23/5/23
  }

  //DELETE
  public deleteTokenCookie() {
    this.cookie.delete(cookieName, '/', getCookieDomainByUrl(window.origin)); ////former cookie domain get by context - 23/5/23
  }

  public deleteTokenCookieUsingLang(lang) {
    this.cookie.delete(cookieName, '/', getCookieDomainByUrl(window.origin)); //former cookie domain get by lang - 23/5/23
  }

  public cleanState() {
    localStorage.removeItem('desktop');
    localStorage.removeItem('ios');
    localStorage.removeItem('android');
  }

  public setLoggedOut() {
    this.setIsAuthenticated(false);
  }

  //UTILS
  private decodeToken(jwt: string) {
    let data = null;
    if (jwt && jwt != null) data = jwtDecode(jwt);
    return data;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
