import { Component, inject } from '@angular/core';
import { PaymentTypeObject } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../../../stores/resources.store';

@Component({
  selector: 'app-order-payment-pending',
  templateUrl: './order-payment-pending.component.html',
  styleUrls: ['./order-payment-pending.component.scss'],
})
export class OrderPaymentPendingComponent {
  public resourcesStore = inject(ResourcesStore);

  protected readonly PaymentTypeObject = PaymentTypeObject;
}
