import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-booster-objectif',
  templateUrl: './booster-objectif.component.html',
  styleUrls: ['./booster-objectif.component.scss'],
})
export class BoosterObjectifComponent implements OnInit {
  @Input() rank: number;
  @Input() text: string;
  @Input() bonus: string;

  constructor() {}

  ngOnInit() {}
}
