import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() title: string;
  @Input() width: string;
  @Input() isOpened: boolean;

  @Output() cancelClicked = new EventEmitter<void>();
  @Output() confirmClicked = new EventEmitter<void>();

  cancel() {
    this.cancelClicked.emit();
  }
  confirm() {
    this.confirmClicked.emit();
  }
}
