import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { MyaccesComponent } from './components/profil/ components/myacces/myacces.component';
import { MycommunicationsComponent } from './components/profil/ components/mycommunications/mycommunications.component';
import { MydatasComponent } from './components/profil/ components/mydatas/mydatas.component';
import { ProfilComponent } from './components/profil/profil.component';
import { StatusComponent } from './components/status/status.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { PointEncodingComponent } from './components/point-encoding/point-encoding.component';
import { DelegateComponent } from './components/delegate/delegate.component';
import { OrdersListComponent } from './components/order/orders-list/orders-list.component';
import { OrderComponent } from './components/order/order/order.component';
import { ClientCodeComponent } from './components/client-code/client-code.component';
import { BoosterComponent } from './components/booster/booster.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { StaffManagerComponent } from './components/staff-manager/staff-manager.component';
import { ManageDocumentsComponent } from './components/staff-manager/manage-documents/manage-documents.component';
import { DocumentsListingComponent } from './components/staff-manager/manage-documents/documents-listing/documents-listing.component';
import { EditDocumentComponent } from './components/staff-manager/manage-documents/edit-document/edit-document.component';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// showHeaderMobileOnly: true -- Allows you to switch header to header-light except for mobile
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: { breadcrumb: 'MENU.HOME', showBreadCrumb: false },
    component: HomeComponent,
  },
  {
    path: 'status',
    data: { breadcrumb: 'MENU.STATUS' },
    component: StatusComponent,
  },
  {
    path: 'wallet',
    data: { breadcrumb: 'MENU.WALLET' },
    component: WalletComponent,
  },
  {
    path: 'delegate',
    data: { breadcrumb: 'MENU.DELEGATE' },
    component: DelegateComponent,
  },
  {
    path: 'point-encoding',
    data: { breadcrumb: 'MENU.POINTENCODING' },
    component: PointEncodingComponent,
  },
  // {
  //   path: 'point-evolution',
  //   data: {breadcrumb: 'MENU.POINTS'},
  //   component: PointEvolutionComponent,
  // },
  {
    path: 'orders',
    data: { breadcrumb: 'MENU.ORDERS.ORDERS' },
    component: OrdersListComponent,
  },
  {
    path: 'orders/:value',
    data: { breadcrumb: 'MENU.ORDERS.ORDERS' },
    component: OrderComponent,
  },
  {
    path: 'referral',
    data: { breadcrumb: 'MENU.BOOSTER' },
    component: BoosterComponent,
  },
  {
    path: 'profile',
    data: { breadcrumb: 'MENU.PROFILE' },
    component: ProfilComponent,
    canActivateChild: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        redirectTo: 'data',
        pathMatch: 'full',
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.INFORMATIONS' },
        path: 'data',
        component: MydatasComponent,
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.ACCES' },
        path: 'access',
        component: MyaccesComponent,
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.COMMUNICATION' },
        path: 'communication',
        component: MycommunicationsComponent,
      },
    ],
  },
  {
    path: 'client-code',
    data: { breadcrumb: 'MENU.CLIENT_CODE' },
    component: ClientCodeComponent,
  },

  {
    path: 'manager',
    component: StaffManagerComponent,
    data: {
      breadcrumb: 'MENU.DEMO',
      showJumbotron: false,
      showNewsletter: false,
      showFooterMenu: false,
      showBreadCrumb: false,
    },
  },
  {
    path: 'manage/:category',
    component: ManageDocumentsComponent,
    children: [
      {
        path: '',
        component: DocumentsListingComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
      {
        path: ':action',
        component: EditDocumentComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
      {
        path: ':action/:documentId',
        component: EditDocumentComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      showBreadCrumb: false,
      showJumbotron: false,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
