<div class="tooltip ac-center ai-center flex">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="red-circle flex ai-center">
    <svg [attr.viewBox]="iconViewbox ?? '0 0 32 32'">
      <use [attr.xlink:href]="'#' + (icon ?? 'icon-info')"></use>
    </svg>
    <span *ngIf="text" [style.width]="width" [style.height]="height" [style.top]="top" [style.right]="right" [style.left]="left" [style.bottom]="bottom" class="tooltiptext {{ position }}">{{
      text
    }}</span>
  </div>
</div>
