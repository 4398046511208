import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'agoraDateTimeUTC' })
export class AgoraDateTimeUTC implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd/MM/yyyy - HH:mm'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    if (!stringDate.endsWith('+00:00')) return new DatePipe('fr-BE').transform(date, format);
    else {
      const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return new DatePipe('fr-BE').transform(utcDate, format);
    }
  }
}

@Pipe({ name: 'agoraDateUTC' })
export class AgoraDateUTC implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd/MM/yyyy'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    if (!stringDate.endsWith('+00:00')) return new DatePipe('fr-BE').transform(date, format);
    else {
      const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return new DatePipe('fr-BE').transform(utcDate, format);
    }
  }
}

@Pipe({ name: 'agoraTimeUTC' })
export class AgoraTimeUTC implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'HH:mm'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    if (!stringDate.endsWith('+00:00')) return new DatePipe('fr-BE').transform(date, format);
    else {
      const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return new DatePipe('fr-BE').transform(utcDate, format);
    }
  }
}

@Pipe({ name: 'agoraDateTime' })
export class AgoraDateTime implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd/MM/yyyy - HH:mm'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    date.setDate(date.getDate());
    return new DatePipe('fr-BE').transform(date, format);
  }
}

@Pipe({ name: 'agoraLocalDateTime' })
export class AgoraLocalDateTime implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd/MM/yyyy - HH:mm'): string {
    date = new Date(date); // Create date based on GMT 0 Formated Date
    return new DatePipe('fr-BE').transform(date, format);
  }
}

@Pipe({ name: 'agoraDate' })
export class AgoraDate implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd/MM/yyyy'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    date.setDate(date.getDate());
    return new DatePipe('fr-BE').transform(date, format);
  }
}

@Pipe({ name: 'agoraTextDate' })
export class AgoraTextDate implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'dd MMMM YYYY'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    date.setDate(date.getDate());
    return new DatePipe('fr-BE').transform(date, format);
  }
}

@Pipe({ name: 'agoraTime' })
export class AgoraTime implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'HH:mm'): string {
    let stringDate = date?.toString();

    if (!stringDate) return '';
    // if(!stringDate.endsWith("+00:00"))  stringDate = stringDate + "+00:00";
    date = new Date(stringDate); // Create date based on GMT 0 Formated Date

    date.setDate(date.getDate());
    return new DatePipe('fr-BE').transform(date, format);
  }
}
