import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorType } from '../enums/errorType.enum';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly _profileError$ = new BehaviorSubject<boolean>(false);
  public readonly profileError$ = this._profileError$.asObservable();

  private readonly _delegateProfileError$ = new BehaviorSubject<boolean>(false);
  public readonly delegateProfileError$ = this._delegateProfileError$.asObservable();

  private readonly _paymentInfoError$ = new BehaviorSubject<boolean>(false);
  public readonly paymentInfoError$ = this._paymentInfoError$.asObservable();

  private readonly _getOrderByIdError$ = new BehaviorSubject<boolean>(false);
  public readonly getOrderByIdError$ = this._getOrderByIdError$.asObservable();

  private readonly _getOrdersError$ = new BehaviorSubject<boolean>(false);
  public readonly getOrdersError$ = this._getOrdersError$.asObservable();

  private readonly _summaryError$ = new BehaviorSubject<boolean>(false);
  public readonly summaryError$ = this._summaryError$.asObservable();

  private readonly _lookupError$ = new BehaviorSubject<boolean>(false);
  public readonly lookupError$ = this._lookupError$.asObservable();

  private readonly _datatableError$ = new BehaviorSubject<boolean>(false);
  public readonly datatableError$ = this._datatableError$.asObservable();

  private readonly _clientActionsError$ = new BehaviorSubject<boolean>(false);
  public readonly clientActionsError$ = this._clientActionsError$.asObservable();

  private readonly _getVouchersError$ = new BehaviorSubject<boolean>(false);
  public readonly getVouchersError$ = this._getVouchersError$.asObservable();

  private readonly _getPlatesError$ = new BehaviorSubject<boolean>(false);
  public readonly getPlatesError$ = this._getPlatesError$.asObservable();

  private readonly _getProductsError$ = new BehaviorSubject<boolean>(false);
  public readonly getProductsError$ = this._getProductsError$.asObservable();

  private readonly _getDemoError$ = new BehaviorSubject<boolean>(false);
  public readonly getDemoError$ = this._getDemoError$.asObservable();

  public raiseError(errorType: ErrorType): void {
    switch (errorType) {
      case ErrorType.PROFILE:
        this._profileError$.next(true);
        break;
      case ErrorType.DELEGATEPROFILE:
        this._delegateProfileError$.next(true);
        break;
      case ErrorType.GETPAYMENTINFO:
        this._paymentInfoError$.next(true);
        break;
      case ErrorType.GETORDER:
        this._getOrderByIdError$.next(true);
        break;
      case ErrorType.SUMMARY:
        this._summaryError$.next(true);
        break;
      case ErrorType.LOOKUP:
        this._lookupError$.next(true);
        break;
      case ErrorType.DATATABLE:
        this._datatableError$.next(true);
        break;
      case ErrorType.GETORDERS:
        this._getOrdersError$.next(true);
        break;
      case ErrorType.CLIENTACTIONS:
        this._clientActionsError$.next(true);
        break;
      case ErrorType.VOUCHERS:
        this._getVouchersError$.next(true);
        break;
      case ErrorType.PLATES:
        this._getPlatesError$.next(true);
        break;
      case ErrorType.PRODUCTS:
        this._getProductsError$.next(true);
        break;
      case ErrorType.DEMO:
        this._getDemoError$.next(true);
        break;
    }
  }

  public dropError(errorType: ErrorType): void {
    switch (errorType) {
      case ErrorType.PROFILE:
        this._profileError$.next(false);
        break;
      case ErrorType.DELEGATEPROFILE:
        this._delegateProfileError$.next(false);
        break;
      case ErrorType.GETPAYMENTINFO:
        this._paymentInfoError$.next(false);
        break;
      case ErrorType.GETORDER:
        this._getOrderByIdError$.next(false);
        break;
      case ErrorType.SUMMARY:
        this._summaryError$.next(false);
        break;
      case ErrorType.LOOKUP:
        this._lookupError$.next(false);
        break;
      case ErrorType.DATATABLE:
        this._datatableError$.next(false);
        break;
      case ErrorType.GETORDERS:
        this._getOrdersError$.next(false);
        break;
      case ErrorType.CLIENTACTIONS:
        this._clientActionsError$.next(false);
        break;
      case ErrorType.VOUCHERS:
        this._getVouchersError$.next(false);
        break;
      case ErrorType.PLATES:
        this._getPlatesError$.next(false);
        break;
      case ErrorType.PRODUCTS:
        this._getProductsError$.next(false);
        break;
      case ErrorType.DEMO:
        this._getDemoError$.next(false);
        break;
    }
  }
}
