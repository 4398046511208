<div class="order-item flex" [ngClass]="border ? 'border' : ''" *ngIf="resourcesStore.isLoaded() && item">
  <div class="item-image flex">
    <figure class="magnify" (click)="zoomInImage()">
      <img loading="lazy" [src]="getDataService.getProductImagesPathForThumbs(item.imageFileName)" />
    </figure>
  </div>
  <div class="item-info flex column flex-3">
    <div class="item-name">{{ item.label }}</div>
    <div class="item-price">{{ item.price | agoraCurrency }}</div>
    <div class="item-specs flex column j-start">
      <div *ngIf="item.size">{{ resourcesStore.i18n().resources.profileOrders.sizesLabels }}: {{ item.size }}</div>
      <div *ngIf="item.linkModification && item.linkModification!=0">
        {{ 'ITEM.LINKS.LABEL' | translate }}:
        @if (item.linkModification == -1) {
        {{ 'ITEM.LINKS.ONELESS' | translate }}
        } @else if (item.linkModification < -1) {
        {{ 'ITEM.LINKS.XLESS' | translate: { X: Math.abs(item.linkModification) } }}
      } @else if (item.linkModification == 1) {
        {{ 'ITEM.LINKS.ONEMORE' | translate }}
      } @else {
        {{ 'ITEM.LINKS.XMORE' | translate: { X: item.linkModification } }}
      }
      </div>
    </div>
  </div>
</div>
