<div class="giftContainer flex column gap-15">
  <ng-container *ngIf="isModal || (!isModal && !locked)" [ngSwitch]="getRemainingGiftsToChoose()">
    <span *ngSwitchCase="1">{{ 'DEMO.GIFTS.ONELEFT' | translate }}</span>
    <span *ngSwitchCase="0">{{ 'DEMO.GIFTS.NONELEFT' | translate }}</span>
    <span *ngSwitchDefault>{{ 'DEMO.GIFTS.XTOCHOOSE' | translate: { X: getRemainingGiftsToChoose() } }}</span>
  </ng-container>
  <ng-container *ngIf="!isModal && locked" [ngSwitch]="getRemainingGiftsToChoose()">
    <span *ngSwitchCase="1">{{ 'DEMO.GIFTS.LOCKED.ONELEFT' | translate }}</span>
    <span *ngSwitchCase="0">{{ 'DEMO.GIFTS.NONELEFT' | translate }}</span>
    <span *ngSwitchDefault>{{ 'DEMO.GIFTS.LOCKED.XTOCHOOSE' | translate: { X: getRemainingGiftsToChoose() } }}</span>
  </ng-container>
  <div class="cardsContainer flex" [class.locked]="locked">
    <ng-container *ngFor="let gift of hostessGifts?.giftsForCategories; let index = index">
      <app-hostess-gift-card
        [gifts]="gift.choices"
        [selectedGift]="getSelectedGift(gift.category, hostessGifts?.selectedGifts)"
        [isActiveGift]="activeCategory == gift.category"
        [category]="gift.category"
        [rank]="index + 1"
        (requestCardOnClickBehavior)="cardClicked(gift)"></app-hostess-gift-card>
    </ng-container>
  </div>
</div>
