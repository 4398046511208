import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormsService } from '../../../../../../core/services/forms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateDocumentRequest, DocumentAudience, DocumentCategoryObject, DocumentResponse, DocumentTypeObject, UpdateDocumentRequest } from '@victoria-company/agora-client';
import { DocumentsService } from '../../../../../../core/services/V2/documents.service';
import { ContextStore } from '../../../../../../stores/context.store';
import { DocumentsStore } from '../../../../../../stores/documents.store';
import { from, of, switchMap } from 'rxjs';
import { AttachmentType } from '../../../../../../shared/components/file-dropzone/file-dropzone.component';

export enum FileType {
  Image = 'image',
  Attachment = 'attachment',
}

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss'],
})
export class EditDocumentComponent implements OnInit {
  readonly active = inject(ActivatedRoute);
  readonly router = inject(Router);
  readonly formService = inject(FormsService);
  readonly fb = new FormBuilder();
  readonly contextStore = inject(ContextStore);
  readonly documentService = inject(DocumentsService);
  readonly documentStore = inject(DocumentsStore);

  public editForm: FormGroup;
  private currentDocument: DocumentResponse;
  private categoryParam: string;

  constructor() {}

  async ngOnInit() {
    if (!this.documentStore.staffDocuments()) await this.documentStore.loadDocumentsForStaff();

    this.active.params
      .pipe(
        switchMap(params => {
          if (params.documentId) {
            return from(this.documentStore.getDocumentById(params.documentId));
          }
          return of(null);
        }),
        switchMap(document => {
          this.currentDocument = document;
          return this.active.parent.params;
        })
      )
      .subscribe(parentParams => {
        if (parentParams && parentParams.category) {
          this.categoryParam = parentParams.category;

          const category = this.categoryParam === 'hostessguide' ? DocumentCategoryObject.HostessGuide : DocumentCategoryObject.CommercialAction;

          this.editForm = this.formService.getEditDocumentForm(this.fb, category, this.currentDocument, this.contextStore.contextId(), this.contextStore.locale().replace('-', '_'));
        }
      });
  }

  async validateForm() {
    if (this.editForm.valid) {
      const audiences: DocumentAudience[] = [];

      if (this.editForm.get('audiences.audiences_delegate')?.value) audiences.push('Delegate');
      if (this.editForm.get('audiences.audiences_hostess')?.value) audiences.push('Hostess');
      if (this.editForm.get('audiences.audiences_customer')?.value) audiences.push('Customer');

      let documentSaved = false;

      if (!this.currentDocument) {
        const request = this.buildRequest(audiences) as CreateDocumentRequest;
        documentSaved = await this.documentStore.createDocument(request);
      } else {
        const request = this.buildRequest(audiences) as UpdateDocumentRequest;
        documentSaved = await this.documentStore.updateDocument(this.currentDocument.id, request);
      }

      if (documentSaved) await this.router.navigate([`/${this.contextStore.locale()}/club/manage/${this.categoryParam}`]);
    } else {
      this.editForm.markAllAsTouched();
    }
  }

  async uploadFile(file: File, type: FileType) {
    const result = await this.documentService.uploadDocumentAttachment(file);
    if (result?.attachmentUrl) {
      switch (type) {
        case FileType.Image:
          this.editForm.controls['imageUrl'].setValue(result.attachmentUrl);
          break;
        case FileType.Attachment:
          this.editForm.controls['attachments'].setValue([{ url: result.attachmentUrl, type: 'file' }]);
      }
    }
  }

  removeAttachment(type: FileType) {
    if (type == FileType.Image) {
      this.editForm.get('imageUrl').setValue(null);
    } else if (type == FileType.Attachment) {
      this.editForm.get('attachments').setValue([]);
    }
  }

  private buildRequest(audiences: DocumentAudience[]) {
    return {
      title: this.editForm.get('title')?.value,
      type: this.editForm.get('type')?.value,
      category: this.editForm.get('category')?.value,
      locale: this.editForm.get('locale')?.value,
      appliesFrom: this.editForm.get('appliesFrom')?.value ? new Date(this.editForm.get('appliesFrom')?.value) : null,
      appliesUntil: this.editForm.get('appliesUntil')?.value ? new Date(this.editForm.get('appliesUntil')?.value) : null,
      visibleFrom: this.editForm.get('visibleFrom')?.value ? new Date(this.editForm.get('visibleFrom')?.value) : null,
      visibleUntil: this.editForm.get('visibleUntil')?.value ? new Date(this.editForm.get('visibleUntil')?.value) : null,
      contextId: this.contextStore.contextId(),
      link: this.editForm.get('link')?.value,
      imageUrl: this.editForm.get('imageUrl')?.value,
      description: this.editForm.get('description')?.value,
      attachments: this.editForm.get('attachments')?.value,
      audiences,
    };
  }

  protected readonly DocumentCategoryObject = DocumentCategoryObject;
  protected readonly DocumentTypeObject = DocumentTypeObject;
  protected readonly FileType = FileType;
  protected readonly AttachmentType = AttachmentType;
}
