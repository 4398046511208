<div class="custom-error-container flex column j-start ai-center ac-center">
  <div class="custom-error-content flex column j-center ai-start flex-step-3">
    <div class="custom-error-white-box">
      <h1 class="canela center">{{ errorTitle ?? 'Erreur' }}</h1>
      <p class="m-center">{{ errorMessage ?? 'Une erreur est survenue durant la récupération de vos données.' }}</p>
    </div>
    <div class="cta-container custom-error-btn-area flex j-center ai-start" *ngIf="showGoHomeBtn || showReloadBtn">
      <button class="btn btn-primary" *ngIf="showReloadBtn" (click)="reload()">Recharger la page</button>
      <button class="btn btn-primary" *ngIf="showGoHomeBtn" (click)="goHome()">Aller à l'accueil</button>
    </div>
  </div>
</div>
