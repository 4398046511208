import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

export enum DocumentActions {
  EDIT = 'edit',
  CREATE = 'create',
}
@Component({
  selector: 'app-manage-documents',
  templateUrl: './manage-documents.component.html',
  styleUrls: ['./manage-documents.component.scss'],
})
export class ManageDocumentsComponent implements OnInit {
  translate = inject(TranslateService)
  active = inject(ActivatedRoute);
  router = inject(Router)
  category: string;
  action = '';

  ngOnInit() {
    //IC Direct access
    this.getParameters()

    //IC Internal Navigation
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.getParameters());
  }

  get screenTitleFromParameters(){
    // console.log("action: ", this.action);
    return this.translate.instant('STAFF.MANAGE.' + this.category +  (this.action ? '.' + this.action : '') )
  }

  private getParameters(){
    //GET param from main component
    this.active.params.subscribe(params => {
      if (params.category) this.category = params.category.toUpperCase();
    });

    //GET param from first child component
    this.active.firstChild.params. subscribe(params => {
      if (params.action) this.action = params.action.toUpperCase();
      else this.action = '';
    })
  }
}
