import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { GiftCategory } from '@victoria-company/agora-client';
import { GetDatasService } from '../../../../core/services/get-datas.service';
import { DemoHostessGifts, DemoHostessGifts_GiftsForCategory_GiftChoice } from '../../../../stores/demo.store';
import { SoundService } from '../../../../core/services/V2/sound.service';

export type SaveGiftsRequest = { category: GiftCategory; gift: DemoHostessGifts_GiftsForCategory_GiftChoice };

@Component({
  selector: 'app-hostess-select-gifts',
  templateUrl: './hostess-select-gifts.component.html',
  styleUrls: ['./hostess-select-gifts.component.scss'],
})
export class HostessSelectGiftsComponent {
  @Input() displayTitle = true;
  @Input() isDelegate = false;
  @Input() isModal = true;
  @Input() hostessGifts: DemoHostessGifts = null;
  @Input() selectedCategory: GiftCategory;
  @Input() giftsLocked = false;

  @Output() requestSaveSelectedGift = new EventEmitter<SaveGiftsRequest>();

  getDataService = inject(GetDatasService);
  readonly soundService = inject(SoundService);

  getGiftsFromSelectedCategory() {
    return this.hostessGifts?.giftsForCategories.find(g => g.category == this.selectedCategory)?.choices ?? [];
  }

  changeCategory(category: GiftCategory) {
    if (this.giftsLocked) return;
    else {
      this.selectedCategory = category;
    }
  }

  async selectGiftToSave(gift: DemoHostessGifts_GiftsForCategory_GiftChoice) {
    if (gift.missingTurnOver > 0 || this.giftsLocked) return;
    else {
      this.requestSaveSelectedGift.emit({ category: this.selectedCategory, gift: gift });
      await this.soundService.playSound('/assets/sounds/item_click.wav');
    }
  }

  isSelectedGift(gift: DemoHostessGifts_GiftsForCategory_GiftChoice) {
    const giftFromCurrentCategory = this.hostessGifts?.selectedGifts?.find(x => x.category == this.selectedCategory);
    if (!giftFromCurrentCategory) return false;
    else {
      return giftFromCurrentCategory.productVariantId == gift.product.variants[0].id;
    }
  }
}
