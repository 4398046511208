/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VoucherResponse } from '@victoria-company/agora-client';


@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  //Add voucher
  private _createdVoucher$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public createdVoucher$: Observable<any> = this._createdVoucher$.asObservable();

  public updateVoucher(voucher: VoucherResponse) {
    this._createdVoucher$.next(voucher);
  }


}
