<div class="demo-client-code-container">
  <app-page-title [mainTitle]="'CLIENT.CODE.TITLE' | translate" [subTitle]="'CLIENT.CODE.SUBTITLE' | translate"></app-page-title>
  <div class="pink-area m-fullwidth my-acces" *ngIf="codeClient">
    <div class="client-code-container">
      <div class="client-code-block flex center-all">
        <p>{{ codeClient }}</p>
      </div>
      <!-- <div>
        <qrcode [qrdata]="codeClient" [width]="200" ></qrcode>
      </div>
      <div class="cta-container w100">
        <button (click)="share()">
          <label>{{'DEMO.MODAL.QRCODE.SHARE_ACTION' | translate}}</label>
          <svg>
            <use xlink:href="#icon-share"></use>
          </svg>
        </button>
      </div> -->
    </div>
  </div>
</div>
