import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormsService } from 'src/app/core/services/forms.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { CartResponse, DelegateResponse, DeliveryAddress, DeliveryType, DeliveryTypeObject, GetCartAvailableDeliveryTypesResponse, UserProfileResponse } from '@victoria-company/agora-client';
import { CheckoutOrValidationProgressionStep } from '../../../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { objIsEmpty } from '../../../../../../core/extensions/extensions';
import { InternalDeliveryData } from '../../../../../../stores/chechout.store';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.scss'],
})
export class OrderDeliveryComponent implements OnInit, OnChanges {
  //V2
  public resourcesStore = inject(ResourcesStore);

  @Input() cart: CartResponse;
  @Input() internalDeliveryData: InternalDeliveryData;
  @Input() deliveryOptions: GetCartAvailableDeliveryTypesResponse;
  @Input() delegate: DelegateResponse;
  @Input() client: UserProfileResponse;
  @Input() isValidationTunnel = false;

  @Output() requestSaveInternalDeliveryInfo = new EventEmitter<{ deliveryType: DeliveryType; deliveryFee: number }>();
  @Output() requestSaveInternalDeliveryAddress = new EventEmitter<DeliveryAddress>();
  @Output() requestSaveDelivery = new EventEmitter();

  editAddress = signal(false);
  deliveryForm: UntypedFormGroup;

  get df() {
    return this.deliveryForm.controls;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public form: FormsService,
    public translate: TranslationService
  ) {}

  ngOnInit() {
    if (this.cart) {
      const deliveryToDelegate = this.deliveryOptions?.deliveryOptions.find(o => o.deliveryType == 'ToDelegate');
      const deliveryToCustomer = this.deliveryOptions?.deliveryOptions.find(o => o.deliveryType == 'ToCustomer');

      if (!this.cart.delivery?.type && !this.internalDeliveryData && deliveryToDelegate)
        this.requestSaveInternalDeliveryInfo.emit({ deliveryType: deliveryToDelegate.deliveryType, deliveryFee: deliveryToDelegate.deliveryFee });
      else if (!this.cart.delivery?.type && !this.internalDeliveryData && deliveryToCustomer)
        this.requestSaveInternalDeliveryInfo.emit({ deliveryType: deliveryToCustomer.deliveryType, deliveryFee: deliveryToCustomer.deliveryFee });

      this.editAddress.set(objIsEmpty(this.cart.delivery?.address));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['client'] && changes['client'].currentValue) {
      if (this.internalDeliveryData && this.internalDeliveryData.delivery?.address != null) this.form.getDeliveryForm(this.fb, this.internalDeliveryData.delivery.address);
      else this.deliveryForm = this.form.getDeliveryForm(this.fb, objIsEmpty(this.cart.delivery?.address) ? this.client?.addresses.find(x => x.type == 'Main') : this.cart.delivery?.address);
    }
  }

  switchOption(deliveryType: DeliveryType) {
    const option = this.deliveryOptions?.deliveryOptions?.find(x => x.deliveryType == deliveryType);
    const deliveryFee = option?.deliveryFee ?? 0.0;

    if (this.internalDeliveryData && this.internalDeliveryData?.delivery?.type == deliveryType) return;
    else if (!this.internalDeliveryData && this.cart.delivery.type && this.cart.delivery.type == deliveryType) return;

    this.requestSaveInternalDeliveryInfo.emit({ deliveryType, deliveryFee });

    if (this.internalDeliveryData && this.internalDeliveryData.delivery?.address != null) this.editAddress.set(objIsEmpty(this.internalDeliveryData.delivery?.address) || !this.deliveryForm?.valid);
    else this.editAddress.set(objIsEmpty(this.cart.delivery?.address) || !this.deliveryForm?.valid);
  }

  isSelectedDelivery(type: DeliveryType) {
    if (this.internalDeliveryData?.delivery?.type) return this.internalDeliveryData?.delivery?.type == type;
    else if (this.cart.delivery?.type) return this.cart.delivery.type == type;
    else return false;
  }

  deliveryIsAvailable(type: DeliveryType) {
    return this.deliveryOptions?.deliveryOptions?.find(x => x.deliveryType == type);
  }

  getDeliveryFee(type: DeliveryType) {
    return this.deliveryOptions?.deliveryOptions?.find(x => x.deliveryType == type)?.deliveryFee ?? 0;
  }

  saveAddress() {
    this.deliveryForm.markAllAsTouched();
    if (this.deliveryForm.valid) {
      const address: DeliveryAddress = {
        firstname: this.client.firstname,
        lastname: this.client.lastname,
        address1: this.deliveryForm.get('street').value,
        city: this.deliveryForm.get('city').value,
        zipCode: this.deliveryForm.get('zipCode').value,
        mobile: this.deliveryForm.get('phone').value,
        country: this.deliveryForm.get('country').value,
      };
      this.editAddress.set(false);
      this.requestSaveInternalDeliveryAddress.emit(address);
    }
  }

  confirmDelivery() {
    this.requestSaveDelivery.emit();
  }

  editDeliveryAddress() {
    if (this.internalDeliveryData && this.internalDeliveryData.delivery?.address != null) this.form.getDeliveryForm(this.fb, this.internalDeliveryData.delivery.address);
    else this.deliveryForm = this.form.getDeliveryForm(this.fb, objIsEmpty(this.cart.delivery?.address) ? this.client?.addresses.find(x => x.type == 'Main') : this.cart.delivery?.address);
    this.editAddress.set(true);
  }

  changeCountry(event) {
    // this.deliveryOptions = this.getDeliveryOptions(event.target.value);
    // this.order.deliveryFee = this.getDeliveryFee(this.deliveryId);
  }

  protected readonly DeliveryTypeObject = DeliveryTypeObject;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
}
