<div class="actionsSheet-container" id="actionsSheet">
  <div class="actionsSheet-content" [class.slideout]="slideOut">
    <div class="actionsSheet">
      <ul>
        <li>{{ 'ACTIONSSHEET.TITLE' | translate }}</li>
        <ng-content></ng-content>
      </ul>
    </div>
    <div class="actionsSheet-action">
      <ag-button [label]="'GLOBAL.ACTION.CANCEL' | translate" classes="btn-white bold" (triggerFunction)="close()"></ag-button>
    </div>
  </div>
</div>
