import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ScreenService } from '../../../core/services/screen.service';

@Component({
  selector: 'app-zoom-in-picture-modal',
  templateUrl: './zoom-in-picture-modal.component.html',
  styleUrls: ['./zoom-in-picture-modal.component.scss'],
})
export class ZoomInPictureModalComponent {
  @Input() opened = false;
  @Input() imageUrl = '';
  @Output() requestCloseModal = new EventEmitter();

  readonly screen = inject(ScreenService);
  readonly id = 'zoom-in-picture-modal';

  close() {
    this.requestCloseModal.emit();
  }
}
