<div class="modal-container" id="encoding-demo-code-modal" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal" *ngIf="resourcesStore.isLoaded()">
    <div class="modal-icon icon peach" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1>{{ resourcesStore.i18n().resources.popupFindHomeparty.mainTitle | removeHtmlTag }}</h1>

    <div class="modal-body promo">
      <p>{{ resourcesStore.i18n().resources.popupFindHomeparty.description | removeHtmlTag }}</p>
      <div class="promo-form">
        <label>{{ resourcesStore.i18n().resources.popupFindHomeparty.homepartyLabel | removeHtmlTag }}</label>
        <div class="flex gap-8 promo">
          <div [class.error]="error">
            <input type="text" [(ngModel)]="demoCode" />
          </div>
          <button class="btn btn-primary" (click)="addCodeToDemo()">{{ resourcesStore.i18n().resources.popupFindHomeparty.buttonLabel | removeHtmlTag }}</button>
        </div>
        <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
      </div>
    </div>
  </div>
</div>
