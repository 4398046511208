import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AddAdvantageStatus } from '../../../../state/models/addAvantageStatus';
import { CartResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-wallet-consumption',
  templateUrl: './wallet-consumption.component.html',
  styleUrls: ['./wallet-consumption.component.scss'],
})
export class WalletConsumptionComponent implements OnInit, OnChanges {
  @Input() isValidationTunnel: boolean = false;
  @Input() stateStatus: AddAdvantageStatus;
  @Input() cart: CartResponse;
  @Input() walletAvailableAmount: number;
  @Output() requestUpdateWalletAmount = new EventEmitter<number>();

  walletAmountForm: UntypedFormGroup;
  enableWalletButton = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.initWalletForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stateStatus && changes.stateStatus && changes.stateStatus.currentValue) {
      const status: AddAdvantageStatus = changes.stateStatus.currentValue;
      if (status.added) this.walletAmountForm?.get('walletAmount')?.setValue(null);
    }
  }

  validateInputValue() {
    const amount = this.walletAmountForm?.get('walletAmount')?.value;
    this.enableWalletButton = amount > 0;
  }

  resetWalletAmount() {
    this.requestUpdateWalletAmount.emit(0);
  }

  useWalletAmount() {
    let amount = this.walletAmountForm?.get('walletAmount')?.value;
    const maxWalletAmount = this.walletAvailableAmount <= this.cart.total - this.cart.deliveryFee ? this.walletAvailableAmount : this.cart.total - this.cart.deliveryFee;

    if (amount <= 0) return;
    if (amount > maxWalletAmount) amount = maxWalletAmount;

    this.requestUpdateWalletAmount.emit(amount);
  }

  private initWalletForm() {
    this.walletAmountForm = this.formBuilder.group({
      walletAmount: { value: null },
    });

    this.walletAmountForm.get('walletAmount').valueChanges.subscribe(v => {
      this.enableWalletButton = !!v;
    });
  }
}
