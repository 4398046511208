/* eslint-disable @typescript-eslint/naming-convention */
import { Component, inject, Input } from '@angular/core';
import { ErrorService } from 'src/app/core/services/error.service';
import { ResourcesStore } from '../../../../../stores/resources.store';
import { ClubActionTypeEnumObject, ClubCustomerActionTypesResponse, GetClubInformationResponse } from '@victoria-company/agora-client';
import { UserStore } from '../../../../../stores/user.store';
import { ContextStore } from '../../../../../stores/context.store';
import { TranslateService } from '@ngx-translate/core';
import { getTrackableActionTypeToLegacyId, getTrackableActionTypeToLegacyText } from 'src/app/core/utils/common.utils';
import { ClubActionTypeEnum } from '@victoria-company/agora-client/dist/models';
import { Router } from '@angular/router';
import { ModalService } from '../../../../../core/services/modal.service';
import { ClubService } from '../../../../../core/services/V2/club.service';

@Component({
  selector: 'app-status-datas',
  templateUrl: './status-datas.component.html',
  styleUrls: ['./status-datas.component.scss'],
})
export class StatusDatasComponent {
  readonly resourcesStore = inject(ResourcesStore);
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly translate = inject(TranslateService);
  readonly router = inject(Router);

  //LEGACY
  readonly modal = inject(ModalService);
  readonly error = inject(ErrorService);
  //
  readonly clubService = inject(ClubService);

  @Input() clubInformation: GetClubInformationResponse;
  @Input() trackableActions: ClubCustomerActionTypesResponse[];

  async handleTrackableAction(type: ClubActionTypeEnum) {
    switch (type) {
      case ClubActionTypeEnumObject.CodeAdd:
        return (window.location.href = this.resourcesStore.getEshopCollectionLink());
      case ClubActionTypeEnumObject.BoosterLevel1:
        return this.router.navigate([`/${this.contextStore.locale()}/club/referral`]);
      case ClubActionTypeEnumObject.FollowFacebook: {
        this.openFacebook();
        await this.clubService.followFacebook();
        break;
      }
      case ClubActionTypeEnumObject.FollowInstagram: {
        this.openInstagram();
        await this.clubService.followInstagram();
        break;
      }
      case ClubActionTypeEnumObject.NewsletterSubscription:
        await this.clubService.subscribeNewsletter(this.userStore.profile()?.email, this.contextStore.locale(), this.contextStore.contextId());
        break;
      case ClubActionTypeEnumObject.BirthdayAdd:
        return this.openBirthDateModal();
    }

    await this.userStore.getTrackableActions(this.contextStore.contextId());
  }

  private openInstagram() {
    switch (this.contextStore.contextId()) {
      case 1:
        window.open('https://www.instagram.com/victoriafr_off/', '_blank');
        break;
      case 2:
        window.open('https://www.instagram.com/victoria_benelux/', '_blank');
        break;
      case 3:
        window.open('https://www.instagram.com/victoriadeutschland/', '_blank');
        break;
      default:
        window.open('https://www.instagram.com/victoriafr_off/', '_blank');
    }
  }

  private openFacebook() {
    switch (this.contextStore.contextId()) {
      case 1:
        window.open('https://www.facebook.com/victoriafranceofficiel/', '_blank');
        break;
      case 2:
        window.open('https://www.facebook.com/victoriabenelux/', '_blank');
        break;
      case 3:
        window.open('https://www.facebook.com/victoriaschmuck.deutschland', '_blank');
        break;
      default:
        window.open('https://www.facebook.com/victoriafranceofficiel/', '_blank');
    }
  }

  openBirthDateModal() {
    this.modal.open('#birthDateModal');
  }

  protected readonly ClubActionTypeEnumObject = ClubActionTypeEnumObject;
  protected readonly getTrackableActionTypeToLegacyText = getTrackableActionTypeToLegacyText;
  protected readonly getTrackableActionTypeToLegacyId = getTrackableActionTypeToLegacyId;
}
