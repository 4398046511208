import { Component, inject } from '@angular/core';
import { WishlistStore } from '../../../../stores/wishlist.store';

@Component({
  selector: 'app-wishlist-delete-confirmation-modal',
  templateUrl: './wishlist-delete-confirmation-modal.component.html',
  styleUrls: ['./wishlist-delete-confirmation-modal.component.scss'],
})
export class WishlistDeleteConfirmationModal {
  public wishlistStore = inject(WishlistStore);

  cancelDeleteFromWishlist() {
    this.wishlistStore.cancelDeleteItemFromWishlistConfirmationModal();
  }

  async confirmDeleteFromWishlist(productId: string) {
    await this.wishlistStore.deleteItemFromWishlist(productId);
  }
}
