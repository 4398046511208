import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() firstname: string;
  @Input() lastname: string;

  initial: string;

  constructor() {}

  ngOnInit() {
    this.initial = `${this.firstname?.substring(0, 1)}${this.lastname?.substring(0, 1)}`.toUpperCase();
  }
}
