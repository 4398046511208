<div class="cart-voucher flex ai center j-space-between">
  <div class="cart-voucher-code flex ai-center">
    {{ voucher.code }}
  </div>
  <div class="cart-voucher-action flex ai-center" (click)="deleteVoucher()" *ngIf="canDeleteVoucher">
    <svg viewBox="0 0 32 32" class="icon trash">
      <use xlink:href="#icon-trash"></use>
    </svg>
  </div>
</div>
