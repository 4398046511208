<div class="links-container">
  <div class="links-label">
    <label>{{ 'ITEM.LINKS.LABEL' | translate }}: </label>
    <span>
      @if (value == 0) {
        {{ 'ITEM.LINKS.NONE' | translate }}
      } @else if (value == -1) {
        {{ 'ITEM.LINKS.ONELESS' | translate }}
      } @else if (value < -1) {
        {{ 'ITEM.LINKS.XLESS' | translate: { X: Math.abs(value) } }}
      } @else if (value == 1) {
        {{ 'ITEM.LINKS.ONEMORE' | translate }}
      } @else {
        {{ 'ITEM.LINKS.XMORE' | translate: { X: value } }}
      }
    </span>
  </div>

  <div class="links-controls" *ngIf="!isReadonly">
    <button class="linksBtn" [disabled]="value == -min" (click)="modifyLinks(-1)">-</button>
    <input class="linksInput" type="text" [(ngModel)]="value" readonly />
    <button class="linksBtn" [disabled]="value == max" (click)="modifyLinks(1)">+</button>
  </div>
</div>
