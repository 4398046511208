import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { ResourcesStore } from '../../../../../../../stores/resources.store';
import { CartItemResponse, ProductSearchModel } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);

  @Input() item: CartItemResponse;
  @Input() products: ProductSearchModel[];
  @Input() border: boolean;
  @Output() requestZoomInImage = new EventEmitter<string>();

  product: ProductSearchModel;

  constructor(public getDataService: GetDatasService) {}

  ngOnInit() {
    console.log(this.item);
    console.log(this.products);
    this.product = this.getProductInCartByVariantId(this.item.productVariantId);
    console.log(this.product);
  }

  getItemSize() {
    const variant = this.product.variants.find(v => v.id == this.item.productVariantId);
    if (variant) return variant.size;
    else return '/';
  }

  getProductInCartByVariantId(productVariantId: string): ProductSearchModel {
    return this.products?.find(p => p.variants.some(v => v.id == productVariantId));
  }

  zoomInImage() {
    this.requestZoomInImage.emit(this.getDataService.getProductImagesPathForHigh(this.product.imageThumbnail));
  }
}
