<div class="booster-access-container">
  <div class="text-container">
    <h1>{{ 'BOOSTER.HOMECARD.TITLE' | translate }}</h1>
    <p>{{ 'BOOSTER.SUBTITLE' | translate }}</p>
  </div>
  <div class="cta-container">
    <button class="btn btn-secondary secondary" [routerLink]="['/' + locale + '/club/referral']">
      <label>{{ 'BOOSTER.HOMECARD.LINK' | translate }}</label>
      <svg class="icon">
        <use xlink:href="#icon-group"></use>
      </svg>
    </button>
  </div>
</div>
