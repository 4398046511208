import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ResourcesStore } from '../../../../../stores/resources.store';

@Component({
  selector: 'app-shipping-info-modal',
  templateUrl: './shipping-info-modal.component.html',
  styleUrls: ['./shipping-info-modal.component.scss'],
})
export class ShippingInfoModalComponent {
  @Input() isOpened: boolean;
  @Output() requestCloseModal = new EventEmitter();

  public resourcesStore = inject(ResourcesStore);

  close() {
    this.requestCloseModal.emit();
  }
}
