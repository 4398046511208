import { Component, effect, inject, Input, signal } from '@angular/core';

import { CartStore } from '../../../../../stores/cart.store';
import { WishlistStore } from '../../../../../stores/wishlist.store';
import { derivedAsync } from 'ngxtension/derived-async';
import { DemoService } from '../../../../../core/services/V2/demo.service';
import { ScreenService } from '../../../../../core/services/screen.service';
import { CartResponse, DemoAttendeeResponse } from '@victoria-company/agora-client';
import { DemoStore } from '../../../../../stores/demo.store';

@Component({
  selector: 'app-modal-become-hostess-delegate',
  templateUrl: './modal-become-hostess-delegate.component.html',
  styleUrls: ['./modal-become-hostess-delegate.component.scss'],
})
export class ModalBecomeHostessDelegateComponent {
  @Input() cart: CartResponse;

  readonly demoService = inject(DemoService);
  readonly demoStore = inject(DemoStore);
  readonly screen = inject(ScreenService);
  readonly cartStore = inject(CartStore);
  readonly wishlistStore = inject(WishlistStore);

  public modalOpened = false;
  public id = 'become-delegate-modal';

  attendee = signal<DemoAttendeeResponse>(undefined);

  call = derivedAsync(async () => {
    if (this.cart && this.cart.demoId) {
      const demo = await this.demoService.getDemoByCodeOrId(this.cart.demoId);
      if (demo && demo.roles.length && demo.demo.status != 'Closed') {
        const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
        this.attendee.set(response);
      }
    }
  });

  constructor() {
    effect(() => {
      if (this.attendee() && this.wishlistStore.isLoaded() && (!this.attendee().requestedBecomeHostess || !this.attendee().requestedBecomeDelegate)) {
        this.triggerOpenModal();
      }
    });
  }

  async requestBecomeHostess() {
    await this.demoStore.requestBecomeHostess(this.cartStore.demo()?.demo?.code);
    const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
    this.attendee.set(response);
  }

  async requestBecomeDelegate() {
    await this.demoStore.requestBecomeDelegate(this.cartStore.demo()?.demo?.code);
    const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
    this.attendee.set(response);
  }

  triggerOpenModal() {
    window.setTimeout(() => {
      this.modalOpened = true;
    }, 3000);
  }

  closeModal() {
    this.modalOpened = false;
  }

  closeModalFromOutside(el: MouseEvent) {
    const m = el.target as HTMLElement;
    if (m.id === this.id && !this.screen.isMobile) {
      this.closeModal();
    }
  }
}
