<div data-testId="no-cart-block" class="no-cart-frame flex column ai-center" *ngIf="resourcesStore.isLoaded()">
  <svg viewBox="0 0 32 32" class="icon">
    <use xlink:href="#icon-bag"></use>
  </svg>
  <p>{{ resourcesStore.i18n().resources.shoppingBag.ctaMessage | removeHtmlTag }}</p>

  <div class="link-container">
    <app-wavedlink [externalUrl]="goToCollection()" [linkText]="resourcesStore.i18n().resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
  </div>

  <div class="link-container" *ngIf="!isAuthenticated">
    <a (click)="goToAuthentication()">{{ resourcesStore.i18n().resources.shoppingBag.buttonLabel }}</a>
  </div>
</div>
