import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ScreenService } from 'src/app/core/services/screen.service';
import { AddAdvantageStatus } from '../../../../state/models/addAvantageStatus';
import {CartResponse, GetUserAdvantagesResponse_VoucherResponse} from '@victoria-company/agora-client';

@Component({
  selector: 'app-voucher-selection',
  templateUrl: './voucher-selection.component.html',
  styleUrls: ['./voucher-selection.component.scss'],
})
export class VoucherSelectionComponent implements OnInit, OnChanges {
  //V2
  @Input() isValidationTunnel: boolean = false;
  @Input() stateStatus: AddAdvantageStatus;
  @Input() cart: CartResponse;
  @Input() vouchers: GetUserAdvantagesResponse_VoucherResponse[];
  @Input() vouchersInCart: GetUserAdvantagesResponse_VoucherResponse[];
  @Output() requestResetStatuses = new EventEmitter();
  @Output() requestUseVoucher = new EventEmitter<string>();

  availableVouchers: GetUserAdvantagesResponse_VoucherResponse[];
  voucherForm: UntypedFormGroup;
  voucherCount = 0;
  availableCount = 0;
  enableVoucherButton = false;
  //
  constructor(
    private formBuilder: UntypedFormBuilder,
    private screen: ScreenService
  ) {}

  ngOnInit() {
    this.initVoucherForm();
    this.updateVouchers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.vouchers && changes.vouchers.currentValue) {
      this.updateVouchers();
    }

    if (changes && changes.vouchersInCart && changes.vouchersInCart.currentValue) {
      this.updateVouchers();
    }

    if (changes && changes.stateStatus && changes.stateStatus.currentValue) {
      const status: AddAdvantageStatus = changes.stateStatus.currentValue;
      if (status.added) {
        this.updateVouchers();
        this.notifyAsDone();
      }
    }
  }

  useVoucher() {
    const voucherCode = this.voucherForm.get('voucher').value;
    this.requestUseVoucher.emit(voucherCode);
  }

  notifyAsDone() {
    const animationDuration = 3500;

    if (this.screen.isMobile) {
      document.getElementsByClassName('add-voucher-done')[0]?.classList.toggle('done');
      document.getElementsByClassName('add-voucher-check')[0]?.classList.toggle('display');
      setTimeout(() => {
        document.getElementsByClassName('add-voucher-check')[0]?.classList.toggle('display');
        document.getElementsByClassName('add-voucher-done')[0]?.classList.toggle('done');
        this.requestResetStatuses.emit();
      }, animationDuration);
    } else
      setTimeout(() => {
        this.requestResetStatuses.emit();
      }, animationDuration);
  }

  private initVoucherForm() {
    this.voucherForm = this.formBuilder.group({
      voucher: ['default'],
    });

    this.voucherForm.get('voucher').valueChanges.subscribe(v => {
      if (v === 'default') this.enableVoucherButton = false;
      else this.enableVoucherButton = true;
    });
  }

  private updateVouchers() {
    this.availableVouchers = this.vouchers?.filter(voucher => !this.vouchersInCart?.find(uV => uV.code === voucher.code));
    this.availableCount = this.availableVouchers?.length ?? 0;
    this.voucherCount = this.vouchers.length;
    this.voucherForm?.get('voucher').setValue('default');
    this.enableVoucherButton = false;
  }
}
