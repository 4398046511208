<div [class.login-container]="!isComponent" [class.login-component]="isComponent">
  <div class="max-width flex column d-center-all login-main-container">
    <div>
      <h1 class="peach" *ngIf="storage.clientFirstName && !isComponent">{{ 'GLOBAL.WELCOMEX' | translate: { X: storage.clientFirstName } | uppercase }}</h1>
      <h1 class="futura login-title" *ngIf="!isComponent">{{ 'LOGIN.TITLE' | translate }}</h1>
    </div>

    <div class="flex row m-column j-center login-option m-j-space-between d-gap-40" [class.component]="isComponent">
      <div clas="flex flex-1 column center-all" [class.margin]="!isComponent">
        <h2 class="futura login-subtitle">{{ 'LOGIN.ACCOUNT.TITLE' | translate | uppercase }}</h2>
        <p class="d-only center-all">{{ 'LOGIN.ACCOUNT.LABEL' | translate }}</p>
        <button class="btn btn-primary margin-auto" data-testid="login-button" (click)="login()">{{ 'LOGIN.ACCOUNT.ACTION' | translate }}</button>
      </div>
      <div class="flex flex-1 column center-all">
        <h2 class="futura login-subtitle">{{ 'LOGIN.REGISTER.TITLE' | translate | uppercase }}</h2>
        <p class="d-only">{{ 'LOGIN.REGISTER.LABEL' | translate }}</p>
        <div class="fit margin-auto">
          <app-wavedlink (click)="goToRegistration()" linkText="{{ 'LOGIN.REGISTER.ACTION' | translate }}" [isCTA]="true"></app-wavedlink>
        </div>
      </div>
    </div>
  </div>
</div>
