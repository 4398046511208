import { HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { from, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { filterURL, getVictoriaUrl } from '../utils/filter.utils';
import { ApiService } from './V2/api.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements TranslateLoader {
  readonly api = inject(ApiService);

  constructor(private translate: TranslateService) {}

  public getTranslation(languageCode: string) {
    const client = this.api.getClientInstance();

    if (!environment.production) return from(client.api.resources.byLanguageCode(languageCode).get({ queryParameters: { useCache: false } })).pipe(catchError(this.handleError));
    else return from(client.api.resources.byLanguageCode(languageCode).get()).pipe(catchError(this.handleError));
  }

  public filter(url: string, locale: string): string {
    return getVictoriaUrl(locale, true) + filterURL(url);
  }

  public getTranslationMultiple(
    client: string,
    delegate: string,
    isDelegateView: boolean = false,
    clientParams?: any,
    delegateParams?: any,
    isClientFromResources: boolean = false,
    isDelegateFromResources: boolean = false
  ) {
    if (isDelegateView && delegateParams && !isDelegateFromResources) return this.translate.instant(delegate, delegateParams);
    else if (isDelegateView && !isDelegateFromResources) return this.translate.instant(delegate);
    else if (isDelegateFromResources) return delegate;
    else if (!isDelegateView && clientParams && !isClientFromResources) return this.translate.instant(client, clientParams);
    else if (!isDelegateView && !isClientFromResources) return this.translate.instant(client);
    else if (isClientFromResources) return client;
    else return 'translation error';
  }

  //ERROR HANDLING
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Erreur Client / Reseau:', error.error.message);
    } else {
      console.error(`Erreur depuis le back :  ${error.status}, ` + `Corps de l'erreur : ${error.error}`);
    }
    return throwError(error.message);
  }
}
