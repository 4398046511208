import { ApiService } from './api.service';
import { DeliveryAddress, DeliveryType, DeliveryTypeObject, UserProfileResponse } from '@victoria-company/agora-client';
import { SearchDelegateForm } from '../../types/size-dropdown.type';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  readonly api = inject(ApiService);

  //GET
  getUserAdvantages(userId: string) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserId(userId).advantages.get();
  }

  //POST
  postSignDipPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).preContractualInformationDocument.sign.post();
  }

  postUserVoucherPromise(cartId: string, voucherCode: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).vouchers.post({ voucherCode });
  }

  postAdvantageViewedPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).confirmAdvantageScreenViewed.post();
  }

  postConfirmCartPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).confirm.post();
  }

  //PUT
  putDeliveryMethodPromise(cartId: string, deliveryType: DeliveryType, address?: DeliveryAddress) {
    const client = this.api.getClientInstance();
    if (deliveryType == DeliveryTypeObject.ToDelegate) return client.api.carts.byCartId(cartId).delivery.put({ deliveryType });
    else
      return client.api.carts.byCartId(cartId).delivery.put({
        deliveryType,
        deliveryAddress: address,
      });
  }

  putDemoCodeToCartPromise(cartId: string, demoCode: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).assignDemo.put({ demoCode });
  }
  putDelegateIdToCartPromise(cartId: string, delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).assignDelegate.put({ delegateId });
  }

  putUseWalletPromise(cartId: string, amount: number) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).wallet.put({ amount });
  }

  //DELETE
  deleteUserVoucherPromise(cartId: string, voucherCode: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).vouchers.byVoucherId(voucherCode).delete();
  }

  getDelegateFromProfileInfoPromise(locale: string, contextId: number, profile: UserProfileResponse) {
    // const queryString = `?query=&languageCode=${locale?.split('-')[0]}&street=${profile.addresses[0]?.address1 ?? ''}&zipCode=${profile.addresses[0]?.zipCode ?? ''}&locality=${profile.addresses[0]?.city ?? ''}&countryCode=${profile.addresses[0]?.countryCode ?? ''}&limit=1`;
    const client = this.api.getClientInstance();
    return client.api.gateway.delegateLookUp.get({
      queryParameters: {
        contextId,
        languageCode: locale?.split('-')[0],
        street: profile.addresses[0]?.address1 ?? '',
        zipCode: profile.addresses[0]?.zipCode ?? '',
        locality: profile.addresses[0]?.city ?? '',
        countryCode: profile.addresses[0]?.countryCode ?? '',
        limit: 1,
      },
    });
  }
  getSearchForDelegatesPromise(locale: string, contextId: number, formData: SearchDelegateForm) {
    //const queryString = `?query=${formData.name ?? ''}&languageCode=${locale?.split('-')[0]}&street=${formData.address ?? ''}&zipCode=${formData.zip ?? ''}&locality=${formData.city ?? ''}&countryCode=${formData.country ?? ''}&limit=5`;
    const client = this.api.getClientInstance();
    return client.api.gateway.delegateLookUp.get({
      queryParameters: {
        contextId,
        query: formData?.name ?? '',
        languageCode: locale?.split('-')[0],
        street: formData?.address ?? '',
        zipCode: formData?.zip ?? '',
        locality: formData?.city ?? '',
        countryCode: formData?.country ?? '',
        limit: 5,
      },
    });
  }
}
