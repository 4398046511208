import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wavedlink',
  templateUrl: './wavedlink.component.html',
  styleUrls: ['./wavedlink.component.scss'],
})
export class WavedlinkComponent {
  @Input() linkUrl: string;
  @Input() externalUrl: string;
  @Input() linkText: string;
  @Input() fragmentUrl: string;
  @Input() isCTA = false;
  @Input() hasArrow = true;
  @Input() externalTarget = '_self';

  constructor(private router: Router) {}

  public navigate() {
    this.router.navigate([this.linkUrl], { replaceUrl: true, fragment: this.fragmentUrl });
  }
}
